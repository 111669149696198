.titulo1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #262626;
  flex: none;
  order: 0;
  flex-grow: 1;
  padding-top: 4rem;
}

.explatitulo {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #404040;
  padding-top: 8px;
}



.selected {
  background: #E5F7E5 !important;
}

.fila-inactiva {
  background-color: #aeaeae;
}

.botonAddCampaign {
  display: flex !important;
  justify-content: center !important;
  gap: 8px !important;
  background: #003d85 !important;
  border-radius: 8px !important;
  flex: none !important;
  max-width: 383px !important;
  height: 33px !important;
  float: right !important;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 2.5%;
  padding: 6px 1px !important;

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    float: right !important;
    align-items: center;
    padding-left: 7rem;
    padding-right: 0.2rem;
    
    
  }
  .textBoton {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 25px;
    display: flex !important;
    text-align: center !important;
    color: #fafafa !important;
    text-transform: none;
    
  }
}

.containerfilter {
  display: flex;
padding-bottom: 8px;
  padding-left: 1.5rem;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  width: 100%;

  svg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0px;
    font-size: 26px;
    color: #a3a3a3;
  }
}

.masiveIcon {
  display: flex;
  padding-bottom: 0;
  margin-top: 25px;
  margin-bottom: 10px;
  min-width: 0px;
  @media (max-width: 768px) {
    float: right;
    margin-top: 2%;
  }
}

.masiveIcon button{
  padding: 0;
}
.row-encuesta .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  
  border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    &:focus {
      border: 1px solid #0065dd;
      box-shadow: 0px 0px 0px 0.2rem rgba(4,54,154,.21);
      transition: all .2s ease-in-out;
      }
    &:hover{
        border: 1px solid #0065dd; 
  }
}
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 4px 26px;
  color: #fafafa;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  border: none;

  @media (min-width: 700px) and (max-width: 1100px) {
    padding: 4px 22px;
  }
  @media (max-width: 700px) {
    padding: 4px;
    font-size: 16px;
    line-height: 20px;
  }

  &.disabled {
    cursor: no-drop;
    background: #aeaeae;
  }

  &.active {
    cursor: pointer;
    background: #ff4900;
  }

  &.active-green {
    cursor: pointer;
    background: #06a502;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    width: 200px;
    height: 33px;
    border-radius: 8px;
  }

  &.buttonIniciar {
    float: right;
    display: block;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 4px 6px;
    color: #fafafa;
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    border: none;
    cursor: pointer;
    min-width: 200px;
    background: #ff4900;
    
  }

  &.next {
    float: right !important;
    align-items: center;
    padding-left: 6rem !important;
    color: #fafafa;
    justify-content: end;
  }
  svg {
    display: block;
    margin: 0;
    font-size: 24px;
    color: #aeaeae;
  }
}

.agregar{
  &.disabled {
    color: #aeaeae;
  }
}

.content-filter {
  display: flex;
  justify-content: start;
  align-items: center;

  .button-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 4px;
    margin: 0;
    background: #f7f7f7;
    padding: 6px 10px;
    border: 1px solid #d4d4d4;
    svg {
      display: flex;
      margin: 0;
      color: #a3a3a3;
      font-size: 24px;
      &:hover {
        background: none;
      }
      &:active {
        border-radius: 0px;
        background: none;
        border: none;
        transform: none;
      }
    }
    &:active {
      background: #e4e4e4;
    }
  }

  .div-container-filter {
    display: flex;
    position: relative;
    padding: 10px;

    .div-filter {
      width: 200px;
      gap: 5px;
      display: none;
      position: absolute;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid #242424;
      border-radius: 4px;
      background-color: #fafafa;
      padding: 10px;
      top: 30px;
      left: -80px;
      z-index: 14;
      &.show {
        display: flex;
      }
    }
  }
}

.modalfilter {
  float: left;
  padding: 1rem;
  margin: auto;
}

.modal-title {
  align-items: center;
  padding: 40px 50px;
}

.explatituloModal {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
  padding-bottom: 20px;
}
.error-message{
color: #FE0000;
font-style: italic;
}

.cancelConfirmaRespuesta {
  width: 353px;
  height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #0065dd;
  order: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (min-width: 700px) and (max-width: 1100px) {
    min-width: 190px;
  }

  @media (max-width: 700px) {
    min-width: 140px;
    width: 100%;
    margin-bottom: 2px;
  }
}

.modal-botton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  @media (max-width: 452px) {
    display: block;
  }

  .modal-button {
    display: flex;
    min-width: 109px;
    min-height: 33px;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    gap: 10px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    cursor: pointer;

    &.modal-button2 {
      display: block;
      min-width: 340px;
      min-height: 33px;
      justify-content: center;
      align-items: center;
      padding: 6px 24px;
      gap: 10px;
      border-radius: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      cursor: pointer;
      &.disabled {
        cursor: no-drop;
        background: #aeaeae;
        color: #FAFAFA;
      }
    }

    @media (min-width: 700px) and (max-width: 1250px) {
      min-width: 190px !important;
    }

    @media (max-width: 700px) {
      min-width: 140px !important;
      width: 100%;
      margin-bottom: 2px;
    }

    &:hover {
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      border-radius: 8px;
      border: 2px solid #61738e;
    }

    &.confirm {
      color: #fafafa;
      border: 2px solid #06a502;
      background: #06a502;

      &:hover {
        background: #06a502;
        color: #fafafa;
        border: 2px solid #06a502;
      }
    }

    &.selec {
      color: #fafafa;
      border: 2px solid #005bc7;
      background: #005bc7;

      &:hover {
        border: 2px solid #005bc7;
        background: #005bc7;
      }
    }

    &.avisoFinal {
      color: #fafafa;
      border: 2px solid #005bc7;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 336px;
      height: 33px;
      background: #005bc7;
      border-radius: 8px;
      margin-bottom: 2rem;

      &:hover {
        border: 2px solid #005bc7;
        background: #005bc7;
      }
    }

    &.cancel {
      color: #005bc7;
      border: 2px solid #005bc7;
      background: #fafafa;
      margin-right: 4rem;

      &:hover {
        border: 2px solid #005bc7;
        background: #fafafa;
      }
    }

    &.delete {
      color: #fafafa;
      border: 2px solid #d32f2f;
      background: #d32f2f;

      &:hover {
        color: #d32f2f;
        border: 2px solid #d32f2f;
      }
    }
  }
}

.layerModal .layer {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: #262626;
  padding: 9px 4px 10px 16px;
  -webkit-user-select: none;
  user-select: none;
  background: #d4d4d4;
}

.row-selects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    label {
      display: flex;
      padding: 9px 4px 10px 16px;
      align-items: center;
      background: #d4d4d4;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      max-height: 50px;
    }
  }
}


  @media (max-width: 767px) {

    .rowDisplay, .row-encuesta .row{
      display: block !important;
    }

    .rowPadding0{
      padding-left: 0px;
      padding-right: 0px;
    }

    .rowDisplay .false{
      display: block !important;
    }

    .rowEncuesta input {
      margin-top: 45px;
    }

    .rowEncuesta2 input, .rowEncuesta .textArea {
      margin-top: 5px;
    }

}

@media (max-width: 360px) {
  .modalButton{
    display: block;
  }  

  .modalButton button{
    width: 100%;
    margin-bottom: 2%;
  }
}

@media (max-width: 440px) {
  .buttonW100{
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .floatLeft575{
    float: left;
  }
}

.row-encuesta {
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;

  .row {
    display: flex;
    float: left;
    flex-direction: row;
    gap: 10px;
    padding-right: 1rem;
    justify-content: space-between;

    label {
      background: #d4d4d4;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      max-height: 50px;
      float: left;
      justify-content: space-between;
      padding: 9px 4px 10px 16px;
    }
  }

  input {
    width: 100%;
    justify-content: space-between;
  }
  .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    border-color: #2196f3;
  }
}

.layerModal {
  display: flex;
  gap: 10px;
  align-items: center;
  label {
    display: flex;
    padding: 9px 4px 10px 16px;
    align-items: center;
    background: #d4d4d4;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

.agregapregunta {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11.57px;
  line-height: 15px;
  align-items: center;
  display: flex;
  text-align: center;
}

.classAction {
  display: flex;
  button {
    
    .content-loadingSmall{
      display: flex;
      max-width: 14px;
      position: absolute;
      justify-content: center;
      align-items: center;
      margin: 0px;
    
      .icon-loading {
        display: flex;
        max-width: 14px;
        position: absolute;
        animation: spin 1s linear infinite;
      }
      
      @keyframes spin {
        to {
          display: flex;
          max-width: 14px;
          position: absolute;
          transform: rotate(360deg);
        }
      }
    }

  }
}

.input .p-inputtext .p-component {
  color: #aeaeae;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s !important;
  appearance: none;
  border-radius: 8px !important;
}

.textArea textarea {
  font-size: 16px;
  color: #262626;
}



.modal-container-encuesta {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #1e1e1ece;
  z-index: 13;
  overflow-y: scroll;

  &.edit {
    overflow-y: scroll;
  }

  .modal-content-encuesta {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: #fafafa;
    /*padding: 48px 46px;*/
    padding: 70px 90px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 8px;
    margin: auto;
    gap: 16px;

    &.w-90 {
      width: 90%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 95%;
        padding: 32px 18px;
        gap: 10px;
      }
    }

    &.w-80 {
      width: 80%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 96%;
        padding: 32px 14px;
        gap: 10px;
      }
    }

    &.w-60 {
      width: 60%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 80%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    &.w-55 {
      width: 55%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 60%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    &.w-50 {
      width: 50%;
      @media (min-width: 700px) and (max-width: 792px) {
        width: 60%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    &.w-45 {
      width: 493px;
      min-height: 251px;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 40%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    .modal-title-right {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #61738e;
      /*padding-right: 2rem;*/
      @media (min-width: 300px) and (max-width: 500px) {
        text-align: center;
      }
    }

    .modal-content-message {
      max-width: 100%;
      background-color: #fafafa;
      padding: 48px 46px;
      border: 1px solid #888;
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      border-radius: 24px;
      margin: auto;
    }

    .modal-close {
      display: flex;
      justify-content: end;
      color: #aaa;
      margin: -20px -20px -10px -10px;
      svg {
        display: flex;
        margin: 0;
        font-size: 30px;
        color: #48566b;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        margin: -10px 0px -5px -5px;
      }
    }

    .modal-close:hover,
    .modal-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    .modal-title-encuesta {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #0065dd;
      padding-bottom: 8px;
      &.start {
        text-align: start;
      }
      @media (min-width: 280px) and (max-width: 1100px) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
      .modal-title-answer {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #0065dd;
        flex-grow: 1;
        text-align: left;
        /*padding: 0px 29px 0px 8px; 22*/
      }
    }

    .yesOrNo {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #48566b;
    }

    .explatituloModal-encuesta {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      text-transform: uppercase;
      color: #61738e;
      padding-bottom: 1.2rem;
      text-transform: uppercase;
      &.start {
        text-align: start;
      }
    }

    .modal-message {
      font-family: "Inter" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 20px;
      text-align: center;
      color: #404040 !important;
      padding-bottom: 2rem;
      padding-right: 0.8rem;
      padding-left: 0.8rem;
      &.start {
        text-align: start;
      }
    }

    .modal-message-restaurar {
      display: flex;
      flex-direction: column;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    .modal-message-bottom {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }
  }
}
