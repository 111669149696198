.modal-container-admin {
  display: inline-flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #1e1e1ece;
  overflow-y: scroll;
  z-index: 13;

  .modal-content-admin {
    width: 80%;
    max-width: 100%;
    background-color: #fafafa;
    padding: 48px 46px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 24px;
    margin: auto;
  }

  .modal-content-admin-message {
    width: 40%;
    max-width: 100%;
    background-color: #fafafa;
    padding: 48px 46px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 24px;
    margin: auto;
  }

  .modal-close {
    color: #aaa;
    top: 0;
    right: 0;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
    display: flex;
    float: right;
    svg {
      font-size: 30px;
      color: #48566b;
      font-weight: 700;
    }
  }

  .modal-close:hover,
  .modal-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #262626;
    margin: 10px 0px;
    padding: 0px;
  }

  .modal-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #262626;
    margin: 10px 0px;
  }

  .modal-message {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #262626;
    margin: 10px 0px;
  }

  .modal-message-bottom {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #262626;
    margin-top: 10px;
  }

  .modal-section-reasing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #262626;
    background: #e5f7e5;
    padding: 0px 16px;
    margin: 10px 0px;
  }

  .modal-section-renewcode {
    display: grid;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #262626;
    text-align: center;

    span {
      padding: 10px;
    }
  }

  .modal-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 1px solid #e5e5e5;
    margin: 0px;

    thead {
      th {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #404040;
        background: #f5f5f5;
        border: 1px solid #d4d4d4;
        padding: 10px;
      }
    }

    tbody {
      td {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #404040;
        background: #fafafa;
        border: 1px solid #d4d4d4;
        padding: 5px;
        border: none;
      }
    }
  }

  .modal-title-table {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #404040;
    margin: 10px 0px 10px 20px;
  }

  .modal-table-edit {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 1px solid #e5e5e5;

    thead {
      th {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #404040;
        background: #f5f5f5;
        border: 1px solid #d4d4d4;
        padding: 15px;
      }
    }

    tbody {
      td {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #404040;
        background: #fafafa;
        border: 1px solid #d4d4d4;
        padding: 5px;
      }
    }
  }

  .modal-table-infoedit-mobile {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 1px solid #e5e5e5;

    thead {
      th {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #404040;
        background: #e2ebf9;
        border: 1px solid #d4d4d4;
        padding: 5px;
      }
    }

    tbody {
      td {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #404040;
        background: #fafafa;
        border: 1px solid #d4d4d4;
        padding: 4px;
        border: none;
      }

      .content-button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .modal-content-edit {
    display: flex;
    max-width: 100%;
    width: 100%;
    justify-content: start;
    align-items: center;
    margin: 10px 0px;
  }

  .content-inputs {
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    justify-content: start;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    color: #262626;
    margin-right: 5px;

    Select {
      width: 90%;
      max-width: 100%;
    }

    .input-text-modal {
      width: 100%;
      max-width: 100%;
    }

    .span-required {
      color: #262626;

      &::after {
        content: " (*)";
      }
    }

    &.rows {
      flex-flow: row wrap;
      margin: 20px 0px 0px 10px;
    }
  }

  .content-bottom-send {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: start;
    margin: 20px 0px;

    .content-text {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: start;
      width: 47%;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #404040;
    }

    .content-text-date {
      display: grid;
      width: 47%;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
    }
  }

  .button-content {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    align-items: center;

    .button-back {
      display: flex;
      width: 40%;
      height: 41px;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      background: #fafafa;
      border: 2px solid #005bc7;
      border-radius: 8px;
      color: #005bc7;
      font-weight: 700;

      &:hover {
        background: #fafafa;
        border: 2px solid #005bc7;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        border-radius: 8px;
        color: #005bc7;
      }
    }

    .button-go-confirm {
      display: flex;
      width: 40%;
      height: 41px;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      background: #06a502;
      border: 2px solid #06a502;
      border-radius: 8px;
      font-weight: 700;

      &:hover {
        color: #06a502;
        background: #fafafa;
        border: 2px solid #06a502;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        border-radius: 8px;
      }
    }

    .button-go-newservice {
      display: flex;
      width: 40%;
      height: 41px;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      background: #ff4900;
      border: 2px solid #ff4900;
      border-radius: 8px;
      font-weight: 700;

      &:hover {
        color: #ff4900;
        background: #fafafa;
        border: 2px solid #ff4900;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
    }

    .button-go-delete {
      display: flex;
      width: 40%;
      height: 41px;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      background: #d32f2f;
      border-radius: 8px;
      font-weight: 700;
      border: 2px solid #d32f2f;
      &:hover {
        color: #d32f2f;
        background: #fafafa;
        border: 2px solid #d32f2f;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        border-radius: 8px;
      }
    }
  }
}

/////////////////////// TOASTMESSAGE ///////////////////////
/////////////////////// TOASTMESSAGE ///////////////////////
.toast-container-admin {
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .toast-content-admin {
    display: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 32px;
    width: 327px;
    position: absolute;
    right: 0;
    top: 15%;
    border-radius: 12px;

    .toast-title {
      font-style: italic;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      align-items: center;
      text-transform: capitalize;
    }

    .toast-message {
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
    }

    &.success {
      background: #e2ebf9;
      color: #06a502;
      border-left: 16px solid #06a502;
    }

    &.error {
      background: #f8e0e0;
      color: #d32f2f;
      border-left: 16px solid #d32f2f;
    }

    &.precaution {
      background: #fff4d9;
      color: #7f1c1c;
      border-left: 16px solid #ff4900;
    }
  }
}

.toast-container-admin-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 4px;
  gap: 8px;
  position: absolute;
  width: 100%;
  height: 32px;
  border-radius: 8px;

  span {
    font-style: italic;
    font-weight: 400;
    font-size: 14.05px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #fafafa;
    justify-content: start;
  }

  svg {
    color: #fafafa;
    background: transparent;
  }

  &.success {
    background: #4bb848;
  }

  &.error {
    background: #d32f2f;
  }

  &.precaution {
    background: #ff4900;
  }
}

// ----------------------------------------------------- //
// ---------------------- 480px ------------------------ //
// ----------------------------------------------------- //
@media (max-width: 480px) {
  .modal-container-admin {
    display: inline-flex;
    position: fixed;
    z-index: 13;

    .modal-content-admin {
      background-color: #fafafa;
      padding: 30px 10px;
      margin: auto;
    }

    .modal-title-table {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #404040;
      margin: 10px 0px 10px 20px;
    }

    .modal-title {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;
      margin: 10px 0px;
    }

    .modal-subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      margin: 10px 0px;
    }

    .modal-message {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      margin: 10px 0px;
    }

    .modal-table {
      text-align: start;

      thead {
        th {
          text-align: start;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #404040;
          background: #f5f5f5;
          border: 1px solid #d4d4d4;
          padding: 10px;
        }
      }

      tbody {
        td {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #404040;
          background: #fafafa;
          border: 1px solid #d4d4d4;
          padding: 5px;
          border: none;
        }
      }
    }

    .modal-table-edit {
      width: 100%;
      border-collapse: collapse;
      text-align: center;
      border: 1px solid #e5e5e5;

      thead {
        th {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #404040;
          background: #f5f5f5;
          border: 1px solid #d4d4d4;
          padding: 6px;
        }
      }

      tbody {
        td {
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #404040;
          background: #fafafa;
          border: 1px solid #d4d4d4;
          padding: 5px;
        }
      }
    }

    .modal-content-edit {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

// ----------------------------------------------------- //
// ------------------- TEXTO INFO ---------------------- //
// ----------------------------------------------------- //

.modal-show {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 13;
  background: #fafafa;
  flex-direction: column;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    color: #242424;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #262626;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    gap: 10px;

    .title-info {
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    .text-info {
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #262626;
    }
  }
}

.modal-show-hidden {
  display: none;
}
