@import "../_config";

.container-table-dashboard {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 24px;
  border-radius: 8px;
  background: var(--relleno-gral-model, #fafafa);
  box-shadow: 0px 16px 32px 0px rgba(29, 29, 29, 0.2);
  height: 350px;

  .content-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title-table-dashboard {
      color: #404040;
      text-align: center;
      font-family: $fontFamilyInter;
      font-size: $fontSizeMid;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
  }

  .content-table-dashboard {
    display: flex;
    width: 100%;
    justify-content: center;

    .table-dashboard {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            border-bottom: 1px dotted #555;
            padding: 10px;
            text-align: left;
            color: var(--texto-general-textos-gris, #48566b);
            text-align: center;
            font-family: $fontFamilyInter;
            font-size: 13.17px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            background-color: #e5e5e5;
          }

          &:nth-child(odd) {
            background-color: #f5f5f5;
          }
          td {
            padding: 4px;
            color: var(--texto-general-textos-gris, #48566b);
            text-align: center;
            font-family: $fontFamilyInter;
            font-size: 13.17px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;

            .content-name {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 10px;
            }
          }
        }
      }
    }
  }

  .footer-table-dashboard {
    display: flex;
    width: 100%;
    vertical-align: middle;
    align-items: center;
    gap: 10px;

    @media (max-width: 770px) {
      display: flex;
      padding: 4px;
      gap: 5px;
      position: static;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
    }

    .pagination-number {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      @media (max-width: 770px) {
        position: static;
      }

      .number-pagination {
        display: flex;
        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        justify-content: center;
        text-align: center;
        align-items: center;
        vertical-align: middle;
        color: #fafafa;
        text-transform: capitalize;
        background: #61738e;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        @media (max-width: 770px) {
          font-size: 12px;
          width: 22px;
          height: 22px;
        }
      }

      button {
        display: flex;
        background: none;
        margin: 0;
        border: none;
        padding: 0px;
        cursor: pointer;

        svg {
          display: flex;
          margin: 0;
          color: #61738e;
          font-size: 32px;
          padding: 0px;
          @media (max-width: 770px) {
            font-size: 22px;
          }

          &.doble {
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            @media (max-width: 770px) {
              font-size: 24px;
            }
          }
          &.disabled {
            color: #aeaeae;
          }
        }
        &.disabled {
          cursor: not-allowed;
          svg {
            color: #aeaeae;
          }
        }
      }
    }
  }
}
