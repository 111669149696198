@import "../ui/_config";

.content-seleccion-multipleDota {
  justify-content: space-between;
  width: 100%;

  &.action-in-line { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &.margin-top-20 {
    margin-top: 20px;
  }
  .content-multiple {
    display: flex;
    flex-direction: row;
    align-items: center;
    float: left;
    padding-top: 9px;

    .button-multiple {
      display: flex;
      margin: 0;
      background: transparent;
      border: none;
      cursor: pointer;
    }

    .title-multiple {
      color: #404040;
      text-align: center;
      font-family: $fontFamilyInter;
      font-size: $fontSizeMid;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      .selects-table-registro {
        color: $azulFlick;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 19px; /* 118.75% */
        margin-left: 20px;
      }

      .selects {
        color: $azulFlick;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 19px; /* 118.75% */

        &::before {
          content: " / ";
          font-weight: 400;
        }
      }
    }
  }
}

.content-actions-Dotacion {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-direction: row;
  flex-flow: row wrap;
  //margin-top: 25px;
  margin-bottom: 30px;

  @media (max-width: 700px) {
    gap: 10px;
    flex-direction: column;
    justify-content: center;
  }

  &.actions-old {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &.contrato {
    flex-flow: row nowrap;
    margin-bottom: 0px;
    justify-content: end;
  }

.content-retroceder {
      display: flex;
      gap: 10px;
}
  .content-enviar {
    display: flex;
    gap: 60px;

    @media (max-width: 700px) {
      gap: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      padding: 4px 26px;
      color: #fafafa;
      border-radius: 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      border: 2px solid #aeaeae;
      height: 36px;
      width: auto;
      gap: 10px;

      @media (min-width: 700px) and (max-width: 1100px) {
        padding: 4px 22px;
      }
      @media (max-width: 700px) {
        justify-content: center;
        width: 80%;
        padding: 4px;
        font-weight: 500;
        line-height: 20px; /* 125% */
      }

      &.disabled {
        cursor: no-drop;
        background: #aeaeae;
      }

      svg {
        display: flex;
        margin: 0;
        font-size: 24px;
        color: #fafafa;
      }

      &.active {
        cursor: pointer;
        background: #ff4900;
        border: 2px solid #ff4900;
        &:hover {
          background: #fafafa;
          color: #ff4900;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
          svg {
            color: #ff4900;
          }
        }
      }

      &.active-green {
        cursor: pointer;
        background: #06a502;
        border: 2px solid #06a502;

        &:hover {
          background: #fafafa;
          color: #06a502;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
          svg {
            color: #06a502;
          }
        }
      }

      &.active-red {
        cursor: pointer;
        background: #d32f2f;
        border: 2px solid #d32f2f;

        &:hover {
          background: #fafafa;
          color: #d32f2f;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
          svg {
            color: #d32f2f;
          }
        }
      }
      &.active-red2 {
        cursor: pointer;
        background: #fafafa;
        border: 2px solid #d32f2f;
        color: #d32f2f;
        &:hover {
          background:#d32f2f ;
          color: #fafafa;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
          svg {
            color: #d32f2f;
          }
        }
      }
    }
  }

  .content-actions-masiveDota {
    display: flex;
    justify-content: end;
    gap: 25px;

    .button-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      background: none;
      border-radius: 100%;
      margin: 0;
      cursor: pointer;
      width: 36px;
      height: 36px;

      svg {
        display: flex;
        margin: 0;
        font-size: 24px;
      }

      &.disabled {
        border: 2px solid #aeaeae;
        cursor: no-drop;
        svg {
          color: #aeaeae;
        }
        &.file {
          padding: 2px 10px;
        }
      }

      &.active-check {
        border: 2px solid #ff4900;
        svg {
          color: #ff4900;
        }
        &:active {
          background-color: #ff4900;
          svg {
            color: #fafafa;
          }
        }
        &:hover {
          background-color: #ff4900;
          svg {
            color: #fafafa;
          }
        }
      }

      &.active-send {
        border: 2px solid #ff4900;
        svg {
          color: #ff4900;
        }
        &:active {
          background-color: #ff4900;
          svg {
            color: #fafafa;
          }
        }
        &:hover {
          background-color: #ff4900;
          svg {
            color: #fafafa;
            fill: #fafafa;
          }
        }
      }

      &.active-cancel {
        border: 2px solid #d32f2f;
        svg {
          color: #d32f2f;
        }
        &:active {
          background-color: #d32f2f;
          svg {
            color: #fafafa;
          }
        }
        &:hover {
          background-color: #d32f2f;
          svg {
            color: #fafafa;
          }
        }
      }

      &.active-block {
        border: 2px solid #d32f2f;
        svg {
          color: #d32f2f;
        }
        &:active {
          background-color: #d32f2f;
          svg {
            color: #fafafa;
          }
        }
        &:hover {
          background-color: #d32f2f;
          svg {
            color: #fafafa;
          }
        }
      }

      &.active-file {
        border: 2px solid #327a30;
        padding: 2px 10px;
        svg {
          display: flex;
          margin: 0;
          font-size: 25px;
          color: #327a30;
        }
        &:active {
          background-color: #327a30;
          svg {
            fill: #fafafa;
          }
        }
        &:hover {
          background-color: #327a30;
          svg {
            fill: #fafafa;
          }
        }
      }

      &.active-datos {
        border: 2px solid #06a502;
        padding: 2px 10px;
        svg {
          display: flex;
          margin: 0;
          font-size: 25px;
          color: #06a502;
        }
        &:active {
          background-color: #06a502;
          svg {
            color: #fafafa;
          }
        }
        &:hover {
          background-color: #06a502;
          svg {
            color: #fafafa;
          }
        }
      }

      &.active-restore {
        border: 2px solid #0065dd;
        svg {
          color: #0065dd;
        }
        &:active {
          background-color: #0065dd;
          svg {
            color: #fafafa;
            fill: #fafafa;
          }
        }
        &:hover {
          background-color: #0065dd;
          svg {
            color: #fafafa;
            fill: #fafafa;
          }
        }
      }

      &.active-filter {
        border: none;
        svg {
          background: none;
          color: #a1c0ed;
          display: flex;
          margin: 0;
          font-size: 40px;
        }
        &:active {
          background-color: #fafafa;
          svg {
            background: none;
            color: #fafafa;
          }
        }
      }
    }
  }
}

.container-operaciones {
  display: flex;
  background-color: #fafafa;
  width: 100%;
  justify-content: center;

  .content-operaciones {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 20px 0px;
    gap: 16px 0px;

    .div-menu-responsive {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button-nav-menu {
        display: flex;
        margin: 0;
        border: none;
        background: #fafafa;
        position: relative;
        svg {
          display: flex;
          font-size: 26px;
          color: #48566b;
          margin: 0;
        }
        &:active {
          svg {
            font-size: 28px;
            color: #5e7599;
          }
        }
      }

      .div-nav-ul {
        display: flex;
        position: absolute;
        margin: 0;
        right: 20px;
        top: 50px;

        .list-nav {
          width: 250px;
          display: flex;
          justify-content: center;
          text-align: start;
          vertical-align: middle;
          flex-direction: column;
          background: #fafafa;
          border-radius: 4px;
          border: 1px solid #242424;
          padding: 5px 2px;
          list-style-type: none;
          z-index: 15;
          .li-nav {
            padding: 3px 16px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #61738e;
            &:hover {
              background: #dedede;
            }
            &.active {
              text-decoration-line: underline;
              background: #dedede;
            }
          }
        }
      }

      .InputSelect {
        position: relative;
        display: inline-block;
        font-size: 14px;

        .InputSelect__select {
          display: block;
          width: 100%;
          padding: 8px 26px 8px 10px;
          font-size: 14px;
          font-family: "Inter";
          font-style: normal;
          border: 1px solid #dedede;
          border-radius: 8px;
          appearance: none;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23ccc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>');
          background-repeat: no-repeat;
          background-position: right 4px center;
          background-size: 12px 12px;
          transition: border-color 0.3s ease-out;
          &:focus {
            border-color: #007bff;
            outline: none;
          }
        }
      }
    }

    .title-tw-cliente {
      color: #005bc7;
      font-family: "Inter";
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px;
      margin-top: -30px;
    }

    .content-nav-asistencia {
      display: flex;
      justify-content: start;
      gap: 24px;
      flex-flow: row wrap;

      .nav-tag-button {
        font-style: normal;
        font-weight: 500;
        align-items: center;
        padding: 7px 14px;
        font-family: "Inter";
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        color: #aeaeae;
        background: #fafafa;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        border-radius: 8px;
        border: none;
        cursor: pointer;
        &:hover {
          background: #f2f2f2;
        }

        &.active {
          color: #0065dd;
        }
      }
    }

    .title-welcome {
      display: flex;
      text-align: center;
      justify-content: start;
      font-family: "Inter";
      font-weight: 700;
      font-size: 22px;
      color: #005bc7;

      &.inicio {
        flex-direction: center;
        text-align: center;
      }
    }

    .title-page {
      display: flex;
      justify-content: center;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 44px;
      color: #262626;
      @media (max-width: 700px) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 17.07px;
        line-height: 22px;
      }
    }

    .title-page-intro {
      display: flex;
      justify-content: center;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 0px;
      color: #262626;
      @media (max-width: 700px) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 17.07px;
        line-height: 22px;
      }
    }

    .subtitle-page {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #040404;
      @media (max-width: 700px) {
        font-size: 13.17px;
        line-height: 16px;
      }
    }

    .section-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;
      background: #005bc7;
      margin: 12px 0px;
      cursor: pointer;

      .section-option {
        display: flex;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #fafafa;
      }

      svg {
        font-size: 30px;
        color: #fafafa;
      }
    }

    .content-actions {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex-direction: row;
      flex-flow: row wrap;
      margin-top: 25px;
      margin-bottom: 30px;

      @media (max-width: 700px) {
        gap: 10px;
        flex-direction: column;
        justify-content: center;
      }

      &.actions-old {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      &.contrato {
        flex-flow: row nowrap;
        margin-bottom: 0px;
      }

      .content-enviar {
        display: flex;
        gap: 60px;

        @media (max-width: 700px) {
          gap: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .navigation {
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-items: center;
          padding: 4px 26px;
          color: #fafafa;
          border-radius: 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          border: 2px solid #aeaeae;
          height: 36px;
          width: auto;
          gap: 10px;

          @media (min-width: 700px) and (max-width: 1100px) {
            padding: 4px 22px;
          }
          @media (max-width: 700px) {
            justify-content: center;
            width: 80%;
            padding: 4px;
            font-weight: 500;
            line-height: 20px; /* 125% */
          }

          &.disabled {
            cursor: no-drop;
            background: #aeaeae;
          }

          svg {
            display: flex;
            margin: 0;
            font-size: 24px;
            color: #fafafa;
          }

          &.active {
            cursor: pointer;
            background: #ff4900;
            border: 2px solid #ff4900;
            &:hover {
              background: #fafafa;
              color: #ff4900;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              svg {
                color: #ff4900;
              }
            }
          }

          &.active-green {
            cursor: pointer;
            background: #06a502;
            border: 2px solid #06a502;

            &:hover {
              background: #fafafa;
              color: #06a502;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              svg {
                color: #06a502;
              }
            }
          }

          &.active-red {
            cursor: pointer;
            background: #d32f2f;
            border: 2px solid #d32f2f;

            &:hover {
              background: #fafafa;
              color: #d32f2f;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              svg {
                color: #d32f2f;
              }
            }
          }

          &.active-red2 {
            cursor: pointer;
            background: #fafafa;
            border: 2px solid #d32f2f;
            color: #d32f2f;
            &:hover {
              background:#d32f2f ;
              color: #fafafa;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              svg {
                color: #d32f2f;
              }
            }
          }
        }
      }

      .content-actions-masive {
        display: flex;
        justify-content: end;
        gap: 25px;

        .button-actions {
          display: flex;
          justify-content: center;
          align-items: center;
          vertical-align: middle;
          background: none;
          border-radius: 100%;
          margin: 0;
          cursor: pointer;
          width: 36px;
          height: 36px;

          svg {
            display: flex;
            margin: 0;
            font-size: 24px;
          }

          &.disabled {
            border: 2px solid #aeaeae;
            cursor: no-drop;
            svg {
              color: #aeaeae;
            }
            &.file {
              padding: 2px 10px;
            }
          }

          &.active-check {
            border: 2px solid #ff4900;
            svg {
              color: #ff4900;
            }
            &:active {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-send {
            border: 2px solid #ff4900;
            svg {
              color: #ff4900;
            }
            &:active {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #ff4900;
              svg {
                color: #fafafa;
                fill: #fafafa;
              }
            }
          }

          &.active-cancel {
            border: 2px solid #d32f2f;
            svg {
              color: #d32f2f;
            }
            &:active {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-block {
            border: 2px solid #d32f2f;
            svg {
              color: #d32f2f;
            }
            &:active {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-file {
            border: 2px solid #327a30;
            padding: 2px 10px;
            svg {
              display: flex;
              margin: 0;
              font-size: 25px;
              color: #327a30;
            }
            &:active {
              background-color: #327a30;
              svg {
                fill: #fafafa;
              }
            }
            &:hover {
              background-color: #327a30;
              svg {
                fill: #fafafa;
              }
            }
          }

          &.active-datos {
            border: 2px solid #06a502;
            padding: 2px 10px;
            svg {
              display: flex;
              margin: 0;
              font-size: 25px;
              color: #06a502;
            }
            &:active {
              background-color: #06a502;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #06a502;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-restore {
            border: 2px solid #0065dd;
            svg {
              color: #0065dd;
            }
            &:active {
              background-color: #0065dd;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #0065dd;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-filter {
            border: none;
            svg {
              background: none;
              color: #a1c0ed;
              display: flex;
              margin: 0;
              font-size: 40px;
            }
            &:active {
              background-color: #fafafa;
              svg {
                background: none;
                color: #fafafa;
              }
            }
          }
        }
      }
    }

    .container-actions {
      display: flex;
      align-items: center;
      justify-content: end;
      flex-flow: row wrap;
      gap: 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &.actions-old {
        margin-top: 0px;
        margin-bottom: 0px;
        justify-content: end;
      }

      .content-filter {
        display: flex;
        justify-content: start;
        align-items: center;

        .button-filter {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          border-radius: 4px;
          margin: 0;
          background: #f7f7f7;
          padding: 6px 10px;
          border: 1px solid #d4d4d4;
          svg {
            display: flex;
            margin: 0;
            color: #a3a3a3;
            font-size: 24px;
            &:hover {
              background: none;
            }
            &:active {
              border-radius: 0px;
              background: none;
              border: none;
              transform: none;
            }
          }
          &:active {
            background: #e4e4e4;
          }
        }

        .div-container-filter {
          display: flex;
          position: relative;
          padding: 10px;

          .div-filter {
            width: 200px;
            gap: 5px;
            display: none;
            position: absolute;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border: 1px solid #242424;
            border-radius: 4px;
            background-color: #fafafa;
            padding: 10px;
            top: 30px;
            left: -80px;
            z-index: 14;
            &.show {
              display: flex;
            }
          }
        }
      }

      .content-actions-masive {
        display: flex;
        justify-content: end;
        gap: 25px;

        .button-actions {
          display: flex;
          justify-content: center;
          align-items: center;
          vertical-align: middle;
          background: none;
          border-radius: 100%;
          margin: 0;
          width: 36px;
          height: 36px;
          cursor: pointer;

          svg {
            display: flex;
            margin: 0;
            font-size: 24px;
          }

          &.disabled {
            border: 2px solid #aeaeae;
            cursor: no-drop;
            svg {
              color: #aeaeae;
            }
            &.file {
              padding: 2px 10px;
            }
          }

          &.active-check {
            border: 2px solid #ff4900;
            svg {
              color: #ff4900;
            }
            &:active {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-send {
            border: 2px solid #ff4900;
            svg {
              color: #ff4900;
            }
            &:active {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-cancel {
            border: 2px solid #d32f2f;
            svg {
              color: #d32f2f;
            }
            &:active {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-block {
            border: 2px solid #d32f2f;
            svg {
              color: #d32f2f;
            }
            &:active {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-file {
            border: 2px solid #327a30;
            padding: 2px 10px;
            svg {
              display: flex;
              margin: 0;
              font-size: 25px;
              color: #327a30;
            }
            &:active {
              background-color: #327a30;
              svg {
                fill: #fafafa;
              }
            }
            &:hover {
              background-color: #327a30;
              svg {
                fill: #fafafa;
              }
            }
          }

          &.active-restore {
            border: 2px solid #0065dd;
            svg {
              color: #0065dd;
            }
            &:active {
              background-color: #0065dd;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #0065dd;
              svg {
                color: #fafafa;
              }
            }
          }
        }
      }
    }

    .button-all {
      display: flex;
      min-width: 200px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      gap: 12px;
      background: #aeaeae;
      color: #fafafa;
      border-radius: 8px;
      border: none;
      height: 40px;
      cursor: pointer;

      &.disabled {
        background: #aeaeae;
        cursor: not-allowed;
      }

      svg {
        display: flex;
        margin: 0;
        font-size: 26px;
        color: #fafafa;
      }

      &.rut {
        background: #005bc7;
        border: 2px solid #005bc7;
        &:hover {
          background: #003d85;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        }
      }

      &.solicitar {
        background: #e6a200;
        border: 2px solid #e6a200;
        &:hover {
          background: #fafafa;
          color: #e6a200;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        }
      }

      &.continuar {
        background: #ff4900;
        border: 2px solid #ff4900;
        &:hover {
          color: #ff4900;
          background: #fafafa;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        }
      }
    }

    .container-filter {
      display: flex;
      justify-content: start;
      flex-direction: row;
      align-items: start;
      padding-top: 0px;
      padding-left: 0px;

      .content-filter {
        display: flex;
        width: 100%;
        justify-content: start;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #262626;
        align-items: center;
        text-transform: capitalize;
        gap: 10px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          border: 0;
          background: none;
          padding: 0px;

          svg {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0px;
            font-size: 26px;
            color: #a3a3a3;
          }
        }
      }
    }

    .content-header-table {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 30px;
      background: #003d85;
      margin: 0px 0px -12px 0px;
      @media (max-width: 480px) {
        padding: 16px 16px;
      }

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #fafafa;
      }
    }

    .container-table-list {
      width: 100%;
      overflow-x: auto;
    }

    .content-table {
      text-align: center;
      border-collapse: collapse;
      width: 100%;
      overflow-x: auto;

      thead {
        align-items: center;
        background: #d9e8fa;
        th {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          border-radius: 0px;
          padding: 4px 8px;
          color: #404040;
          height: 56px;

          .content-asisst-check {
            display: flex;
            justify-content: center;
            flex-direction: row;
            gap: 20px;

            .label-check {
              color: var(--texto-general-textos-negro, #404040);
              text-align: center;
              font-family: "Inter";
              font-size: 11.57px;
              font-style: normal;
              font-weight: 400;
              line-height: 15px;
            }
          }

          @media (min-width: 700px) and (max-width: 1100px) {
            padding: 2px;
            font-size: 14px;
            line-height: 19px;
            height: 48px;
          }
          @media (max-width: 700px) {
            padding: 4px;
            font-size: 14px;
            line-height: 19px;
            height: 48px;
          }
        }
      }

      tbody {
        justify-content: center;
        align-items: center;
        tr {
          background: #fafafa;
          &.selected {
            background: #e9ebf8;
            &.borderdashed {
              border-bottom: 1px dashed #ccc;
            }
          }
          &.validate {
            background: #ffe4d9;
          }
          &.contract {
            background: #e5f7e5;
          }
          &.checked {
            background: #e9ebf8;
          }
        }

        td {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          border-radius: 0px;
          padding: 4px 8px;
          text-align: center;
          text-transform: capitalize;
          color: #262626;
          height: 46px;

          @media (min-width: 700px) and (max-width: 1100px) {
            padding: 4px;
            font-size: 14px;
            line-height: 19px;
            height: 46px;
          }
          @media (max-width: 700px) {
            padding: 1px;
            font-size: 14px;
            line-height: 19px;
            height: 46px;
          }
          &.inactiva {
            color: #aeaeae;
            fill: #aeaeae;
          }

          &.icon-estado {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .textStado {
            font-size: 11.57px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
            text-align: justify;
            padding: 1em;
            min-width: 136px;
            min-height: 53px;
            align-content: center;
            display: flex;
            flex-wrap: wrap;
          }
          .image1 {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
            margin: 0;
          }

          .image2 {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            margin: 0;
            top: 50%;
            left: 46%;
            transform: translate(-44%, -52%);
            z-index: 2;
          }

          .image_1 {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
            margin: 0;
          }

          .image_2 {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            margin: 0;
            top: 50%;
            left: 46%;
            transform: translate(-42%, -50%);
            z-index: 2;
          }

          .imageA {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
            margin-right: 8px;
            width: 14.5px;
            height: 17px;
          }
          .imageB {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            margin: 0;
            top: -3%;
            right: -120%;
            transform: translate(-50%, -50%);
            z-index: 2;
          }

          .content-icon-table {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            @media (min-width: 700px) and (max-width: 1100px) {
              gap: 4px;
            }
            @media (max-width: 700px) {
              gap: 3px;
            }

            button {
              display: flex;
              border: none;
              background: none;
              padding: 2px;
              margin: 0;
              cursor: pointer;
              svg {
                display: flex;
                margin: 0;
                font-size: 24px;

                &.disabled {
                  background: none;
                  color: #aeaeae;
                  cursor: not-allowed;
                  fill: #aeaeae;
                }

                &.restore {
                  background: none;
                  color: #0065dd;
                  &:active {
                    background-color: #0065dd;
                    color: #fafafa;
                    border-radius: 50%;
                    padding: 1px;
                  }
                }

                &.edit {
                  background: none;
                  color: #0065dd;
                  &:active {
                    background-color: #0065dd;
                    color: #fafafa;
                    border-radius: 50%;
                    padding: 2px;
                  }
                }

                &.block {
                  background: none;
                  color: #d32f2f;
                  &:active {
                    background-color: #d32f2f;
                    color: #fafafa;
                    border-radius: 50%;
                    padding: 1px;
                    fill: #fafafa;
                    svg {
                      color: #fafafa;
                    }
                  }
                }

                &.cancel {
                  background: none;
                  color: #d32f2f;
                  &:active {
                    background-color: #d32f2f;
                    color: #fafafa;
                    border-radius: 50%;
                    padding: 1px;
                  }
                }

                &.active-restore {
                  background: none;
                  color: #0065dd;
                  fill:#0065DD;
                  &:active {
                    background-color: #0065dd;
                    color: #fafafa;
                    border-radius: 50%;
                    padding: 2.5px;
                    width: 20px;
                    height: 21px;
                    fill:#fafafa;
                    svg {
                      color: #fafafa;
                      fill:#fafafa;
                    }
                  }
                }

                &.active-registro {
                  background: none;
                  fill: #06a502;
                  &:active {
                    background-color: #06a502;
                    border-radius: 50%;
                    padding: 2.5px;
    width: 20px;
    height: 21px;
                    fill: #fafafa;
                    color: #fafafa;
                    svg {
                      color: #fafafa;
                      fill:#fafafa;
                    }
                  }}
                    &.active-block {
                      background: none;
                      color: #d32f2f;
                      fill: #d32f2f;
                      &:active {
                        background-color: #d32f2f;
                        border-radius: 50%;
                        padding: 2.5px;
                        width: 20px;
                        height: 21px;
                        color: #fafafa;
                        fill:#fafafa;
                        svg {
                          color: #fafafa;
                          fill:#fafafa;
                        }
                      }
                    }

                    &.active-edit {
                      background: none;
                      color: #FF4900;
                      fill:#FF4900;
                      &:active {
                        background-color: #FF4900;
                        border-radius: 50%;
                        padding: 2.5px;
                        width: 20px;
                        height: 21px;
                        color: #fafafa;
                        fill:#fafafa;
                        svg {
                          color: #fafafa;
                          fill:#fafafa;
                        }
                      }
                    }
                  
                
              }
            }
          }

          .validate-state {
            display: flex;
            justify-content: center;
            align-items: center;
            vertical-align: middle;

            .validate {
              display: flex;
              justify-content: center;
              color: #06a502;
              margin: 0;
              transform: rotateY(180deg);
            }
            .block {
              display: flex;
              justify-content: center;
              color: #d32f2f;
              margin: 0;
            }
            .discart {
              display: flex;
              justify-content: center;
              color: #d32f2f;
              margin: 0;
            }
          }

          .icon-check {
            color: #003d85;

            &.disabled {
              color: #dedede;
            }
          }
        }
      }
    }

    .footer-table {
      padding: 13px 10px;
      width: 100%;
      background: #f5f5f5;
      display: flex;
      vertical-align: middle;
      align-items: center;
      gap: 10px;
      position: relative;
      margin-top: -12px;
      @media (max-width: 770px) {
        display: flex;
        padding: 4px;
        gap: 5px;
        position: static;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
      }

      .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;

        input {
          padding: 8px 6px;
          font-size: 14px;
          button {
            svg {
              width: 10px;
            }
          }
        }

        .pagination-text {
          display: flex;
          justify-content: center;
          flex-direction: row;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          @media (max-width: 770px) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .pagination-number {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        position: absolute;
        @media (max-width: 770px) {
          position: static;
        }

        .number-pagination {
          display: flex;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          justify-content: center;
          text-align: center;
          align-items: center;
          vertical-align: middle;
          color: #fafafa;
          text-transform: capitalize;
          background: #61738e;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          @media (max-width: 770px) {
            font-size: 12px;
            width: 22px;
            height: 22px;
          }
        }

        button {
          display: flex;
          background: none;
          margin: 0;
          border: none;
          padding: 0px;
          cursor: pointer;

          svg {
            display: flex;
            margin: 0;
            color: #61738e;
            font-size: 32px;
            padding: 0px;
            @media (max-width: 770px) {
              font-size: 22px;
            }

            &.doble {
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              @media (max-width: 770px) {
                font-size: 24px;
              }
            }
            &.disabled {
              color: #aeaeae;
            }
          }
          &.disabled {
            cursor: not-allowed;
            svg {
              color: #aeaeae;
            }
          }
        }
      }
    }
  }
}
.headerHeight {
  height: 55px;
  font-size: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.bg-headerGrey2{
  background: #D4D4D4;
  font-weight: 600;
  font-size: 15px;
  font-family: Inter;
  line-height: 18px;
  min-height: 26px;
  justify-content: center;
  align-items: center;
  display: flex
}

.headerHeight2 {
  height: 26px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  align-items: center;
  display: flex;
  justify-content: center;
}

.contentHeight {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentHeight2 {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
} 

.font17 {
  font-size: 17px !important;
}
.textAsig{
  font-weight: 500;
  font-size: 16px;
  font-family: Inter;
  line-height: 20px;
}

.borderColorWhite{
  border-color: #FAFAFA;
}

.borderdashed {
  border-bottom: 1px dashed #ccc;
}

.active-filterDash{
  border: none;
  background: none;
        svg {
          background: none;
          color: #a1c0ed;
          display: flex;
          margin: 0;
          font-size: 40px;
        }
        &:active {
          background-color: #fafafa;
          svg {
            background: none;
            color: #fafafa;
          }
        }
}