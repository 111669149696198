@import "../ui/_config";

/** 
* TODO: NUEVO MODAL PARA SLEECCION
*/

.modal-container-generico {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 30px 0px;
  background-color: #1e1e1ece;
  z-index: 13;
  overflow: auto;

  .modal-clear-box {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 13;
  }

  &.filtro {
    overflow-y: none;
    position: fixed;
    padding: 30px 0px;
    background-color: transparent;
  }

  &.tabs {
    padding: 100px 0px;
  }

  .modal-content-generico {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: #fafafa;
    padding: 48px 71px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 24px;
    margin: auto;
    gap: 16px;
    z-index: 14;

    &.tabs {
      border-radius: 0px 24px 24px 24px;
    }

    &.filtro {
      background: #f5f5f5;
      padding: 48px 90px;
      border-radius: 1px;
      top: 40%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -0%);
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    }

    .modal-content-message {
      max-width: 100%;
      background-color: #fafafa;
      padding: 48px 46px;
      border: 1px solid #888;
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      border-radius: 24px;
      margin: auto;
    }

    .modal-close {
      display: flex;
      justify-content: end;
      color: #aaa;
      margin: -20px -20px -10px -10px;
      svg {
        display: flex;
        margin: 0;
        font-size: 30px;
        color: #48566b;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        margin: -10px 0px -5px -5px;
      }
    }

    .modal-close:hover,
    .modal-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    .modal-title {
      display: flex;
      justify-content: center;
      vertical-align: middle;
      align-items: center;
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;
      padding: 0px;
      gap: 10px;

      &.start {
        text-align: start;
      }

      &.filter {
        padding: 0px 0px 40px 0px;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .modal-subtitle {
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: $fontSizeMid;
      line-height: 20px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }

      &.end {
        text-align: end;
      }

      .block-message {
        color: #d32f2f;
        font-family: $fontFamilyInter;
        font-size: $fontSizeMid;
        font-style: italic;
        font-weight: 400;
        line-height: 19px;
      }
    }

    .modal-message {
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: $fontSizeMid;
      line-height: 20px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }

      &.end {
        text-align: end;
      }
    }

    /** 
    * TODO: NUEVO MODAL PARA SLEECCION
    */

    .tab-container {
      display: flex;
      position: relative;
      top: -105px;
      left: -72px;
      
      .tab-header {
        overflow-x: auto; /* Agregar esta línea para permitir desplazamiento horizontal */
        white-space: nowrap; /* Evitar el salto de línea */
        position: absolute;
        
        .tab-btn {
          cursor: pointer;
          padding: 8px 16px;
          text-align: left;
          margin-right: 5px;
          border: 1px solid #e5e5e5;
          border-bottom: none;
          border-radius: 8px 8px 0px 0px;
          font-family: $fontFamilyInter;
          font-size: $fontSizeMid;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;

          &.active {
            background-color: #fafafa;
          }
        }
      }
    }
  }
}

.project-table-Mo {
  display: flex;
  border: none;
  margin-bottom: 1em;
  &.sin {
    border: none;
    gap: 20px;
    margin-left: 30px;
    margin-right: 15px;
  }
  &.disabled {
    display: none;
    transition: all 0.3s ease;
  }

  .container-table-Mo {
    width: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 0px;
    border: none;

    &.sin {
      border: none;
      gap: 20px;
      align-items: none;
      display: contents;
    }

    .title {
      display: flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      padding: 4px;

      @media (max-width: 700px) {
        font-size: 15px;
      }
    }

    .content-table-Mo {
      text-align: center;
      border-collapse: collapse;
      width: 100%;
      overflow-x: auto;
      thead {
        align-items: center;
        background: #d4d4d4;

        th {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          border-radius: 0px;
          color: #262626;
          height: 25px;
        }
      }

      tbody {
        justify-content: center;
        align-items: center;
        tr {
          background: #fafafa;
          &.selected {
            background: #e2ebf9;
          }
          &.validate {
            background: #ffe4d9;
          }
          &.contract {
            background: #e5f7e5;
          }
          &.checked {
            background: #e2ebf9;
          }
        }

        td {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 23px;
          border-radius: 0px;
          padding: 4px 8px;
          text-align: center;
          text-transform: capitalize;
          color: #262626;
          height: 28px;
          border-bottom: 1px dashed #d4d4d4;

          @media (min-width: 700px) and (max-width: 1100px) {
            padding: 4px;
            font-size: 14px;
            line-height: 19px;
            height: 46px;
          }
          @media (max-width: 700px) {
            padding: 1px;
            font-size: 14px;
            line-height: 19px;
            height: 46px;
          }
          &.inactiva {
            color: #aeaeae;
            fill: #aeaeae;
          }
          .content-icon-table {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            @media (min-width: 700px) and (max-width: 1100px) {
              gap: 4px;
            }
            @media (max-width: 700px) {
              gap: 3px;
            }
          }
        }
      }
    }
  }
}
