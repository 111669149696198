@import "../_config";

.container-table-asignar-proceso {
  display: flex;
  justify-content: center;
  align-items: center;

  .content-table-asignar-proceso {
    display: flex;
    width: 100%;

    .table-asignar-proceso {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          margin: 20px;
          background: #eaeaea;
          th {
            color: var(--texto-general-textos-negro, #404040);
            text-align: center;
            font-family: $fontFamilyInter;
            font-size: $fontSizeMid;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            padding: 8px;
          }
        }
      }
      tbody {
        tr {
          td {
            color: var(--texto-general-textos-negro, #404040);
            text-align: center;
            font-family: $fontFamilyInter;
            font-size: $fontSizeMid;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding: 8px;
          }
        }

        tr:nth-child(even) {
          background-color: #f5f5f5;
        }

        tr:nth-child(odd) {
          background-color: #fdfdfd;
        }
      }
    }
  }
}
