.tabs_tw .tab_tw .radioClass{
  display:none;
}
.tabs_tw .tab_tw{
 overflow: hidden;
 width:25%;  
 flex: auto;
}
.tabs_tw .content_tab {
  position: absolute;
  z-index:0;
  opacity:0;
  padding:0;
  top:340px;
  left:36%;
  right:22%;
  color: black;
  background:#ffffff;
  border-radius: 8px;   
}

.tabs_tw>.tab_tw .radioClass:checked ~ .content_tab {
  z-index:1;
  opacity:1;
}
.tabs_tw .tab_tw .label_tw_tab {
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: 'Roboto', normal;
  font-size: 18px;
  font-weight: 600;
  padding:10px;
  cursor: pointer;
  color: #333333;
  background:#f1f1f1;
  margin-bottom: 1px;
  /*border-radius:0px 5px 5px 0px;*/
  box-shadow: 0 3px 0px  rgba(0, 0, 0, 0.2);
  width:270px;
  height: 80px;
}


.header{
  color: Black;
  font-family: 'Roboto', normal;
  font-size: 15px;
  text-align: center;
  margin-top:20px;
}

.tabs_tw{
 
  /*margin-right:20%;*/
  padding:0;
  background:#ffffff;
  border-radius:8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;

}

.tabs_tw .tab_tw:first-child {
  border-top-left-radius: 8px;
}

.tabs_tw .tab_tw:last-child {
  border-bottom-left-radius: 8px;
}

.tabs_tw .tab_tw .label_tw_tab:hover{
background:#5AB1D0;
transition:all 400ms ease;
}


.tabs_tw .tab_tw .radioClass:checked + .label_tw_tab {
  background: #ffffff;
  color: #0065DD;
  border-left: 4px solid rgba(0, 101, 221, 0.3);;
  border-right: -5px;

  
}


@media only screen and (max-width: 1200px) {  
  .tabs_tw .tab_tw .label_tw_tab {
    font-size: 12px;
    width:170px;
  }

  .tabs_tw .content_tab {
    left:36%;
  }
}  
@media only screen and (max-width: 820px)  {  
  .tabs_tw .tab_tw .label_tw_tab {
    font-size: 12px;
    width:90px;
  }

  .tabs_tw .content_tab {
    left:46%;
  }

  h1{
    font-size: 24px;
  }
}

/* Para 480px */  
@media only screen and (max-width: 500px)  {  
  .tabs_tw .tab_tw .label_tw_tab {
    font-size: 12px;
    width:90px;
  }
  .tabs_tw .content_tab {
    left:46%;
    top:380px;
  }

  h1{
    font-size: 18px;
  }
} 


/* Para 320px */  
@media only screen and (max-width: 340px)  {  
  .tabs_tw .tab_tw .label_tw_tab {
    font-size: 12px;
    width:70px;
  }

  .tabs_tw .content_tab {
    left:46%;
    top:380px;
  }

  h1{
    font-size: 18px;
  }
} 

.colorDisabled{
  color: #D5D5D5 !important;
}

.tabs_tw .tab_tw .colorDisabled:hover{
  background:#f1f1f1;
  transition:all 400ms ease;
  }
