@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "../ui/_config";

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #fafafa;
}

.container-validation-kam {
  display: flex;
  background-color: #fafafa;
  width: 100%;
  justify-content: center;

  .content-validation-kam {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 20px 0px;
    gap: 16px 0px;

    .content-nav {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      text-align: center;
      gap: 0px 25px;
      margin: 10px 0px;

      .nav-tag {
        display: flex;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #61738e;
        cursor: pointer;
        &.active {
          text-decoration-line: underline;
        }
        &:active {
          color: #748cb1;
        }
      }
    }

    .div-menu-responsive {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button-nav-menu {
        display: flex;
        margin: 0;
        border: none;
        background: #fafafa;
        position: relative;
        svg {
          display: flex;
          font-size: 26px;
          color: #48566b;
          margin: 0;
        }
        &:active {
          svg {
            font-size: 28px;
            color: #5e7599;
          }
        }
      }

      .div-nav-ul {
        display: flex;
        position: absolute;
        margin: 0;
        right: 20px;
        top: 50px;

        .list-nav {
          width: 250px;
          display: flex;
          justify-content: center;
          text-align: start;
          vertical-align: middle;
          flex-direction: column;
          background: #fafafa;
          border-radius: 4px;
          border: 1px solid #242424;
          padding: 5px 2px;
          list-style-type: none;
          z-index: 15;
          .li-nav {
            padding: 3px 16px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #61738e;
            &:hover {
              background: #dedede;
            }
            &.active {
              text-decoration-line: underline;
              background: #dedede;
            }
          }
        }
      }

      .InputSelect {
        position: relative;
        display: inline-block;
        font-size: 14px;

        .InputSelect__select {
          display: block;
          width: 100%;
          padding: 8px 26px 8px 10px;
          font-size: 14px;
          font-family: "Inter";
          font-style: normal;
          border: 1px solid #dedede;
          border-radius: 8px;
          appearance: none;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23ccc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>');
          background-repeat: no-repeat;
          background-position: right 4px center;
          background-size: 12px 12px;
          transition: border-color 0.3s ease-out;
          &:focus {
            border-color: #007bff;
            outline: none;
          }
        }
      }
    }

    .title-tw-cliente {
      color: #005bc7;
      font-family: "Inter";
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px;
      margin-top: -30px;
    }

    .content-nav-asistencia {
      display: flex;
      justify-content: start;
      gap: 24px;
      flex-flow: row wrap;

      .nav-tag-button {
        font-style: normal;
        font-weight: 500;
        align-items: center;
        padding: 7px 14px;
        font-family: "Inter";
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        color: #aeaeae;
        background: #fafafa;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        border-radius: 8px;
        border: none;
        cursor: pointer;
        &:hover {
          background: #f2f2f2;
        }

        &.active {
          color: #0065dd;
        }
      }
    }

    .title-welcome {
      display: flex;
      text-align: center;
      justify-content: start;
      font-family: "Inter";
      font-weight: 700;
      font-size: 22px;
      color: #005bc7;

      &.inicio {
        flex-direction: center;
        text-align: center;
      }
    }

    .title-page {
      display: flex;
      justify-content: center;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 44px;
      color: #262626;
      @media (max-width: 700px) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 17.07px;
        line-height: 22px;
      }
    }

    .title-page-intro {
      display: flex;
      justify-content: center;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 0px;
      color: #262626;
      @media (max-width: 700px) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 17.07px;
        line-height: 22px;
      }
    }

    .subtitle-page {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #040404;
      @media (max-width: 700px) {
        font-size: 13.17px;
        line-height: 16px;
      }
    }

    .section-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;
      background: #005bc7;
      margin: 12px 0px;
      cursor: pointer;

      .section-option {
        display: flex;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #fafafa;
      }

      svg {
        font-size: 30px;
        color: #fafafa;
      }
    }

    .content-actions {
      display: flex;
      //justify-content: space-between;
      gap: 20px;
      flex-direction: row;
      flex-flow: row wrap;
      margin-top: 25px;
      margin-bottom: 30px;

      @media (max-width: 700px) {
        gap: 10px;
        flex-direction: column;
        justify-content: center;
      }

      &.actions-old {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      &.contrato {
        flex-flow: row nowrap;
        margin-bottom: 0px;
      }

      .content-enviar {
        display: flex;
        gap: 60px;

        @media (max-width: 700px) {
          gap: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .navigation {
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-items: center;
          padding: 4px 26px;
          color: #fafafa;
          border-radius: 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          border: 2px solid #aeaeae;
          height: 36px;
          width: auto;
          gap: 10px;

          @media (min-width: 700px) and (max-width: 1100px) {
            padding: 4px 22px;
          }
          @media (max-width: 700px) {
            justify-content: center;
            width: 80%;
            padding: 4px;
            font-weight: 500;
            line-height: 20px; /* 125% */
          }

          &.disabled {
            cursor: no-drop;
            background: #aeaeae;
          }

          svg {
            display: flex;
            margin: 0;
            font-size: 24px;
            color: #fafafa;
          }

          &.active {
            cursor: pointer;
            background: #ff4900;
            border: 2px solid #ff4900;
            &:hover {
              background: #fafafa;
              color: #ff4900;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              svg {
                color: #ff4900;
              }
            }
          }

          &.active-green {
            cursor: pointer;
            background: #06a502;
            border: 2px solid #06a502;

            &:hover {
              background: #fafafa;
              color: #06a502;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              svg {
                color: #06a502;
              }
            }
          }

          &.active-red {
            cursor: pointer;
            background: #d32f2f;
            border: 2px solid #d32f2f;

            &:hover {
              background: #fafafa;
              color: #d32f2f;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              svg {
                color: #d32f2f;
              }
            }
          }

          &.active-red2 {
            cursor: pointer;
            background: #fafafa;
            border: 2px solid #d32f2f;
            color: #d32f2f;
            svg {
              color: #d32f2f;
            }
            &:hover {
              background:#d32f2f ;
              color: #fafafa;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              svg {
                color: #fafafa;
              }
            }
          }
        }
      }

      .content-actions-masive {
        display: flex;
        justify-content: end;
        gap: 25px;
        margin-left: auto;

        .button-actions {
          display: flex;
          justify-content: center;
          align-items: center;
          vertical-align: middle;
          background: none;
          border-radius: 100%;
          border: none;
          margin: 0;
          cursor: pointer;
          width: 36px;
          height: 36px;

          svg {
            display: flex;
            margin: 0;
            font-size: 24px;
          }

          &.disabled {
            border: 2px solid #aeaeae;
            cursor: no-drop;
            svg {
              color: #aeaeae;
            }
            &.file {
              padding: 2px 10px;
            }
          }

          &.active-check {
            border: 2px solid #ff4900;
            svg {
              color: #ff4900;
            }
            &:active {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-send {
            border: 2px solid #ff4900;
            svg {
              color: #ff4900;
            }
            &:active {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #ff4900;
              svg {
                color: #fafafa;
                fill: #fafafa;
              }
            }
          }

          &.active-cancel {
            border: 2px solid #d32f2f;
            svg {
              color: #d32f2f;
            }
            &:active {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-gris {
            border: 2px solid $colorGris;
            svg {
              color: $colorGris;
              fill: $colorGris;
            }
            &:active {
              background-color: $colorGris;
              svg {
                color: #fafafa;
                fill: #fafafa;
              }
            }
            &:hover {
              background-color: $colorGris;
              svg {
                color: #fafafa;
                fill: #fafafa;
              }
            }
          }

          &.active-block {
            border: 2px solid #d32f2f;
            svg {
              color: #d32f2f;
            }
            &:active {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-file {
            border: 2px solid #327a30;
            padding: 2px 10px;
            svg {
              display: flex;
              margin: 0;
              font-size: 25px;
              color: #327a30;
            }
            &:active {
              background-color: #327a30;
              svg {
                fill: #fafafa;
              }
            }
            &:hover {
              background-color: #327a30;
              svg {
                fill: #fafafa;
              }
            }
          }

          &.active-datos {
            border: 2px solid #06a502;
            padding: 2px 10px;
            svg {
              display: flex;
              margin: 0;
              font-size: 25px;
              color: #06a502;
            }
            &:active {
              background-color: #06a502;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #06a502;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-restore {
            border: 2px solid #0065dd;
            svg {
              color: #0065dd;
            }
            &:active {
              background-color: #0065dd;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #0065dd;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-filter {
            border: none;
            svg {
              background: none;
              color: #a1c0ed;
              display: flex;
              margin: 0;
              font-size: 40px;
            }
            &:active {
              background-color: #fafafa;
              svg {
                background: none;
                color: #fafafa;
              }
            }
          }
        }
      }
    }

    .container-actions {
      display: flex;
      align-items: center;
      justify-content: end;
      flex-flow: row wrap;
      gap: 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &.actions-old {
        margin-top: 0px;
        margin-bottom: 0px;
        justify-content: end;
      }

      .content-filter {
        display: flex;
        justify-content: start;
        align-items: center;

        .button-filter {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          border-radius: 4px;
          margin: 0;
          background: #f7f7f7;
          padding: 6px 10px;
          border: 1px solid #d4d4d4;
          svg {
            display: flex;
            margin: 0;
            color: #a3a3a3;
            font-size: 24px;
            &:hover {
              background: none;
            }
            &:active {
              border-radius: 0px;
              background: none;
              border: none;
              transform: none;
            }
          }
          &:active {
            background: #e4e4e4;
          }
        }

        .div-container-filter {
          display: flex;
          position: relative;
          padding: 10px;

          .div-filter {
            width: 200px;
            gap: 5px;
            display: none;
            position: absolute;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border: 1px solid #242424;
            border-radius: 4px;
            background-color: #fafafa;
            padding: 10px;
            top: 30px;
            left: -80px;
            z-index: 14;
            &.show {
              display: flex;
            }
          }
        }
      }

      .content-actions-masive {
        display: flex;
        justify-content: end;
        gap: 25px;

        .button-actions {
          display: flex;
          justify-content: center;
          align-items: center;
          vertical-align: middle;
          background: none;
          border-radius: 100%;
          margin: 0;
          width: 36px;
          height: 36px;
          cursor: pointer;

          svg {
            display: flex;
            margin: 0;
            font-size: 24px;
          }

          &.disabled {
            border: 2px solid #aeaeae;
            cursor: no-drop;
            svg {
              color: #aeaeae;
            }
            &.file {
              padding: 2px 10px;
            }
          }

          &.active-check {
            border: 2px solid #ff4900;
            svg {
              color: #ff4900;
            }
            &:active {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-send {
            border: 2px solid #ff4900;
            svg {
              color: #ff4900;
            }
            &:active {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #ff4900;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-cancel {
            border: 2px solid #d32f2f;
            svg {
              color: #d32f2f;
            }
            &:active {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-block {
            border: 2px solid #d32f2f;
            svg {
              color: #d32f2f;
            }
            &:active {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #d32f2f;
              svg {
                color: #fafafa;
              }
            }
          }

          &.active-file {
            border: 2px solid #327a30;
            padding: 2px 10px;
            svg {
              display: flex;
              margin: 0;
              font-size: 25px;
              color: #327a30;
            }
            &:active {
              background-color: #327a30;
              svg {
                fill: #fafafa;
              }
            }
            &:hover {
              background-color: #327a30;
              svg {
                fill: #fafafa;
              }
            }
          }

          &.active-restore {
            border: 2px solid #0065dd;
            svg {
              color: #0065dd;
            }
            &:active {
              background-color: #0065dd;
              svg {
                color: #fafafa;
              }
            }
            &:hover {
              background-color: #0065dd;
              svg {
                color: #fafafa;
              }
            }
          }
        }
      }
    }

    .button-all {
      display: flex;
      min-width: 200px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      gap: 12px;
      background: #aeaeae;
      color: #fafafa;
      border-radius: 8px;
      border: none;
      height: 40px;
      cursor: pointer;

      &.disabled {
        background: #aeaeae;
        cursor: not-allowed;
      }

      svg {
        display: flex;
        margin: 0;
        font-size: 26px;
        color: #fafafa;
      }

      &.rut {
        background: #005bc7;
        border: 2px solid #005bc7;
        &:hover {
          background: #003d85;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        }
      }

      &.solicitar {
        background: #e6a200;
        border: 2px solid #e6a200;
        &:hover {
          background: #fafafa;
          color: #e6a200;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        }
      }

      &.continuar {
        background: #ff4900;
        border: 2px solid #ff4900;
        &:hover {
          color: #ff4900;
          background: #fafafa;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        }
      }
    }

    .container-filter {
      display: flex;
      justify-content: start;
      flex-direction: row;
      align-items: start;
      padding-top: 0px;
      padding-left: 0px;

      .content-filter {
        display: flex;
        width: 100%;
        justify-content: start;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #262626;
        align-items: center;
        text-transform: capitalize;
        gap: 10px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          border: 0;
          background: none;
          padding: 0px;

          svg {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0px;
            font-size: 26px;
            color: #a3a3a3;
          }
        }
      }
    }

    .content-header-table {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 30px;
      background: #003d85;
      margin: 0px 0px -12px 0px;
      @media (max-width: 480px) {
        padding: 16px 16px;
      }

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #fafafa;
      }
    }

    .container-table-list {
      width: 100%;
      overflow-x: auto;
    }

    .content-table {
      text-align: center;
      border-collapse: collapse;
      width: 100%;
      overflow-x: auto;

      thead {
        align-items: center;
        background: #d9e8fa;
        th {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          border-radius: 0px;
          padding: 4px 8px;
          color: #404040;
          height: 56px;

          .content-asisst-check {
            display: flex;
            justify-content: center;
            flex-direction: row;
            gap: 20px;

            .label-check {
              color: var(--texto-general-textos-negro, #404040);
              text-align: center;
              font-family: "Inter";
              font-size: 11.57px;
              font-style: normal;
              font-weight: 400;
              line-height: 15px;
            }
          }

          @media (min-width: 700px) and (max-width: 1100px) {
            padding: 2px;
            font-size: 14px;
            line-height: 19px;
            height: 48px;
          }
          @media (max-width: 700px) {
            padding: 4px;
            font-size: 14px;
            line-height: 19px;
            height: 48px;
          }
          .button-select {
            border: none;
            background: none;
            cursor: pointer;
          }
        }
      }

      tbody {
        justify-content: center;
        align-items: center;
        tr {
          background: #fafafa;
          &.selected {
            background: #e2ebf9;
          }
          &.validate {
            background: #ffe4d9;
          }
          &.contract {
            background: #e5f7e5;
          }
          &.checked {
            background: #e9ebf8;
          }
        }

        td {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          border-radius: 0px;
          padding: 4px 8px;
          text-align: center;
          text-transform: capitalize;
          color: #262626;
          height: 46px;

          @media (min-width: 700px) and (max-width: 1100px) {
            padding: 4px;
            font-size: 14px;
            line-height: 19px;
            height: 46px;
          }
          @media (max-width: 700px) {
            padding: 1px;
            font-size: 14px;
            line-height: 19px;
            height: 46px;
          }
          &.inactiva {
            color: #aeaeae;
            fill: #aeaeae;
          }

          .button-select {
            border: none;
            background: none;
            cursor: pointer;
          }

          &.icon-estado {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .textStado {
            font-size: 11.57px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
            text-align: justify;
            padding: 1em;
            min-width: 136px;
            min-height: 53px;
            align-content: center;
            display: flex;
            flex-wrap: wrap;
          }
          .image1 {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
            margin: 0;
          }

          .image2 {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            margin: 0;
            top: 50%;
            left: 46%;
            transform: translate(-44%, -52%);
            z-index: 2;
          }

          .image_1 {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
            margin: 0;
          }

          .image_2 {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            margin: 0;
            top: 50%;
            left: 46%;
            transform: translate(-42%, -50%);
            z-index: 2;
          }

          .imageA {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
            margin-right: 8px;
            width: 14.5px;
            height: 17px;
          }
          .imageB {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            margin: 0;
            top: -3%;
            right: -120%;
            transform: translate(-50%, -50%);
            z-index: 2;
          }

          .content-state {
            display: flex;
            justify-content: center;
            align-items: center;
            vertical-align: center;
            gap: 10px;

            .container-state {
              display: flex;
              align-items: center;
              flex-wrap: row;
              justify-content: center;
              text-align: center;
              gap: 5px;
            }

            .content-state-icon {
              display: flex;
              justify-content: center;
              width: 20%;

              .content-icon {
                display: flex;
                margin: 0;
              }
            }

            .button-select {
              display: flex;
              justify-content: center;
              margin: 0;
              background: transparent;
              border: none;
              svg {
                display: flex;
                margin: 0;
              }

              &:disabled {
                cursor: not-allowed;
              }
            }

            .content-state-text {
              display: flex;
              flex-direction: column;
              justify-content: start;
              text-align: start;
              width: 80%;

              .title {
                display: flex;
                width: 100%;
                font-family: $fontFamilyInter;
                font-size: $fontSizeXSmall;
                font-style: normal;
                font-weight: 400;
                line-height: 15px;
                &.registro-verde {
                  color: $colorVerde;
                }
                &.registro-azul {
                  color: $azulFlick;
                }
                &.registro-rosado {
                  color: $colorRosado;
                }
                &.registro-gris {
                  color: $colorGris;
                }
                &.registro-rojo {
                  color: $colorRojo;
                }

                &.conducta-inadecuada,
                &.proceso-legal {
                  color: $colorRojo;
                }
                &.contrato-activo {
                  color: $colorVerde;
                }
                &.registro-completo {
                  color: $colorVerde;
                }
                &.registro-incompleto {
                  color: $colorAmarillo;
                }
                &.proceso-interno {
                  color: $colorAmarillo;
                }
                &.solicitud-recibida {
                  color: $colorNaranjo;
                }
                &.solicitud-enviada {
                  color: $colorAmarillo;
                }
                &.solicitud-rechazada {
                  color: $colorRojo;
                }
                &.registro-ocupado {
                  color: $colorRojo;
                }
              }

              .message {
                display: flex;
                width: 100%;
                color: #404040;
                font-family: $fontFamilyInter;
                font-size: $fontSizeXSmall;
                font-weight: 400;
                line-height: 15px;
              }
            }
          }

          .content-icon-table {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            @media (min-width: 700px) and (max-width: 1100px) {
              gap: 4px;
            }
            @media (max-width: 700px) {
              gap: 3px;
            }

            button {
              display: flex;
              border: none;
              background: none;
              padding: 2px;
              margin: 0;
              cursor: pointer;
              svg {
                display: flex;
                margin: 0;
                font-size: 24px;

                &.disabled {
                  background: none;
                  color: #aeaeae;
                  cursor: not-allowed;
                  fill: #aeaeae;
                }

                &.restore {
                  background: none;
                  color: #0065dd;
                  &:active {
                    background-color: #0065dd;
                    color: #fafafa;
                    border-radius: 50%;
                    padding: 1px;
                  }
                }

                &.edit {
                  background: none;
                  color: #0065dd;
                  &:active {
                    background-color: #0065dd;
                    color: #fafafa;
                    border-radius: 50%;
                    padding: 2px;
                  }
                }

                &.block {
                  background: none;
                  color: #d32f2f;
                  &:active {
                    background-color: #d32f2f;
                    color: #fafafa;
                    border-radius: 50%;
                    padding: 1px;
                    fill: #fafafa;
                    svg {
                      color: #fafafa;
                    }
                  }
                }

                &.cancel {
                  background: none;
                  color: #d32f2f;
                  &:active {
                    background-color: #d32f2f;
                    color: #fafafa;
                    border-radius: 50%;
                    padding: 1px;
                  }
                }

                &.active-restore {
                  background: none;
                  color: #0065dd;
                  &:active {
                    background-color: #0065dd;
                    color: #fafafa;
                    border-radius: 50%;
                    padding: 1px;
                  }
                }

                &.active-registro {
                  background: none;
                  fill: #06a502;
                  &:active {
                    background-color: #06a502;
                    border-radius: 50%;
                    padding: 1.5px;
                    fill: #fafafa;
                    color: #fafafa;
                    svg {
                      color: #fafafa;
                    }

                    &.active-block {
                      background: none;
                      color: #d32f2f;
                      &:active {
                        background-color: #d32f2f;
                        border-radius: 50%;
                        padding: 1.5px;
                        color: #fafafa;
                        svg {
                          color: #fafafa;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .validate-state {
            display: flex;
            justify-content: center;
            align-items: center;
            vertical-align: middle;

            .validate {
              display: flex;
              justify-content: center;
              color: #06a502;
              margin: 0;
              transform: rotateY(180deg);
            }
            .block {
              display: flex;
              justify-content: center;
              color: #d32f2f;
              margin: 0;
            }
            .discart {
              display: flex;
              justify-content: center;
              color: #d32f2f;
              margin: 0;
            }
          }

          .icon-check {
            color: #003d85;

            &.disabled {
              color: #dedede;
            }
          }
        }
      }
    }

    .footer-table {
      padding: 13px 10px;
      width: 100%;
      background: #f5f5f5;
      display: flex;
      vertical-align: middle;
      align-items: center;
      gap: 10px;
      position: relative;
      margin-top: -12px;
      @media (max-width: 770px) {
        display: flex;
        padding: 4px;
        gap: 5px;
        position: static;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
      }

      .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;

        input {
          padding: 8px 6px;
          font-size: 14px;
          button {
            svg {
              width: 10px;
            }
          }
        }

        .pagination-text {
          display: flex;
          justify-content: center;
          flex-direction: row;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          @media (max-width: 770px) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .pagination-number {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        position: absolute;
        @media (max-width: 770px) {
          position: static;
        }

        .number-pagination {
          display: flex;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          justify-content: center;
          text-align: center;
          align-items: center;
          vertical-align: middle;
          color: #fafafa;
          text-transform: capitalize;
          background: #61738e;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          @media (max-width: 770px) {
            font-size: 12px;
            width: 22px;
            height: 22px;
          }
        }

        button {
          display: flex;
          background: none;
          margin: 0;
          border: none;
          padding: 0px;
          cursor: pointer;

          svg {
            display: flex;
            margin: 0;
            color: #61738e;
            font-size: 32px;
            padding: 0px;
            @media (max-width: 770px) {
              font-size: 22px;
            }

            &.doble {
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              @media (max-width: 770px) {
                font-size: 24px;
              }
            }
            &.disabled {
              color: #aeaeae;
            }
          }
          &.disabled {
            cursor: not-allowed;
            svg {
              color: #aeaeae;
            }
          }
        }
      }
    }

    // REMPLAZO DE TABLA PARA COLAPSAR BIEN PERRON
  }
}

.imageTooltip1 {
  display: flex;
  position: relative;
  margin-bottom: 12px;
  z-index: 1;
}

.imageTooltip2 {
  display: flex;
  position: absolute;
  margin-bottom: 12px;
  top: 50%;
  left: 4%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

/////////////////////////// modal design ///////////////////////////
/////////////////////////// modal design ///////////////////////////

.modal-container-validatekam {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #1e1e1ece;
  z-index: 13;
  overflow-y: scroll;

  .modal-clear-box {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 13;
  }

  &.edit {
    overflow-y: scroll;
    padding: 30px 0px;
  }

  &.filtro {
    overflow-y: none;
    position: fixed;
    padding: 30px 0px;
    background-color: transparent;
  }

  .modal-content-validatekam {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: #fafafa;
    padding: 48px 46px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 24px;
    margin: auto;
    gap: 16px;
    z-index: 14;

    &.filtro {
      background: #f5f5f5;
      padding: 48px 90px;
      border-radius: 1px;
      top: 40%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -0%);
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    }

    &.w-90 {
      width: 90%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 95%;
        padding: 32px 18px;
        gap: 10px;
      }
    }

    &.w-80 {
      width: 80%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 96%;
        padding: 32px 14px;
        gap: 10px;
      }
    }

    &.w-70 {
      width: 70%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 14px;
        gap: 10px;
      }
    }

    &.w-60 {
      width: 60%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 80%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    &.w-45 {
      width: 45%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 60%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    &.w-30 {
      width: 30%;

      @media (min-height: 491px) {
        width: 30%;
        padding: 20px 18px;
      }
      @media (max-width: 416px) {
        width: 30%;
        padding: 20px 18px;
      }
    }

    .secciones-modal {
      cursor: pointer;
    }

    .modal-content-message {
      max-width: 100%;
      background-color: #fafafa;
      padding: 48px 46px;
      border: 1px solid #888;
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      border-radius: 24px;
      margin: auto;
    }

    .modal-close {
      display: flex;
      justify-content: end;
      color: #aaa;
      margin: -20px -20px -10px -10px;
      svg {
        display: flex;
        margin: 0;
        font-size: 30px;
        color: #48566b;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        margin: -10px 0px -5px -5px;
      }
    }

    .modal-close:hover,
    .modal-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    .modal-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;
      padding: 1px;

      &.start {
        text-align: start;
      }

      &.filter {
        padding: 0px 0px 40px 0px;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .modal-subtitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }

      .block-message {
        color: #d32f2f;
        font-family: "inter";
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 19px;
      }
    }

    .modal-message {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    .modal-message-restaurar {
      display: flex;
      flex-direction: column;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    .modal-message-bottom {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    /** 
    * TODO: Tabla ficha bien perron 
    */
    .separador-seccion {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      border-bottom: 1px solid #dedede;
    }

    .area-table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: -12px 0px;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #404040;
      }
      svg {
        display: flex;
        margin: 0;
        justify-content: center;
        align-items: center;
        font-size: 34px;
        color: #404040;
        transition: all 0.3s ease;
        &.disabled {
          transform: rotate(180deg);
          transition: all 0.3s ease;
        }
      }
    }

    .project-table {
      display: flex;
      width: 100%;
      &.disabled {
        display: none;
        transition: all 0.3s ease;
      }

      .container-table {
        width: 100%;
        display: flex;
        vertical-align: middle;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px 0px;

        @media (max-width: 700px) {
          gap: 6px 0px;
        }
        &.container-table2 {
          width: 100%;
          display: flex;
          vertical-align: middle;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px 30px;

          @media (max-width: 700px) {
            gap: 6px 0px;
          }
        }
        .title {
          display: flex;
          vertical-align: middle;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          padding: 4px;

          @media (max-width: 700px) {
            font-size: 15px;
          }
        }

        .content-table {
          display: flex;
          flex-direction: column;
          border: 1px solid #b0c1d5;
          @media (min-width: 1100px) {
            width: calc(100% / 4);
          }
          @media (min-width: 700px) and (max-width: 1100px) {
            width: calc(100% / 2);
          }
          @media (max-width: 700px) {
            width: calc(100% / 1);
          }

          &.uno {
            @media (min-width: 1100px) {
              width: calc(100% / 1);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 1);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          &.dos {
            @media (min-width: 1100px) {
              width: calc(100% / 2);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 2);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          &.tres {
            @media (min-width: 1100px) {
              width: calc(100% / 3);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 2);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          &.cuatro {
            @media (min-width: 1100px) {
              width: 22%;
              &.ending {
                width: 34%;
              }
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 2);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          &.cinco {
            @media (min-width: 1100px) {
              width: calc(100% / 5);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 2);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          .subtitle-content-age {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
          }

          .title-table {
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 45px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            padding: 10px 0px;
            background: #D9E1EA;
            border-bottom: 1px solid #b0c1d5;
            @media (max-width: 700px) {
              font-size: 14px;
              min-height: 40px;
              line-height: 20px;
              padding: 4px;
            }
          }

          .subtitle-table {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 45px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            @media (max-width: 700px) {
              font-size: 14px;
              min-height: 40px;
              line-height: 20px;
              padding: 4px;
            }
            &.cinco {
              background: #d9e1ea;
              border-left: 1px solid #b0c1d5;
              width: 25%;
              gap: 1px;
              &:first-child {
                border-left: none;
              }
              &:nth-child(2) {
                background: #D9E1EA;
              }
              &:nth-child(4) {
                background: #D9E1EA;
              }
            }
          }
        }

        .content-table-row-data {
          display: flex;
          width: 100%;
          flex-direction: row;
          @media (min-width: 280px) and (max-width: 1100px) {
            flex-direction: column;
          }
        }

        .iconSquare {
          padding-right: 30px;
          align-content: center;
          align-items: center;
          @media (min-width: 280px) and (max-width: 1100px) {
            width: calc(100%);
            justify-content: center;
            display: flex;
            padding-top: 10px;
          }
        }

        .content-table-row {
          display: flex;
          vertical-align: center;
          align-items: center;
          width: calc(100% / 1);

          &.sin-elign-center{
            align-items:normal;
          }
          &.dos {
            @media (min-width: 280px) and (max-width: 1100px) {
              width: calc(100% / 1);
              flex-direction: row;
            }
          }
          &.tres {
            width: 31%;
            gap: 45px;
            @media (min-width: 280px) and (max-width: 1100px) {
              width: calc(100% / 1);
              flex-direction: row;
            }
          }
          &.cuatro {
            width: 48%;
            gap: 45px;
            @media (min-width: 280px) and (max-width: 1100px) {
              width: calc(100% / 1);
              flex-direction: row;
            }
          }

          &.documento {
            width: calc(100% / 2);
            @media (min-width: 280px) and (max-width: 1100px) {
              width: calc(100% / 1);
              flex-direction: row;
            }
          }

          .title-table-row {
            display: flex;
            justify-content: start;
            align-items: center;
            max-height: 45px;
            min-height: 45px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            padding: 10px 16px;
            background: #D9E1EA;
            border: 1px solid #b0c1d5;
            width: 50%;
            @media (max-width: 700px) {
              width: 100%;
              font-size: 14px;
              min-height: 40px;
              line-height: 20px;
              padding: 4px;
            }

            &.dos {
              width: 70%;
              @media (max-width: 700px) {
                width: 100%;
              }
            }
            &.tres {
              width: 56%;
              @media (min-width: 500px) {
                width: 100%;
              }
            }
          }

          .subtitle-table-row {
            display: flex;
            justify-content: start;
            align-items: center;
            max-height: 45px;
            min-height: 45px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            padding: 10px 20px;
            border: 1px solid #b0c1d5;
            width: 50%;
            @media (max-width: 700px) {
              width: 100%;
              font-size: 14px;
              min-height: 40px;
              line-height: 20px;
              padding: 4px;
            }
            &.dos {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 20px;
              width: 30%;
              @media (min-width: 280px) and (max-width: 1100px) {
                gap: 10px;
              }
              .button-plus {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                background: none;
                border: none;
                cursor: pointer;
                svg {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0;
                }
              }
            }
          }
        }

        .content-table-children {
          display: flex;
          flex-direction: row;

          @media (min-width: 1100px) {
            width: calc(100% / 6);
          }
          @media (min-width: 700px) and (max-width: 1100px) {
            width: calc(100% / 3);
          }
          @media (max-width: 700px) {
            width: calc(100% / 2);
          }

          &.cargas {
            @media (min-width: 1100px) {
              width: calc(100% / 5);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 3);
            }
            @media (max-width: 700px) {
              width: calc(100% / 2);
            }
          }

          &.columna {
            @media (min-width: 1100px) {
              width: calc(100% / 1);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 1);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          .title-table-children {
            display: flex;
            justify-content: center;
            vertical-align: middle;
            align-items: center;
            flex-basis: 40%;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            background: #fafafa;
            border: 1px solid #b0c1d5;
            @media (max-width: 700px) {
              font-size: 14px;
              line-height: 20px;
            }
          }

          .subtitle-table-children {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            div {
              flex-basis: 50%;
              display: flex;
              justify-content: center;
              vertical-align: middle;
              align-items: center;
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              padding: 10px 20px;
              background: #fafafa;
              border: 1px solid #b0c1d5;
              @media (max-width: 700px) {
                font-size: 14px;
                line-height: 20px;
                padding: 4px;
              }
              &:first-child {
                background: #D9E1EA;
                border: 1px solid #b0c1d5;
              }
            }
          }
        }

        .content-button-plus {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          .button-plus {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            background: none;
            border: none;

            svg {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0;
              font-size: 34px;
            }
          }
        }
      }
    }
  }
}

/** 
* TODO: Nuevas secciones de filtro para modal
*/

.container-filter-newdesign {
  display: flex;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 12;

  .modal-back-box {
    position: fixed;
    background: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 13;
  }

  .content-filter-newdesign {
    display: flex;
    flex-direction: column;
    position: absolute;
    max-width: 100%;
    background-color: #fafafa;
    padding: 48px 46px;
    margin-bottom: 40px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 1px;
    z-index: 15;

    @media (max-width: 700px) {
      padding: 48px 24px;
    }

    .container-title-filter {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;
      padding: 0px 0px 40px 0px;

      @media (max-width: 700px) {
        padding: 0px 0px 10px 0px;
      }
    }

    .section-filter-aplicate {
      display: flex;
      justify-content: space-between;
      align-items: end;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 40px;
      gap: 20px;

      @media (max-width: 700px) {
        margin-bottom: 0px;
      }

      .content-filter-aplicate {
        display: flex;
        justify-content: start;
        flex-flow: column wrap;
        gap: 25px;

        @media (max-width: 700px) {
          gap: 10px;
        }

        .title-filter {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #262626;
        }

        .content-button-filter-aplicate {
          display: flex;
          justify-content: start;
          gap: 10px;
          flex-wrap: wrap;

          .button-filter-aplicate {
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 4px 8px 4px 16px;
            height: 28px;
            background: #002d63;
            border: 1px solid #fafafa;
            gap: 10px;
            color: #fafafa;
            cursor: pointer;
            svg {
              display: flex;
              margin: 0;
              color: #fafafa;
              font-size: 16px;
            }
          }
        }
      }

      .content-button-filter {
        display: flex;
        justify-content: end;
        align-items: end;

        .button-clear-left {
          display: flex;
          align-items: center;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          padding: 4px 8px 4px 16px;
          height: 28px;
          background: #fafafa;
          border: 2px solid #005bc7;
          gap: 10px;
          color: #005bc7;
          border-radius: 8px;
          cursor: pointer;
          svg {
            display: flex;
            margin: 0;
            color: #fafafa;
            font-size: 16px;
          }

          &:hover {
            background: #005bc7;
            color: #fafafa;
            border: 2px solid #005bc7;
          }
        }
      }
    }

    .content-filter {
      display: flex;
      justify-content: start;
      align-items: center;
      vertical-align: middle;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;

      .section-filter {
        display: flex;
        width: calc(100% / 4 - 20px);
        min-height: 180px;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        flex-direction: column;
        border-left: 2px solid #dedede;
        border-right: 2px solid #dedede;
        padding: 0px 10px;
        gap: 10px;

        &.section-filterContrato {
          width: calc(100% / 2.8 - 20px) !important;
        }
        &.section-filterContratoOtros {
          width: calc(100% / 3.6 - 20px) !important;
        }

        @media (max-width: 700px) {
          width: 100%;
          border-left: none;
          border-right: none;
          border-bottom: 2px solid #dedede;
          min-height: 125px;
          &:last-child {
            border-bottom: none;
          }

          &.section-filterContrato {
            width: 100% !important;
          }
        }

        .title {
          display: flex;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
        }

        .content-description-filter {
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 10px;

          @media (max-width: 700px) {
            flex-direction: row;
            gap: 10px;
          }
          section {
            display: flex;
            justify-content: center;
            vertical-align: middle;
            align-items: center;
            flex-direction: row;
            gap: 10px;
            @media (max-width: 700px) {
              width: 100%;
              flex-direction: row;
              gap: 8px;
            }
            .orden {
              display: flex;
              align-items: center;
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 11.57px;
              gap: 10px;
            }
            svg {
              display: flex;
              margin: 0;
            }
          }
        }

        .content-input-filter {
          width: 100%;
          margin-bottom: 20px;
        }

        .section-button {
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 5px;
          flex-direction: row;
          flex-wrap: wrap;

          .button-filter {
            display: flex;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            align-items: center;
            border: 1px solid #01326a;
            text-transform: capitalize;
            background: #f5f5f5;
            border-radius: 0px;
            color: #002d63;
            padding: 4px 25px 4px 16px;

            @media (max-width: 1300px) {
              font-size: 11.57px;
              line-height: 15px;
              min-width: 160px !important;
              margin-bottom: 5px;
            }
            &:hover {
              background: #002d63;
              color: #f5f5f5;
            }
            &:active {
              background: #002d63;
              color: #f5f5f5;
            }
            &.active {
              background: #002d63;
              color: #f5f5f5;
            }
          }
        }
        .button-container {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 10px;
        }

        .button-pair {
          display: grid;
          grid-template-columns: repeat(
            2,
            1fr
          ); /* Distribuye en columnas de igual ancho */
          gap: 10px;

          @media (max-width: 1000px) {
            grid-template-columns: 1fr; /* Cambia a una columna en pantallas pequeñas */
          }
        }
      }
    }
  }
}

//////////// modal mio ////////////
//////////// modal mio ////////////
.iconCausal {
  align-items: center;
  align-content: center;
  justify-content: center;
  //display: flex ;
  box-shadow: 0px 4px 4px 0px rgba(4, 54, 154, 0.2);
  width: 33%;
}

.classSpanTooltips {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding-left: 12px;
}
.separate {
  margin-inline: 40px;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.modal-container-validatekam-Mo .p-tabview .p-tabview-nav {
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 6px;
  border-width: 0px;
}

.modal-container-validatekam-Mo
  .p-tabview
  .p-tabview-nav
  li
  .p-tabview-nav-link {
  justify-content: center;
  border-width: 0 0 0px 0;
}

.modal-container-validatekam-Mo
  .p-tabview
  .p-tabview-nav
  li.p-highlight
  .p-tabview-nav-link {
  border-width: 0 0 4px 0;
  border-color: #003d85;
  padding: 7% 0 !important;
  justify-content: center;
  display: flex;
}
.texButton {
  background: none;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 11.57px;
  font-family: Inter;
  line-height: 15px;
  color: #404040;
  gap: 100px;
}

.content-text {
  display: inline-flex;
  padding-left: 9px;
  padding-right: 2px;
  gap: 15px;
  width: 100%;
}

.content-text2 {
  float: left;
  padding: 10 5px;
}

.textError {
  color: #d32f2f;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: 25px;
  float: left;
  display: inline-flex;
}

.textErrorIcon {
  color: #d32f2f;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding-top: 23px;
}

.modal-container-validatekam-Mo {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #1e1e1ece;
  z-index: 13;
  overflow-y: scroll;

  .modal-clear-box {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 13;
  }

  &.edit {
    overflow-y: scroll;
    padding: 30px 0px;
  }

  &.filtro {
    overflow-y: none;
    position: fixed;
    padding: 30px 0px;
    background-color: transparent;
  }

  .modal-content-validatekam-Mo {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: #fafafa;
    padding: 48px 46px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 24px;
    margin: auto;
    gap: 16px;
    z-index: 14;

    &.filtro {
      background: #f5f5f5;
      padding: 48px 90px;
      border-radius: 1px;
      top: 40%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -0%);
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    }

    &.w-90 {
      width: 90%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 95%;
        padding: 32px 18px;
        gap: 10px;
      }
    }

    &.w-80 {
      width: 80%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 96%;
        padding: 32px 14px;
        gap: 10px;
      }
    }

    &.w-70 {
      width: 70%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 14px;
        gap: 10px;
      }
    }

    &.w-60 {
      width: 60%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 80%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    &.w-45 {
      width: 45%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 60%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    &.w-30 {
      width: 30%;

      @media (min-height: 491px) {
        width: 30%;
        padding: 20px 18px;
      }
      @media (max-width: 416px) {
        width: 30%;
        padding: 20px 18px;
      }
    }

    &.causal {
      width: 45%;

      @media (min-height: 820px) {
        width: 45%;
        padding: 20px 18px;
      }
      @media (max-width: 656px) {
        width: 30%;
        padding: 20px 18px;
      }
    }

    .modal-content-message {
      max-width: 100%;
      background-color: #fafafa;
      padding: 48px 46px;
      border: 1px solid #888;
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      border-radius: 24px;
      margin: auto;
    }

    .modal-close {
      display: flex;
      justify-content: end;
      color: #aaa;
      margin: -20px -20px -10px -10px;
      svg {
        display: flex;
        margin: 0;
        font-size: 30px;
        color: #48566b;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        margin: -10px 0px -5px -5px;
      }
    }

    .modal-close:hover,
    .modal-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    .modal-title-Mo {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;
      margin-bottom: 16px;

      &.start {
        text-align: start;
      }

      &.filter {
        padding: 0px 0px 40px 0px;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .modal-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;

      &.start {
        text-align: start;
      }

      &.filter {
        padding: 0px 0px 40px 0px;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .modal-subtitle-Mo {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    .modal-message-Mo {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }
    .modal-message2-Mo {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      padding-bottom: 1em;
      &.start {
        text-align: start;
      }
    }

    .separador-seccion {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      border-bottom: 1px solid #dedede;
    }

    .project-table-Mo {
      display: flex;
      border: 1px solid #d4d4d4;
      margin-bottom: 1em;
      margin-left: 50px;
      margin-right: 50px;
      &.sin {
        border: none;
        gap: 20px;
        margin-left: 30px;
        margin-right: 15px;
      }
      &.disabled {
        display: none;
        transition: all 0.3s ease;
      }

      .container-table-Mo {
        width: 100%;
        display: flex;
        vertical-align: middle;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px 0px;
        border: 1px solid #d4d4d4;

        &.sin {
          border: none;
          gap: 20px;
          align-items: none;
          display: contents;
        }

        .title {
          display: flex;
          vertical-align: middle;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          padding: 4px;

          @media (max-width: 700px) {
            font-size: 15px;
          }
        }

        .content-table-Mo {
          text-align: center;
          border-collapse: collapse;
          width: 100%;
          overflow-x: auto;
          thead {
            align-items: center;
            background: #d9e8fa;

            th {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              border-radius: 0px;
              color: #262626;
              height: 34px;
            }
          }

          tbody {
            justify-content: center;
            align-items: center;
            tr {
              background: #fafafa;
              &.selected {
                background: #e2ebf9;
              }
              &.validate {
                background: #ffe4d9;
              }
              &.contract {
                background: #e5f7e5;
              }
              &.checked {
                background: #e2ebf9;
              }
            }

            td {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 23px;
              border-radius: 0px;
              padding: 4px 8px;
              text-align: center;
              text-transform: capitalize;
              color: #262626;
              height: 23px;
              border-top: 2px solid #d4d4d4;

              @media (min-width: 700px) and (max-width: 1100px) {
                padding: 4px;
                font-size: 14px;
                line-height: 19px;
                height: 46px;
              }
              @media (max-width: 700px) {
                padding: 1px;
                font-size: 14px;
                line-height: 19px;
                height: 46px;
              }
              &.inactiva {
                color: #aeaeae;
                fill: #aeaeae;
              }

              &.icon-estado {
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
              &.menos {
                height: 23px;
                padding: 0px;
              }

              .content-icon-table {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;

                @media (min-width: 700px) and (max-width: 1100px) {
                  gap: 4px;
                }
                @media (max-width: 700px) {
                  gap: 3px;
                }

                button {
                  display: flex;
                  border: none;
                  background: none;
                  padding: 2px;
                  margin: 0;
                  cursor: pointer;
                  svg {
                    display: flex;
                    margin: 0;
                    font-size: 24px;

                    &.disabled {
                      background: none;
                      color: #aeaeae;
                      cursor: not-allowed;
                    }

                    &.restore {
                      background: none;
                      color: #0065dd;
                      &:active {
                        background-color: #0065dd;
                        color: #fafafa;
                        border-radius: 50%;
                        padding: 1px;
                      }
                    }

                    &.edit {
                      background: none;
                      color: #0065dd;
                      &:active {
                        background-color: #0065dd;
                        color: #fafafa;
                        border-radius: 50%;
                        padding: 2px;
                      }
                    }

                    &.block {
                      background: none;
                      color: #d32f2f;
                      &:active {
                        background-color: #d32f2f;
                        color: #fafafa;
                        border-radius: 50%;
                        padding: 1px;
                        fill: #fafafa;
                        svg {
                          color: #fafafa;
                        }
                      }
                    }

                    &.cancel {
                      background: none;
                      color: #d32f2f;
                      &:active {
                        background-color: #d32f2f;
                        color: #fafafa;
                        border-radius: 50%;
                        padding: 1px;
                      }
                    }

                    &.active-restore {
                      background: none;
                      color: #0065dd;
                      &:active {
                        background-color: #0065dd;
                        color: #fafafa;
                        border-radius: 50%;
                        padding: 1px;
                      }
                    }

                    &.active-registro {
                      background: none;
                      fill: #06a502;
                      &:active {
                        background-color: #06a502;
                        border-radius: 50%;
                        padding: 1.5px;
                        fill: #fafafa;
                        color: #fafafa;
                        svg {
                          color: #fafafa;
                        }

                        &.active-block {
                          background: none;
                          color: #d32f2f;
                          &:active {
                            background-color: #d32f2f;
                            border-radius: 50%;
                            padding: 1.5px;
                            color: #fafafa;
                            svg {
                              color: #fafafa;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .content-table-row-data {
          display: flex;
          width: 100%;
          flex-direction: row;
          @media (min-width: 280px) and (max-width: 1100px) {
            flex-direction: column;
          }
        }

        .content-table-row {
          display: flex;
          vertical-align: center;
          align-items: center;
          width: calc(100% / 1);
          &.dos {
            @media (min-width: 280px) and (max-width: 1100px) {
              width: calc(100% / 1);
              flex-direction: row;
            }
          }

          &.documento {
            width: calc(100% / 2);
            @media (min-width: 280px) and (max-width: 1100px) {
              width: calc(100% / 1);
              flex-direction: row;
            }
          }

          .title-table-row {
            display: flex;
            justify-content: start;
            align-items: center;
            max-height: 45px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            padding: 10px 16px;
            background: #d4d4d4;
            border: 1px solid #b0c1d5;
            width: 50%;
            @media (max-width: 700px) {
              width: 100%;
              font-size: 14px;
              min-height: 40px;
              line-height: 20px;
              padding: 4px;
            }

            &.dos {
              width: 70%;
              @media (max-width: 700px) {
                width: 100%;
              }
            }
          }

          .subtitle-table-row {
            display: flex;
            justify-content: start;
            align-items: center;
            max-height: 45px;
            min-height: 45px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            padding: 10px 20px;
            border: 1px solid #b0c1d5;
            width: 50%;
            @media (max-width: 700px) {
              width: 100%;
              font-size: 14px;
              min-height: 40px;
              line-height: 20px;
              padding: 4px;
            }
            &.dos {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 20px;
              width: 30%;
              @media (min-width: 280px) and (max-width: 1100px) {
                gap: 10px;
              }
              .button-plus {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                background: none;
                border: none;
                cursor: pointer;
                svg {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

////////////

/** 
* TODO: Modal botones varios; disabled, cancel, confirm, delete, etc...
*/

.modal-button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;

  &.center {
    justify-content: space-around;
  }

  &.separados {
    justify-content: space-between;
  }

  &.contratacion {
    gap: 30px;
    justify-content: center;
  }

  &.end {
    justify-content: end;
  }

  &.filter {
    padding-top: 50px;
    justify-content: end;
    @media (max-width: 700px) {
      padding-top: 1px;
      justify-content: center;
    }
  }
  &.modal-button-content-in-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    gap: 32px;
    &.center {
      justify-content: center;
    }

    &.end {
      justify-content: end;
    }

    &.filter {
      padding-top: 50px;
      justify-content: end;
      @media (max-width: 700px) {
        padding-top: 1px;
        justify-content: center;
      }
    }
  }

  .modal-buttonModal {
    display: flex;
    min-width: 160px;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    gap: 10px;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    cursor: pointer;

    @media (min-width: 350px) and (max-width: 1150px) {
      min-width: 96px;
    }
    @media (max-width: 344px) {
      min-width: 96px;
    }

    &.cancel {
      color: #005bc7;
      border: 2px solid #005bc7;
      background: #fafafa;

      &:hover {
        border: 2px solid #005bc7;
        background: #fafafa;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
    }

    &.confirm {
      color: #fafafa;
      border: 2px solid #06a502;
      background: #06a502;

      &:hover {
        background: #fafafa;
        color: #06a502;
        border: 2px solid #06a502;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
    }
  }

  .tooltip-custom {
    background-color: #fafafa;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #fafafa;
  }

  .modal-button {
    display: flex;
    min-width: 240px;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    gap: 10px;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin: 16px 0px;
    cursor: pointer;

    @media (min-width: 700px) and (max-width: 1100px) {
      min-width: 190px;
    }
    @media (max-width: 700px) {
      min-width: 140px;
    }

    &.cancel {
      color: #005bc7;
      border: 2px solid #005bc7;
      background: #fafafa;

      &.contratacion {
        min-width: 250px;
      }

      &:hover {
        border: 2px solid #005bc7;
        background: #fafafa;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: #fafafa;
        cursor: not-allowed;
      }
    }

    &.confirm {
      color: #fafafa;
      border: 2px solid #06a502;
      background: #06a502;

      &.contratacion {
        min-width: 250px;
      }

      &:hover {
        background: #fafafa;
        color: #06a502;
        border: 2px solid #06a502;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: #fafafa;
        cursor: not-allowed;
      }
    }

    &.option {
      color: #fafafa;
      border: 2px solid #0065dd;
      background: #0065dd;

      &.contratacion {
        min-width: 250px;
      }

      &:hover {
        background: #fafafa;
        color: #0065dd;
        border: 2px solid #0065dd;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: #fafafa;
        cursor: not-allowed;
      }
    }

    &.naranja {
      color: #fafafa;
      border: 2px solid #ff4900;
      background: #ff4900;

      &.contratacion {
        min-width: 250px;
      }

      &:hover {
        background: #fafafa;
        color: #ff4900;
        border: 2px solid #ff4900;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }

      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: #fafafa;
        cursor: not-allowed;
      }
    }

    &.delete {
      color: #fafafa;
      border: 2px solid #d32f2f;
      background: #d32f2f;

      &.contratacion {
        min-width: 250px;
      }

      &:hover {
        background: #fafafa;
        color: #d32f2f;
        border: 2px solid #d32f2f;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: #fafafa;
        cursor: not-allowed;
      }
    }

    &.disabled {
      color: #fafafa;
      border: 2px solid #aeaeae;
      background: #aeaeae;
      cursor: not-allowed;

      &:hover {
        background: #aeaeae;
        color: #fafafa;
      }
    }
  }
}

@media (max-width: 576px) {
  .modalDisplay {
    display: block;
  }
  .separate {
    margin-inline: 0;
  }
  .modal-container-validatekam-Mo .separate > button > svg {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 767px) {
  .displayBlockResp {
    display: block !important;
  }
  .responsiveDivDetener {
    float: left;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
}

.content-table-rowFlex {
  display: flex;
}
.gapAreaContr {
  gap: 8px 0px !important;
}
.gapContentRow {
  gap: 8px 10px;
}
.input-initial {
  &.initial {
    font-size: 15px;
    padding: 10px 8px 10px 14px;
  }
}

.title-table-rowResp {
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: 45px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 16px;
  background: #d4d4d4;
  border: 1px solid #b0c1d5;
}

.bg-headerBlue {
  background: #d9e1ea;
  font-weight: 600;
  font-size: 15px;
  font-family: Inter;
  line-height: 18px;
  min-height: 56px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bg-headerGrey {
  background: #e5e5e5;
  font-weight: 600;
  font-size: 15px;
  font-family: Inter;
  line-height: 18px;
  min-height: 56px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.textNormal {
  font-weight: 400;
  font-size: 15px;
  font-family: Inter;
  line-height: 20px;
  text-transform: capitalize;
}
.text-headerWhite {
  font-weight: 500;
  font-size: 15px;
  font-family: Inter;
  line-height: 23px;
}

.textLucro {
  font-size: 13.17px;
  font-family: Inter;
  font-weight: 500;
  line-height: 16px;
  padding-bottom: 4px;
}

.borderColorGrey {
  border-color: #d4d4d4;
}
.border-Seg {
  border-bottom-style: dotted;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.border-Seg1 {
  border-bottom-style: dotted;
}

.modalZ {
  z-index: 15;
}

.classSvg {
  width: 27px !important;
  height: 27px !important;
}

.w-100 {
  width: 100%;
}

/* 90% de ancho */
.w-90 {
  width: 90%;
}

/* 80% de ancho */
.w-80 {
  width: 80%;
}

/* 70% de ancho */
.w-70 {
  width: 70%;
}

/* 60% de ancho */
.w-60 {
  width: 60%;
}

/* 50% de ancho */
.w-50 {
  width: 50%;
}

/* 40% de ancho */
.w-40 {
  width: 40%;
}

/* 30% de ancho */
.w-30 {
  width: 30%;
}

/* 25% de ancho */
.w-25 {
  width: 25%;
}

/* 20% de ancho */
.w-20 {
  width: 20%;
}

/* 10% de ancho */
.w-10 {
  width: 10%;
}

.preocupacional{
  display: flex;

  .content1{
    display: flex;
  }
}


