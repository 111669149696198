.container-assist {
  display: flex;
  border-radius: 0px !important;
  text-align: center;
  justify-content: center;
  background: #fafafa !important;
  margin: 0px 0px 100px 0px;

  .content {
    width: 100%;
    align-items: center;
    margin-bottom: 60px;

    .content-body {
      padding: 10px;
      width: 80%;
      padding: 20px;
      display: inline-block;
      text-align: center;
      align-content: center;
      justify-content: center;

      .nav-text {
        font-style: normal;
        width: 80%;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        color: #48566b;
        justify-content: start;
      }

      .title-assists {
        color: #005bc7;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 37px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        justify-content: center;
        margin: 40px 0px 20px 0px;
      }

      .subtitle-assists {
        display: flex;
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #005bc7;
      }

      .text-descripcion {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #404040;
      }

      .count-person {
        font-style: normal;
        display: flex;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #6f6f6f;
        margin-bottom: 20px;
      }

      .content-filter {
        display: flex;
        gap: 50px;
        width: 100%;

        .filtro-select {
          width: 100%;
        }
      }

      .content-list {
        display: flex;
        justify-content: start;
        width: 100%;
        align-items: center;
        vertical-align: center;
        margin: 20px 0px;

        .title-count {
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 27px;
          color: #005bc7;
          margin-right: 20px;
        }

        .sub-title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #404040;
        }
      }

      .message-check {
        width: 100%;
        gap: 16px;
        background: #4bb848;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 6px 24px;
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #fafafa;

        .icon-assists {
          font-size: 35px;
          display: flex;
        }
      }

      .message-alert {
        width: 100%;
        gap: 16px;
        background: #fe0000;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 6px 24px;
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #fafafa;

        .icon-assists {
          font-size: 35px;
          display: flex;
        }
      }

      .content-section {
        display: flex;
        align-content: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        cursor: pointer;

        .section-active {
          background-color: #0065dd;
          padding: 20px 0px;
          width: 50%;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          align-items: center;
          text-align: center;
          position: relative;
          float: left;
          border-radius: 10px 10px 0px 0px;
          p {
            color: #fafafa;
          }
        }

        .section-active:before,
        .section-active:after {
          content: " ";
          position: absolute;
          display: block;
          width: 0;
          height: 0;
        }

        .arrowBottom:before {
          border-top: 16px solid #0065dd;
          bottom: -12px;
          z-index: 13;
        }

        .section-inactive {
          background-color: #fafafa;
          border-radius: 10px 10px 0px 0px;
          padding: 20px 0px;
          width: 50%;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          align-items: center;
          text-align: center;
          p {
            color: #0065dd;
          }
        }
      }

      .content-section-blue {
        width: 100%;
        height: 15px;
        margin-bottom: 20px;
        background: #0065dd;
      }

      .container-text {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 2% 0% 2% 0%;

        .text-area {
          max-width: 100%;
          width: 100%;
          padding: 12px;
          display: flex;
          justify-content: center;
        }
      }
    }

    .date-assist {
      display: flex;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #48566b;
      margin: 30px 0px 0px 10px;
      justify-content: start;
    }

    .line {
      border-bottom: 2px solid rgba(157, 157, 157, 0.481);
      margin-bottom: 20px;
    }

    .target {
      display: flex;
      width: 100%;
      justify-content: center;
      margin: 20px 0px;
      box-shadow: 0px 2px 2px rgba(4, 54, 154, 0.17);
      border-radius: 8px;

      .content {
        width: 100%;
        background: #f5f5f5;
        border-radius: 8px;
        padding: 14px;
        margin: 0px;

        .list {
          display: flex;
          justify-content: left;
          text-align: start;
          margin-left: 5px;
          margin-bottom: 6px;
          position: relative;

          .icon-down {
            font-size: 25px;
            position: absolute;
            top: auto;
            right: 0;
            cursor: pointer;
          }

          .cargo-mod {
            color: #0065dd;
          }

          .descrip-black {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #48566b;
            margin-right: 8px;
          }

          .descrip-grey {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #262626;
            margin-right: 8px;
          }
        }

        .list-icon {
          display: flex;
          justify-content: end;

          .icon-rigth {
            display: flex;
            justify-content: end;
            font-size: 2rem;
            cursor: pointer;
            color: #48566b;
            margin-right: 20px;
          }

          .icon-check {
            display: flex;
            justify-content: end;
            font-size: 2rem;
            cursor: pointer;
            margin-right: 20px;
            color: #4bb848;
          }

          .icon-message {
            display: flex;
            justify-content: end;
            font-size: 2rem;
            cursor: pointer;
            margin-right: 20px;
            color: #ffb400;
          }
        }
      }

      .content:hover {
        background: #efefef;
        border-radius: 10px;
      }

      .border-red {
        border-left: 12px solid #d32f2f;
      }

      .border-orange {
        border-left: 12px solid #ff4900;
      }

      .border-green {
        border-left: 12px solid #06a502;
      }

      .border-pink {
        border-left: 12px solid #ff0081;
      }
    }
  }

  .container-table {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .content-table {
      width: 100%;
      border-spacing: 0.5px;
      border-radius: 0px;
      border-collapse: collapse;

      .tr-list {
        border-bottom: 1px dashed #48566b;
        font-style: normal;
        color: #a3a3a3;
        font-weight: 500;
        font-size: 19px;
        line-height: 22px;
        border-radius: 0px;
        padding: 8px 24px;

        .tr-list:first-child {
          border-top: 0px dashed #404040;
        }

        .td-list {
          color: #a3a3a3;
          padding: 10px;
          text-align: justify;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }

        .active {
          color: #404040;
        }
      }

      .tr-list-title {
        border-bottom: 1px solid #404040;
        border-top: 1px solid #404040;
        font-style: normal;
        color: #404040;
        font-weight: 500;
        font-size: 19px;
        line-height: 22px;
        border-radius: 0px;
        padding: 10px;
        background: #f5f5f5;
        border-width: 1px 0px;
        border-style: solid;
        border-color: #61738e;

        .td-list-title {
          padding: 10px;
          text-align: justify;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }
      }

      ///////////////////// SWITCH /////////////////////
      ///////////////////// SWITCH /////////////////////

      // .switch {
      //   position: relative;
      //   display: inline-block;
      //   width: 60px;
      //   height: 34px;
      // }

      // .switch input {
      //   opacity: 0;
      //   width: 0;
      //   height: 0;
      // }

      // .slider {
      //   position: absolute;
      //   cursor: pointer;
      //   top: 0;
      //   left: 0;
      //   right: 0;
      //   bottom: 0;
      //   background-color: #61738e;
      //   -webkit-transition: 0.4s;
      //   transition: 0.4s;
      // }

      // .slider:before {
      //   position: absolute;
      //   content: "";
      //   height: 26px;
      //   width: 26px;
      //   left: 4px;
      //   bottom: 4px;
      //   background-color: #fafafa;
      //   -webkit-transition: 0.4s;
      //   transition: 0.4s;
      // }

      // input:checked + .slider {
      //   background-color: #48566b;
      // }

      // input:checked + .slider:before {
      //   -webkit-transform: translateX(26px);
      //   -ms-transform: translateX(26px);
      //   transform: translateX(26px);
      // }

      // /* Rounded sliders */
      // .slider.round {
      //   border-radius: 34px;
      // }

      // .slider.round:before {
      //   border-radius: 50%;
      // }

      ///////////////////// SWITCH /////////////////////
      ///////////////////// SWITCH /////////////////////
      
      ///////////////////// SWITCH FORMA FINAL (TRANSFORMACION) /////////////////////
      ///////////////////// SWITCH FORMA FINAL (TRANSFORMACION) /////////////////////

      .list-presente {
        color: #242424;
        font-size: 36px;
      }

      .list-ausente {
        color: #AEAEAE;
        font-size: 36px;
      }

      ///////////////////// SWITCH FORMA FINAL (TRANSFORMACION) /////////////////////
      ///////////////////// SWITCH FORMA FINAL (TRANSFORMACION) /////////////////////
    }
  }

  .container-button {
    width: 100%;
    display: flex;
    margin: 30px 0px;
    justify-content: space-between;

    .button-left {
      display: flex;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      width: 45%;
      background: #005bc7;
      color: #fafafa;
      border: 2px solid #005bc7;

      &:hover {
        color: #005bc7; 
        background: #fafafa;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        border-radius: 8px;
        border: 2px solid #005bc7;
      }
    }

    .button-right {
      display: flex;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      width: 45%;
      background: #06a502;
      border: 2px solid #06a502;
      border-radius: 8px;
      color: #fafafa;
      &:hover {
        background: #fafafa;
        color: #06a502;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        border-radius: 8px;
      }
    }

    .button-center {
      display: flex;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      width: 45%;
      background: #06a502;
      border: 2px solid #06a502;
      border-radius: 8px;
      color: #fafafa;
      &:hover {
        background: #fafafa;
        color: #06a502;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        border-radius: 8px;
      }
    }
  }
}

// ----------------------------------------------------- //
// ---------------------- 960px ------------------------ //
// ----------------------------------------------------- //
@media (max-width: 960px) {
  .container-assist {
    display: flex;
    border-radius: 0px !important;
    text-align: center;
    justify-content: center;
    background: #fafafa !important;
    margin: 0px 0px 100px 0px;

    .content {
      width: 100%;
      align-items: center;
      margin-bottom: 60px;

      .content-body {
        padding: 10px;
        width: 90%;
        padding: 20px 0px;
        display: inline-block;
        text-align: center;
        align-content: center;
        justify-content: center;

        .nav-text {
          font-style: normal;
          width: 90%;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          display: flex;
          color: #48566b;
          justify-content: start;
        }

        .title-assists {
          color: #005bc7;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 37px;
          display: flex;
          align-items: center;
          text-align: center;
          text-transform: uppercase;
          justify-content: center;
          margin: 40px 0px 20px 0px;
        }

        .subtitle-assists {
          display: flex;
          width: 100%;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
          color: #005bc7;
        }

        .text-descripcion {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 25px;
          display: flex;
          align-items: center;
          color: #404040;
        }

        .count-person {
          font-style: normal;
          display: flex;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #6f6f6f;
          margin-bottom: 20px;
        }

        .content-filter {
          display: flex;
          gap: 40px;
          width: 100%;
        }

        .content-list {
          display: flex;
          justify-content: start;
          width: 100%;
          align-items: center;
          vertical-align: center;
          margin: 20px 0px;

          .title-count {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            color: #005bc7;
            margin-right: 20px;
          }

          .sub-title {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #404040;
          }
        }

        .message-check {
          width: 100%;
          gap: 16px;
          background: #4bb848;
          border-radius: 8px;
          display: flex;
          align-items: center;
          padding: 6px 24px;
          font-style: italic;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #fafafa;

          .icon-assists {
            font-size: 35px;
            display: flex;
          }
        }

        .message-alert {
          width: 100%;
          gap: 16px;
          background: #fe0000;
          border-radius: 8px;
          display: flex;
          align-items: center;
          padding: 6px 24px;
          font-style: italic;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #fafafa;

          .icon-assists {
            font-size: 35px;
            display: flex;
          }
        }

        .content-section {
          display: flex;
          align-content: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          cursor: pointer;

          .section-active {
            background-color: #0065dd;
            padding: 10px;
            width: 50%;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            align-items: center;
            text-align: center;
            position: relative;
            float: left;
            border-radius: 10px 10px 0px 0px;
            p {
              color: #fafafa;
            }
          }

          .section-active:before,
          .section-active:after {
            content: " ";
            position: absolute;
            display: block;
            width: 0;
            height: 0;
          }

          .section-inactive {
            background-color: #fafafa;
            padding: 10px;
            width: 50%;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            align-items: center;
            text-align: center;
            p {
              color: #0065dd;
            }
          }
        }

        .content-section-blue {
          width: 100%;
          height: 10px;
          margin-bottom: 20px;
          background: #0065dd;
        }

        .container-text {
          max-width: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 2% 0% 2% 0%;

          .text-area {
            max-width: 100%;
            padding: 12px;
            display: flex;
            justify-content: center;
          }
        }
      }

      .date-assist {
        display: flex;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        color: #48566b;
        justify-content: start;
      }

      .line {
        border-bottom: 2px solid rgba(157, 157, 157, 0.481);
        margin-bottom: 20px;
      }

      .target {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 20px 0px;
        box-shadow: 0px 2px 2px rgba(4, 54, 154, 0.17);
        border-radius: 8px;

        .content {
          width: 100%;
          background: #f5f5f5;
          border-radius: 8px;
          padding: 14px;
          margin: 0px;

          .list {
            display: flex;
            justify-content: left;
            text-align: start;
            margin-left: 5px;
            margin-bottom: 6px;
            position: relative;

            .icon-down {
              font-size: 25px;
              position: absolute;
              top: auto;
              right: 0;
              cursor: pointer;
            }

            .cargo-mod {
              color: #0065dd;
            }

            .descrip-black {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              color: #48566b;
              margin-right: 5px;
            }

            .descrip-grey {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #262626;
              margin-right: 5px;
            }
          }

          .list-icon {
            display: flex;
            justify-content: end;

            .icon-rigth {
              display: flex;
              justify-content: end;
              font-size: 2rem;
              cursor: pointer;
              color: #48566b;
              margin-right: 20px;
            }

            .icon-check {
              display: flex;
              justify-content: end;
              font-size: 2rem;
              cursor: pointer;
              margin-right: 20px;
              color: #4bb848;
            }

            .icon-message {
              display: flex;
              justify-content: end;
              font-size: 2rem;
              cursor: pointer;
              margin-right: 20px;
              color: #ffb400;
            }
          }
        }
      }
    }

    .container-button {
      width: 100%;
      display: flex;
      margin: 30px 0px;
      justify-content: space-between;

      .button-left {
        display: flex;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        width: 45%;
        background: #005bc7;
        color: #fafafa;
      }

      .button-right {
        display: flex;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        width: 45%;
        background: #06a502;
        color: #fafafa;
      }
      .button-right:hover {
        background: #037701;
      }
    }
  }
}

// ----------------------------------------------------- //
// ---------------------- 480px ------------------------ //
// ----------------------------------------------------- //
@media (max-width: 480px) {
  .container-assist {
    display: flex;
    border-radius: 0px !important;

    .content {
      width: 95%;
      align-items: center;
      margin-bottom: 60px;

      .content-body {
        width: 100%;
        padding: 15px 0px;
        display: inline-block;
        text-align: center;
        align-content: center;
        justify-content: center;

        .nav-text {
          font-style: normal;
          width: 100%;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          color: #48566b;
          justify-content: start;
        }

        .title-assists {
          color: #005bc7;
          font-style: normal;
          font-weight: 900;
          font-size: 22px;
          line-height: 27px;
          display: flex;
          align-items: center;
          text-align: center;
          text-transform: uppercase;
          justify-content: center;
          margin: 40px 0px 20px 0px;
        }

        .subtitle-assists {
          display: flex;
          width: 100%;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 27px;
          color: #005bc7;
        }

        .text-descripcion {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          display: flex;
          text-align: justify;
          color: #404040;
          margin-bottom: 2rem;
        }

        .count-person {
          font-style: normal;
          display: flex;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #6f6f6f;
          margin-bottom: 10px;
        }

        .content-filter {
          display: flex;
          gap: 30px;
          width: 100%;
        }

        .content-list {
          display: flex;
          justify-content: start;

          .title-count {
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
          }

          .sub-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }

        .message-check {
          width: 100%;
          background: #4bb848;
          border-radius: 8px;
          display: flex;
          align-items: center;
          text-align: left;
          padding: 6px 24px;
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #fafafa;

          .icon-assists {
            font-size: 30px;
            display: flex;
          }
        }

        .message-alert {
          width: 100%;
          gap: 16px;
          background: #fe0000;
          border-radius: 8px;
          display: flex;
          align-items: center;
          text-align: left;
          padding: 6px 24px;
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #fafafa;

          .icon-assists {
            font-size: 30px;
            display: flex;
          }
        }

        .content-section {
          display: flex;
          align-content: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          cursor: pointer;

          .section-active {
            background-color: #0065dd;
            padding: 10px;
            width: 50%;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            align-items: center;
            text-align: center;
            position: relative;
            float: left;

            p {
              color: #fafafa;
            }
          }

          .section-inactive {
            background-color: #fafafa;
            padding: 10px;
            width: 50%;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            align-items: center;
            text-align: center;
            p {
              color: #0065dd;
            }
          }
        }

        .container-text {
          max-width: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 2% 0% 2% 0%;

          .text-area {
            max-width: 100%;
            padding: 12px;
            display: flex;
            justify-content: center;
          }
        }
      }

      .date-assist {
        display: flex;
        font-style: normal;
        justify-content: start;
      }

      .line {
        border-bottom: 0px solid rgba(157, 157, 157, 0.481);
        margin-bottom: 0px;
      }

      .target {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 20px 0px;
        box-shadow: 0px 2px 2px rgba(4, 54, 154, 0.17);
        border-radius: 8px;

        .content {
          width: 100%;
          background: #f5f5f5;
          border-radius: 8px;
          padding: 14px;
          margin: 0px;

          .list {
            display: flex;
            justify-content: left;
            text-align: start;
            margin-left: 5px;
            margin-bottom: 6px;
            position: relative;

            .icon-down {
              font-size: 25px;
              position: absolute;
              top: auto;
              right: 0;
              cursor: pointer;
            }

            .cargo-mod {
              color: #0065dd;
            }

            .descrip-black {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              color: #48566b;
              margin-right: 5px;
            }

            .descrip-grey {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #262626;
              margin-right: 5px;
            }
          }

          .list-icon {
            display: flex;
            justify-content: end;

            .icon-rigth {
              display: flex;
              justify-content: end;
              font-size: 2rem;
              cursor: pointer;
              color: #48566b;
              margin-right: 20px;
            }

            .icon-check {
              display: flex;
              justify-content: end;
              font-size: 2rem;
              cursor: pointer;
              margin-right: 20px;
              color: #4bb848;
            }

            .icon-message {
              display: flex;
              justify-content: end;
              font-size: 2rem;
              cursor: pointer;
              margin-right: 20px;
              color: #ffb400;
            }
          }
        }
      }
    }

    .container-table {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 50px;

      .content-table {
        width: 100%;
        border-spacing: 0.5px;
        border-radius: 0px;
        border-collapse: collapse;

        .tr-list {
          border-bottom: 1px dashed #48566b;
          font-style: normal;
          color: #a3a3a3;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          border-radius: 0px;
          padding: 8px 10px;

          .tr-list:first-child {
            border-top: 0px dashed #404040;
          }

          .td-list {
            color: #a3a3a3;
            padding: 5px;
            text-align: justify;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
          }

          .active {
            color: #404040;
          }
        }

        .tr-list-title {
          border-bottom: 1px solid #404040;
          border-top: 1px solid #404040;
          font-style: normal;
          color: #404040;
          font-weight: 500;
          font-size: 19px;
          line-height: 22px;
          border-radius: 0px;
          padding: 10px;
          background: #f5f5f5;
          border-width: 1px 0px;
          border-style: solid;
          border-color: #61738e;

          .td-list-title {
            padding: 10px;
            text-align: justify;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }

    .container-button {
      width: 100%;
      display: flex;
      margin: 30px 0px;
      justify-content: space-between;

      .button-left {
        display: flex;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        width: 45%;
        background: #005bc7;
        color: #fafafa;
      }

      .button-right {
        display: flex;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        width: 45%;
        background: #06a502;
        color: #fafafa;
      }
      .button-right:hover {
        background: #037701;
      }
    }
  }
}
