.seccion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .span-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #3B6597;
    padding-left: 20px;
    padding-right: 28px;
  }
  svg {
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    font-size: 44px;
    color: #003d85;
    transition: all 0.3s ease;
    &.disabled {
      transform: rotate(180deg);
      transition: all 0.3s ease;
    }
  }
}

.modal-container-psicolaboral {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #1e1e1ece;
  z-index: 13;
  overflow-y: scroll;

  .modal-clear-box {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 13;
  }

  &.edit {
    overflow-y: scroll;
    padding: 30px 0px;
  }

  &.filtro {
    overflow-y: none;
    position: fixed;
    padding: 30px 0px;
    background-color: transparent;
  }

  .modal-content-psicolaboral {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: #fafafa;
    padding: 48px 46px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 24px;
    margin: auto;
    gap: 16px;
    z-index: 14;

    &.filtro {
      background: #f5f5f5;
      padding: 48px 90px;
      border-radius: 1px;
      top: 40%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -0%);
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    }

    &.w-90 {
      width: 90%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 95%;
        padding: 32px 18px;
        gap: 10px;
      }
    }

    &.w-80 {
      width: 80%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 96%;
        padding: 32px 14px;
        gap: 10px;
      }
    }

    &.w-70 {
      width: 70%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 14px;
        gap: 10px;
      }
    }

    &.w-60 {
      width: 60%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 80%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    &.w-45 {
      width: 45%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 60%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    &.w-30 {
      width: 30%;

      @media (min-height: 491px) {
        width: 30%;
        padding: 20px 18px;
      }
      @media (max-width: 416px) {
        width: 30%;
        padding: 20px 18px;
      }
    }

    .secciones-modal {
      cursor: pointer;
    }

    .modal-content-message {
      max-width: 100%;
      background-color: #fafafa;
      padding: 48px 46px;
      border: 1px solid #888;
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      border-radius: 24px;
      margin: auto;
    }

    .modal-close {
      display: flex;
      justify-content: end;
      color: #aaa;
      margin: -14px 6px -7px -10px;
      svg {
        display: flex;
        margin: 0;
        font-size: 30px;
        color: #48566b;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        margin: -10px 0px -5px -5px;
      }
    }

    .modal-close:hover,
    .modal-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    .modal-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;
      padding: 1px;

      &.start {
        text-align: start;
      }

      &.filter {
        padding: 0px 0px 40px 0px;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .modal-subtitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    .modal-message {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    .modal-message-restaurar {
      display: flex;
      flex-direction: column;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    .modal-message-bottom {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    .separador-seccion {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      border-bottom: 1px solid #dedede;
    }

    .area-table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: -12px 0px;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #404040;
      }
      svg {
        display: flex;
        margin: 0;
        justify-content: center;
        align-items: center;
        font-size: 34px;
        color: #404040;
        transition: all 0.3s ease;
        &.disabled {
          transform: rotate(180deg);
          transition: all 0.3s ease;
        }
      }
    }

    .project-table {
      display: flex;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

      &.experience {
        flex-direction: row;
        @media  (max-width: 770px){
   
          flex-direction: column;
           }
      }
      &.disabled {
        display: none;
        transition: all 0.3s ease;
      }

      .container-table {
        width: 100%;
        display: flex;
        vertical-align: middle;
        align-items: center;
        flex-wrap: wrap;
        gap: 0px 0px;

        @media (max-width: 700px) {
          gap: 6px 0px;
        }

        &.container-table2 {
          width: 100%;
          display: flex;
          vertical-align: middle;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px 30px;

          @media (max-width: 700px) {
            gap: 6px 0px;
          }
        }

        .title {
          display: flex;
          vertical-align: middle;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          padding: 4px;

          @media (max-width: 700px) {
            font-size: 15px;
          }
        }

        .content-table {
          display: flex;
          flex-direction: column;
          border: 1px solid #b0c1d5;
          @media (min-width: 1100px) {
            width: calc(100% / 4);
          }
          @media (min-width: 700px) and (max-width: 1100px) {
            width: calc(100% / 2);
          }
          @media (max-width: 700px) {
            width: calc(100% / 1);
          }

          &.uno {
            @media (min-width: 1100px) {
              width: calc(100% / 1);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 1);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          &.dos {
            @media (min-width: 1100px) {
              width: calc(100% / 2);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 2);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          &.tres {
            @media (min-width: 1100px) {
              width: calc(100% / 3);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 2);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          &.cuatro {
            @media (min-width: 1100px) {
              width: 22%;
              &.ending {
                width: 34%;
              }
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 2);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          &.cinco {
            @media (min-width: 1100px) {
              width: calc(100% / 5);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 2);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          .subtitle-content-age {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
          }

          .title-table {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 45px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            padding: 10px 0px;
            background: #D9E1EA;
            border-bottom: 1px solid #b0c1d5;
            @media (max-width: 700px) {
              font-size: 14px;
              min-height: 40px;
              line-height: 20px;
              padding: 4px;
            }
            &.start {
              justify-content: flex-start;
            }
            &.end {
              justify-content: flex-end;
            }
            &.border {
              border: 1px solid #b0c1d5;
              width: 40px;
              height: 36px;
            }
          }

          .subtitle-table {
            display: flex;
            justify-content: start;
            align-items: start;
            min-height: 45px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            background: #fdfdfd;
            padding-left: 5px;
            @media (max-width: 700px) {
              font-size: 14px;
              min-height: 40px;
              line-height: 20px;
              padding: 4px;
            }
            &.cinco {
              background: #d9e1ea;
              border-left: 1px solid #b0c1d5;
              width: 25%;
              gap: 1px;
              &:first-child {
                border-left: none;
              }
              &:nth-child(2) {
                background: #d4d4d4;
              }
              &:nth-child(4) {
                background: #d4d4d4;
              }
            }
          }
        }

        .content-table-row-data {
          display: flex;
          width: 100%;
          flex-direction: row;
          @media (min-width: 280px) and (max-width: 1100px) {
            flex-direction: column;
          }
        }

        .iconSquare {
          padding-right: 30px;
          align-content: center;
          align-items: center;
          @media (min-width: 280px) and (max-width: 1100px) {
            width: calc(100%);
            justify-content: center;
            display: flex;
            padding-top: 10px;
          }
        }

        .content-table-row {
          display: flex;
          vertical-align: center;
          // align-items: center;
          width: calc(100% / 1);
          &.dos {
            width: calc(100% / 1);
            flex-direction: row;
          }
          &.tres {
            width: 31%;
            // gap: 45px;
            flex-basis: content;
            @media (min-width: 280px) and (max-width: 1100px) {
              width: calc(100% / 1);
              flex-direction: row;
            }
          }
          &.cuatro {
            width: 48%;
            gap: 45px;
            @media (min-width: 280px) and (max-width: 1100px) {
              width: calc(100% / 1);
              flex-direction: row;
            }
          }
          &.documento {
            width: calc(100% / 2);
            @media (min-width: 280px) and (max-width: 1100px) {
              width: calc(100% / 1);
              flex-direction: row;
            }
          }
          /* &.true {
                @media (max-width: 700px) {
                  display: block;
                }
            }*/

          .title-table-row {
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            min-height: 45px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            padding: 10px 16px;
            background: #D9E1EA;
            border: 1px solid #B0C1D5;
            /*width: 50%;*/
            @media (max-width: 700px) {
              /*width: 100%;*/
              font-size: 14px;
              min-height: 40px;
              line-height: 20px;
              padding: 4px;
              /*&.true {
                    width: 100%; 
                }*/
            }

            &.dos {
              width: 70%;
              @media (max-width: 700px) {
                width: 100%;
              }
            }
            &.tres {
              width: 56%;
              @media (min-width: 500px) {
                width: 100%;
              }
            }
          }

          .subtitle-table-row {
            display: flex;
            justify-content: start;
            align-items: center;
            min-height: 45px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            padding: 10px 20px;
            border: 1px solid #b0c1d5;
            width: 50%;
            @media (max-width: 700px) {
              width: 100%;
              font-size: 14px;
              min-height: 40px;
              line-height: 20px;
              padding: 4px;
            }
            &.dos {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 20px;
              width: 30%;
              @media (min-width: 280px) and (max-width: 1100px) {
                gap: 10px;
              }
              .button-plus {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                background: none;
                border: none;
                cursor: pointer;
                svg {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0;
                }
              }
            }
          }
        }

        .content-table-children {
          display: flex;
          flex-direction: row;

          @media (min-width: 1100px) {
            width: calc(100% / 6);
          }
          @media (min-width: 700px) and (max-width: 1100px) {
            width: calc(100% / 3);
          }
          @media (max-width: 700px) {
            width: calc(100% / 2);
          }

          &.cargas {
            @media (min-width: 1100px) {
              width: calc(100% / 5);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 3);
            }
            @media (max-width: 700px) {
              width: calc(100% / 2);
            }
          }

          &.columna {
            @media (min-width: 1100px) {
              width: calc(100% / 1);
            }
            @media (min-width: 700px) and (max-width: 1100px) {
              width: calc(100% / 1);
            }
            @media (max-width: 700px) {
              width: calc(100% / 1);
            }
          }

          .title-table-children {
            display: flex;
            justify-content: center;
            vertical-align: middle;
            align-items: center;
            flex-basis: 40%;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            background: #fafafa;
            border: 1px solid #b0c1d5;
            @media (max-width: 700px) {
              font-size: 14px;
              line-height: 20px;
            }
          }

          .subtitle-table-children {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            div {
              flex-basis: 50%;
              display: flex;
              justify-content: center;
              vertical-align: middle;
              align-items: center;
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              padding: 10px 20px;
              background: #fafafa;
              border: 1px solid #b0c1d5;
              @media (max-width: 700px) {
                font-size: 14px;
                line-height: 20px;
                padding: 4px;
              }
              &:first-child {
                background: #dadada;
                border: 1px solid #b0c1d5;
              }
            }
          }
        }

        .content-button-plus {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          .button-plus {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            background: none;
            border: none;

            svg {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0;
              font-size: 34px;
            }
          }
        }
      }
    }
  }
}

.content-table-rowFlex {
  display: flex;
  @media (max-width: 700px) {
    display: contents;
  }
}
.gapAreaContr {
  gap: 8px 0px !important;
}

.gapContent {
  gap: 8px 10px;
}
.title-table-rowResp {
  display: flex;
  justify-content: end;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 16px;
  background: #D9E1EA;
  border: 1px solid #b0c1d5;

  &.contratos{
    justify-content: start;
  }
}

.title-table-rowRespP {
  display: inline-block !important;
  justify-content: start;
  align-items: center;
  min-height: 45px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 16px;
  background: #d4d4d4;
  border: 1px solid #b0c1d5;
}

.subtitle-table {
  display: flex;
  justify-content: start;
  align-items: center;
  max-height: 45px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500; 
  font-size: 15px;
  background: #fdfdfd;
  border: 1px solid #b0c1d5;
  @media (max-width: 700px) {
    font-size: 14px;
    min-height: 40px;
    line-height: 20px;
    padding: 4px;
  }
  &.cinco {
    background: #d9e1ea;
    border-left: 1px solid #b0c1d5;
    width: 25%;
    gap: 1px;
    &:first-child {
      border-left: none;
    }
    &:nth-child(2) {
      background: #d4d4d4;
    }
    &:nth-child(4) {
      background: #d4d4d4;
    }
  }
}

.subtitle-tableSin {
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  background: #fdfdfd;
  padding-left: 15px;
  @media (max-width: 700px) {
    font-size: 14px;
    min-height: 40px;
    line-height: 20px;
    padding: 4px;
  }
}

.container-input {
  display: flex;
  width: 100%;
  gap: 35px;
  flex-direction: row;

  &.psico {
    flex-direction: row;
  }
  @media (min-width: 700px) {
    display: block;
  }
  .content-input {
    display: flex;
    justify-content: start;
    align-items: end;
    gap: 40px 40px;
    margin-bottom: 30px;

    &.input-number {
      margin-bottom: 16px;
    }

    .content-select-modal-update,
    .content-text-modal-update {
      display: flex;
      flex-direction: row;
      width: 100;
      &.end {
        justify-content: end;
      }
      &.disabled {
        display: none;
        transition: all 0.3s ease;
      }
    }

    .button-delete-psicolaboral {
      display: flex;
      justify-content: center;
      margin: auto;
      background: #d32f2f;
      border-radius: 8px;
      padding: 4px 8px;
      align-items: center;
      border: 2px solid #d32f2f;
      width: 32px;
      height: 33px;
      color: #fdfdfd;
      margin-top: 39px;
      font-size: initial;
      cursor: pointer;

      @media (max-width: 500px) {
        margin: auto;
        margin-top: 20px;
      }

      svg {
        display: flex;
        background: transparent;
        margin: 0;
        color: #fafafa;
        font-size: 32px;
        fill: 8px;
      }

      &:hover {
        background: #d32f2f;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        border: 2px solid #d32f2f;
        svg {
          color: #d32f2f;
        }
      }
    }
  }
}
.subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: #3B6597;
}
.linea {
  border-bottom: 2px dashed #dedede;
}

.border {
  border: 1px solid #b0c1d5;
  width: 40px;
  height: 36px;
  background: #d4d4d4;
  justify-content: center;
  display: flex;
  align-items: center;
  float: left;
}
/* CustomComponent.css */
.custom-component {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 39px;
  width: 100%;
  &.disabled {
    display: none;
    transition: all 0.3s ease;
  }
}

.col9 {
  flex: 7.5;
  border: 1px solid #b0c1d5;
  background-color: #D9E1EA;
  height: 39px;
  min-height: 39px;
}
.col9B {
  flex: 7.5;
  border: 1px solid #b0c1d5;
  height: 39px;
  min-height: 39px;
}

.col3 {
  flex: 4.5;
  border: 1px solid #b0c1d5;
  background-color: #D9E1EA;
  height: 39px;
  min-height: 39px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.col3B {
  flex: 4.5;
  border: 1px solid #b0c1d5;
  height: 39px;
  min-height: 39px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.col-content {
  justify-content: start;
  align-items: center;
  min-height: 39px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 16px;
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 320px
}

.button-space {
  display: flex;
  // flex-direction: row;

  justify-content: space-between;
  padding: 10px;
}

.custom-button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #b0c1d5;
  background-color: #D9E1EA;
  cursor: pointer;
  justify-content: space-between;
  min-height: 39px;
  cursor: pointer;
  width: 100%;
}

.custom-buttonB {
  border: 1px solid #b0c1d5;
  background-color: #fcfcfc;
  cursor: pointer;
  justify-content: space-between;
  min-height: 39px;
  cursor: pointer;
  width: 100%;
}

.custom-buttonSelect {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  border: 1px solid #b0c1d5;
  background-color: #e9ebf8;
  cursor: pointer;
  justify-content: space-between;
  min-height: 39px;
  cursor: pointer;
  width: 100%;
}

.titleInput {
  font-family: Inter;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  padding-bottom: 8px;
}

.textInput {
  font-family: Inter !important;
  font-weight: 400 !important;
  size: 15px;
  line-height: 20px !important;
  color: #48566b;
  width: 100%;
}

.mesageDown{
  font-family: Inter;
  font-weight: 500;
  size: 13.17px;
  line-height: 16px;
  margin-top: 18px;
  color: #A3A3A3;
  display: flex;
}

.mesageDownRed{
  font-family: Inter;
  font-weight: 500;
  size: 13.17px;
  line-height: 16px;
  margin-top: 8px;
  color: #D32F2F;
  display: flex;
  float: right;
  padding-right: 100px;
  font-style: italic;
}

.textInTableCategoria {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.competencias2{
  padding: 0px;
  display: flex;
  flex-direction: row;
  @media  (max-width: 770px){
   
 flex-direction: column;
  }
}

.butonForAdd{
  display: flex;
  padding-left: 18px;
  align-items: center;
}

.project-chartPsico {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 35px;
  border-radius: 8px;
  background: var(--relleno-gral-model, #fafafa);
  box-shadow: 0px 2px 4px 0px rgba(29, 29, 29, 0.2);
  //height:680px;
  width: 60%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    width: 100%;
  }

  &.experience {
    flex-direction: row;
    @media  (max-width: 770px){

      flex-direction: column;
       }
  }
  &.disabled {
    display: none;
    transition: all 0.3s ease;
  }

  .container-table {
    display: flex;
    vertical-align: middle;
    align-items: center;
   

    @media (max-width: 700px) {
      gap: 6px 0px;
    }
  .title {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
   padding-top: 30px;
   padding-bottom: 10px;

    @media (max-width: 700px) {
      font-size: 15px;
    }
  }
}
}
