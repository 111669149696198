
.container-login {
  background: #005bc7;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;

  .content-login {
    background: #fafafa;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;

    .content-flex {
      width: 60%;
      justify-content: center;

      .img-login {
        display: flex;
        justify-content: center;
        margin: 6rem 1rem 4rem 1rem;
      }

      .content-text {
        display: flex;
        justify-content: center;
        font-style: normal;

        .title {
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #005bc7;
          margin: 3rem 0rem;
        }

        .description {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          color: #040404;
          margin-bottom: 4rem;
        }

        .correo {
          width: 419px;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          color: #040404;
          justify-content: center;
        }

        .email {
          width: 419px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          color: #01326a;
          justify-content: center;
          margin-bottom: 4rem;
        }

        .info {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          color: #040404;
          margin-bottom: 10px;
        }

        .content-inside {
          width: 55%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px;
          background: #FAFAFA;
          text-align: center;
          margin: 0px 10px 40px 10px;
          box-shadow: 0px 2px 2px rgba(4, 54, 154, 0.17);

          .text-invitation {
            width: 419px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            color: #040404;
            justify-content: center;
            text-transform: uppercase;
            text-align: center;
          }

          .content-password {
            max-width: 100%;
            width: 100%;
            margin: 10px;
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border: 1px solid #D4D4D4; 
            border-radius: 8px;
  
            .text-pass {
              max-width: 100%;
              width: 18%;
              height: 48px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              font-weight: 700;
              padding: 8px 12px;
              margin: 5px;
              text-align: center;
              text-transform: uppercase;
            }
          }

          .pass-error {
            border: 2px solid #b10000; 
            background-color: #ea9e9e;
          }
        }
      }
    }

    .content-button {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 2rem;

      .button-login {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        padding: 8px 16px;
        width: 50%;
        height: 41px;
        gap: 10px;
        background: #005bc7;
        border-radius: 8px;
      }
    }

    .error-code {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0px;
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #FE0000;
      text-align: center;
    }
  }
}

// ----------------------------------------------------- //
// ---------------------- 960px ------------------------ //
// ----------------------------------------------------- //
@media (max-width: 960px) {

  .container-login {
    background: #005bc7;
    width: 100%;
    display: flex;
    height: 100vh;
    justify-content: center;
  
    .content-login {
      background: #fafafa;
      width: 100%;
      display: flex;
      height: 80vh;
      justify-content: center;
      border-radius: 0px 0px 34px 34px;
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
  
      .content-flex {
        width: 80%;
        justify-content: center;
  
        .img-login {
          display: flex;
          justify-content: center;
          margin: 10rem 1rem 4rem 1rem;
        }
  
        .content-text {
          display: flex;
          justify-content: center;
          font-style: normal;
  
          .title {
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #005bc7;
            margin: 3rem 0rem;
          }
  
          .description {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: #040404;
            margin-bottom: 4rem;
          }
  
          .correo {
            width: 419px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            color: #040404;
            justify-content: center;
          }
  
          .email {
            width: 419px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            color: #01326a;
            justify-content: center;
            margin-bottom: 4rem;
          }
  
          .info {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: #040404;
            margin-bottom: 10px;
          }

          .content-inside {
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 24px;
            background: #FAFAFA;
            margin: 0px 10px 40px 10px;
            box-shadow: 0px 2px 2px rgba(4, 54, 154, 0.17);
  
            .text-invitation {
              width: 419px;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              display: flex;
              color: #040404;
              justify-content: center;
              text-transform: uppercase;
            }
  
            .content-password {
              max-width: 100%;
              width: 100%;
              margin: 10px;
              display: flex;
              justify-content: space-between;
              padding: 10px;
              border: 1px solid #D4D4D4; 
              border-radius: 8px;
    
              .text-pass {
                max-width: 100%;
                width: 18%;
                height: 48px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 700;
                padding: 8px 12px;
                margin: 5px;
                text-align: center;
                text-transform: uppercase;
              }
            }
          }
        }
      }
  
      .content-button {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 6rem;
  
        .button-login {
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          width: 50%;
          gap: 10px;
          background: #005bc7;
          border-radius: 8px;
          height: 41px;
        }
      }
    }
  }
}

// ----------------------------------------------------- //
// ---------------------- 480px ------------------------ //
// ----------------------------------------------------- //
@media (max-width: 480px) {
  .container-login {
    background: #005bc7;
    width: 100%;
  
    .content-login {
      background: #fafafa;
      width: 100%;
      height: 90vh;
  
      .content-flex {
        width: 90%;
        justify-content: center;
  
        .img-login {
          display: flex;
          justify-content: center;
          margin: 8rem 1rem 4rem 1rem;
        }
  
        .content-text {
          display: flex;
          justify-content: center;
          font-style: normal;
  
          .title {
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #005bc7;
            margin: 2rem 0rem;
          }
  
          .description {
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: #040404;
            margin-bottom: 4rem;
          }
  
          .correo {
            width: 419px;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            display: flex;
            color: #040404;
            justify-content: center;
          }
  
          .email {
            width: 419px;
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            display: flex;
            color: #01326a;
            justify-content: center;
            margin-bottom: 4rem;
          }
  
          .info {
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: #040404;
            margin-bottom: 10px;
          }

          .content-inside {
            max-width: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 24px;
            background: #FAFAFA;
            margin: 0px 10px 40px 10px;
            box-shadow: 0px 2px 2px rgba(4, 54, 154, 0.17);
  
            .text-invitation {
              width: 100%;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              display: flex;
              color: #040404;
              justify-content: center;
              text-transform: uppercase;
            }
  
            .content-password {
              max-width: 100%;
              width: 100%;
              margin: 10px;
              display: flex;
              justify-content: space-between;
              padding: 6px;
              border: 1px solid #D4D4D4; 
              border-radius: 8px;
    
              .text-pass {
                width: 20%;
                height: 40px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 700;
                padding: 8px 8px;
                margin: 5px;
                text-align: center;
                text-transform: uppercase;
              }
            }
          }
        }
      }
  
      .content-button {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 4rem;
  
        .button-login {
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          width: 50%;
          gap: 10px;
          background: #005bc7;
          border-radius: 8px;
          height: 41px;
        }
      }
    }
  }
}

// ----------------------------------------------------- //
// ---------------------- 480px ------------------------ //
// ----------------------------------------------------- //
@media (max-width: 290px) {
  .container-login {
    background: #005bc7;
    width: 100%;
  
    .content-login {
      background: #fafafa;
      width: 100%;
      height: 90vh;
  
      .content-flex {
        width: 95%;
        justify-content: center;
  
        .img-login {
          display: flex;
          justify-content: center;
          margin: 8rem 1rem 4rem 1rem;
        }
  
        .content-text {
          display: flex;
          justify-content: center;
          font-style: normal;

          .content-inside {
            max-width: 100%;
            padding: 14px;
            margin: 0px 5px 40px 5px;
  
            .text-invitation {
              font-size: 14px;
              line-height: 20px;
              align-items: center;
            }
  
            .content-password {
              max-width: 100%;
              width: 100%;
              margin: 5px;
              display: flex;
              justify-content: space-between;
              padding: 6px;
              border: 1px solid #D4D4D4; 
              border-radius: 8px;
    
              .text-pass {
                width: 20%;
                height: 35px;
                font-size: 10px;
                font-weight: 600;
                padding: 4px 4px;
                margin: 5px;
              }
            }
          }
        }
      }
    }
  }
}