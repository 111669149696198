@import "../config";

/**
* TODO: ESTILOS TODOS PERRONES PARA LOS DASHBOREEES
**/

.container-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;
  gap: 24px;

  .section-dashboard {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
  }
}
.MessageGraf{
  color: #A3A3A3;
  font-family: Inter;
font-weight: 400;
font-size: 16px;
line-height: 19px;
align-items: end!important;
text-align: end !important;
font-style: italic;
justify-content: end !important;
display: flex;
}