@import "./_config";
/**
* TODO: CHANGES IN MODAL UPDATE CANDIDATE  
*/

$fondoBlanco: #fafafa;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.modal-container-updateCandidate {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  // max-width: 100%;
  padding: 30px 0px;
  height: 100%;
  background-color: #1e1e1ece;
  z-index: 13;
  overflow-y: scroll;

  .modal-content-updateCandidate {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: $fondoBlanco;
    padding: 90px 72px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 24px;
    margin: auto;
    gap: 16px;

    &.w-80 {
      width: 80%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 96%;
        padding: 32px 14px;
        gap: 10px;
      }
    }

    .linea-separador-titulo {
      margin: -15px 0px -8px 0px;
      border-bottom: 2px dashed #dedede;
    }

    .modal-content-message {
      max-width: 100%;
      background-color: $fondoBlanco;
      padding: 48px 46px;
      border: 1px solid #888;
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      border-radius: 24px;
      margin: auto;
    }

    .modal-close {
      display: flex;
      justify-content: end;
      color: #aaa;
      margin: -20px -20px -10px -10px;
      svg {
        display: flex;
        margin: 0;
        font-size: 30px;
        color: #48566b;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        margin: -10px 0px -5px -5px;
      }
    }

    .modal-close:hover,
    .modal-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    .modal-title {
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;
      @media (min-width: 280px) and (max-width: 1100px) {
        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
      &.modal-new-candidate {
        padding: 0px 0px 15px 0px;
      }
    }

    .modal-subtitle {
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
    }

    .modal-updating {
      display: flex;
      justify-content: end;
      align-items: center;
      text-align: center;
      font-family: $fontFamilyInter;
      font-size: 11.57px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      color: $negroTabla;
    }

    .modal-rechazado {
      display: flex;
      justify-content: start;
      align-items: center;
      font-family: $fontFamilyInter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: $colorRojo;
    }

    .modal-message {
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
    }

    ///////////////////// TABLA FICHA BIENPERRON /////////////////////
    ///////////////////// TABLA FICHA BIENPERRON /////////////////////
    .separador-seccion {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      border-bottom: 1px solid #dedede;
    }

    .seccion-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .span-title {
        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: $colorTitleModalUpdate;
        padding: 0px;
      }
      svg {
        display: flex;
        margin: 0;
        justify-content: center;
        align-items: center;
        font-size: 44px;
        color: $colorTitleModalUpdate;
        transition: all 0.3s ease;
        &.disabled {
          transform: rotate(180deg);
          transition: all 0.3s ease;
        }
      }
    }

    .project-table {
      display: flex;
      justify-content: center;
      gap: 15px;
      flex-direction: column;
      &.disabled {
        display: none;
      }

      /** 
      * TODO: news styles in area documents
      */

      .container-documentos {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        .content-documentos {
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: 10px;

          .span-text-document {
            font-family: "Inter";
            font-size: 16px;
            font-style: italic;
            font-weight: 400;
            line-height: 19px;
            text-align: left;
            color: #b1b1b1;
          }

          .content-input-cargar {
            display: flex;
            width: 100%;
            max-width: 100%;
            flex-direction: row;
            justify-content: end;
            align-content: end;
            align-items: end;
            gap: 15px;
            margin: 8px 0px;

            .content-button-document {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              align-content: center;

              .button-document {
                display: flex;
                margin: 0;

                svg {
                  display: flex;
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .advertencia-label {
        color: var(--texto-general-textos-negro, #404040);
        font-family: $fontFamilyInter;
        font-size: $fontSizeMid;
        font-style: italic;
        font-weight: 400;
        line-height: 19px;
      }

      .advertencia-label-proceso {
        color: $colorRojo;
        font-family: "Inter";
        font-size: $fontSizeMid;
        font-style: italic;
        font-weight: 400;
        line-height: 19px;
      }

      .content-subtitle {
        display: flex;
        justify-content: flex-start;
        vertical-align: middle;
        flex-direction: column;
        gap: 10px 0px;

        div {
          display: flex;
          justify-content: flex-start;
          vertical-align: middle;
          flex-direction: column;
          flex-direction: row;
          gap: 10px;

          .title {
            font-family: $fontFamilyInter;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            text-transform: uppercase;
            color: $colorTitleModalUpdate;
          }

          .subtitle {
            font-family: $fontFamilyInter;
            font-style: normal;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-transform: uppercase;
            color: $colorTitleModalUpdate;
          }
          .sub-subtitle {
            font-family: $fontFamilyInter;
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            color: #a3a3a3;
          }
        }
        .linea {
          border-bottom: 2px dashed #dedede;
        }
      }

      /**
      * TODO: Contendor de los inputs y select del modal AreaPersonal
      */

      .container-input {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0px;

        .content-input {
          display: flex;
          justify-content: start;
          align-items: end;
          gap: 40px 40px;
          margin-bottom: 30px;

          &.input-number {
            margin-bottom: 16px;
          }

          .content-select-modal-update,
          .content-text-modal-update {
            display: flex;
            flex-direction: row;
            width: 100;
            &.end {
              justify-content: end;
            }
          }

          .button-delete {
            display: flex;
            justify-content: center;
            margin: 0;
            background: #d32f2f;
            border-radius: 8px;
            padding: 4px 8px;
            align-items: center;
            border: 2px solid #d32f2f;
            width: 50px;
            height: 42px;
            cursor: pointer;

            svg {
              display: flex;
              background: transparent;
              margin: 0;
              color: $fondoBlanco;
              font-size: 32px;
              fill: 8px;
            }

            &:hover {
              background: $fondoBlanco;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              border: 2px solid #d32f2f;
              svg {
                color: #d32f2f;
              }
            }
          }
        }
      }

      .process-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        gap: 30px;
        margin-bottom: 10px;

        .div-button {
          display: flex;
          justify-content: center;
          align-items: end;
          .button-accept {
            display: flex;
            min-width: 120px;
            justify-content: center;
            align-items: center;
            padding: 6px 24px;
            gap: 10px;
            border-radius: 8px;
            font-family: $fontFamilyInter;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            cursor: pointer;

            &.cancel {
              color: $fondoBlanco;
              border: 2px solid #005bc7;
              background: #005bc7;

              &:hover {
                border: 2px solid #005bc7;
                background: $fondoBlanco;
                color: #005bc7;
              }
            }
          }
        }
      }

      .container-grilla {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 50px;

        .grilla-content {
          display: flex;
          justify-content: center;
          vertical-align: middle;
          flex-wrap: wrap;
          font-family: $fontFamilyInter;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          min-height: 45px;

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            vertical-align: middle;
            flex-basis: calc(100% / 4);
            flex-direction: row;
            padding: 10px 12px;
            font-size: 16px;
            border: 1px solid #b0c1d5;
            background: #d4d4d4;
            &.xcuatro {
              flex-basis: calc(100% / 4);
            }
          }

          .message {
            display: flex;
            flex-basis: calc((100% / 4) * 3);
            flex-direction: row;
            align-items: center;
            font-size: 16px;
            padding: 10px 12px;
            border: 1px solid #b0c1d5;
            &.xcuatro {
              flex-basis: calc(100% / 4);
            }
          }
        }
      }
    }

    /////////////////////////// button design ///////////////////////////
    /////////////////////////// button design ///////////////////////////

    .modal-button-content {
      display: flex;
      justify-content: end;
      align-items: center;
      vertical-align: middle;
      margin-top: 22px;
      gap: 32px;

      &.document-masive {
        gap: 10px;
        margin-top: auto;
      }

      .modal-button {
        display: flex;
        min-width: 200px;
        justify-content: center;
        align-items: center;
        padding: 6px 24px;
        gap: 10px;
        border-radius: 8px;
        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        cursor: pointer;

        &.document-masive {
          padding: 0px;
          min-width: auto;
          width: 40px;
          height: 40px;
          margin: auto;
        }

        @media (min-width: 700px) and (max-width: 1100px) {
          min-width: 190px;
        }

        @media (max-width: 700px) {
          min-width: 140px;
        }

        &:hover {
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        }

        &.confirm {
          color: $fondoBlanco;
          border: 2px solid #06a502;
          background: #06a502;

          &:hover {
            background: $fondoBlanco;
            color: #06a502;
            border: 2px solid #06a502;
          }

          &:disabled {
            background: #aeaeae;
            color: #fafafa;
            border: 2px solid #aeaeae;
            cursor: not-allowed;
          }
        }

        &.cancel {
          color: #005bc7;
          border: 2px solid #005bc7;
          background: #fafafa;

          &:disabled {
            background: #aeaeae;
            color: #fafafa;
            border: 2px solid #aeaeae;
            cursor: not-allowed;
          }
        }

        &.delete {
          color: $fondoBlanco;
          border: 2px solid #d32f2f;
          background: #d32f2f;

          &:hover {
            background: $fondoBlanco;
            color: #d32f2f;
            border: 2px solid #d32f2f;
          }
          &:disabled {
            background: #aeaeae;
            color: #fafafa;
            border: 2px solid #aeaeae;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

.wrap {
  display: flex;
  flex-wrap: wrap;
}

.w-dies {
  display: flex;
  width: 10%;
}

.w-cinco {
  display: flex;
  width: 5%;
}

.w-50v2 {
  display: flex;
  width: calc(50% - 20px);
}

.w-x6 {
  display: flex;
  width: calc((100% / 6) + 5px);
}

.w-x3 {
  display: flex;
  width: calc(100% / 3);
}

.w-x5 {
  display: flex;
  width: calc(95% / 5);
}

@for $i from 100 through 0 {
  .gap-#{$i} {
    gap: $i * 1px;
  }
}

@for $i from 100 through 0 {
  .w-#{$i} {
    width: $i * 1%;
  }
}
