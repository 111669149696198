@import "../ui/_config";

/**
* TODO: CHANGES IN MODAL UPDATE CANDIDATE  
*/
$fondo: #ffffff;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.container-new-nuevo-candidato {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: $fondo;
  border-radius: 8px;
  margin-top: 16px;

  .content-new-nuevo-candidato {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    border-radius: 10px 10px 0px 0px;
    background: #f5f5f5;
    
    &.hidden-section {
      border-radius: 10px;
      gap: 10px;
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    }

    .content-title {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 90%;

      .title {
        display: flex;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: #262626;
        padding: 20px 0px;
      }

      .button-arrow-down {
        display: flex;
        position: absolute;
        background: transparent;
        border: none;
        right: 0px;
        cursor: pointer;

        &.content-hidden {
          transform: rotate(180deg);
        }

        svg {
          display: flex;
          margin: 0;
        }
      }
    }

    .container-action-nuevo-candidato {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      &.hidden-section {
        display: none;
      }

      .content-message {
        display: flex;
        align-items: start;
        justify-content: start;
        margin: 20px 0px 43px 0px;
        width: 90%;

        .message {
          font-family: "Inter";
          font-style: italic;
          font-weight: 400;
          font-size: 16px;
        }

        .message-initial {
          color: #48566b;
        }

        .message-danger {
          color: #d32f2f;
        }

        .message-success {
          color: #06A502;
        }
      }

      .content-accion {
        display: flex;
        justify-content: space-between;
        width: 90%;

        .section-left,
        .section-rigth {
          display: flex;
          justify-content: center;
          align-items: end;
          flex-direction: row;
          gap: 30px;

          .button-all {
            display: flex;
            min-width: 200px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 10px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            gap: 12px;
            background: #aeaeae;
            color: #fafafa;
            border-radius: 8px;
            border: none;
            height: 40px;
            cursor: pointer;
            transition-duration: .2s;
            transition-timing-function: ease;

            &.disabled {
              background: #aeaeae;
              cursor: not-allowed;
            }

            svg {
              display: flex;
              margin: 0;
              font-size: 26px;
              color: #fafafa;
            }

            &.rut {
              background: $botonAzulRut;
              border: 2px solid $botonAzulRut;
              &:hover {
                background: $botonAzulRutHover;
                box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
                border: 2px solid $botonAzulRutHover;
            }
            }

            &.solicitar {
              background: #e6a200;
              border: 2px solid #e6a200;
              &:hover {
                background: #fafafa;
                color: #e6a200;
                box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              }
            }

            &.continuar {
              background: $botonNaranja;
              border: 2px solid $botonNaranja;
              &:hover {
                color: $botonNaranja;
                background: #fafafa;
                box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              }
            }
          }
        }
      }

      .content-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 20px 0px;
        width: 100%;
        border-radius: 0px 0px 10px 10px;
        background: #e5e5e5;
        gap: 10px;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);

        .content-section {
          display: flex;
          justify-content: start;
          align-items: center;
          flex-direction: row;
          width: 90%;
          gap: 20px;

          .span-section {
            font-family: "Inter";
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            color: #001935;
          }

          .button-cargar {
            display: flex;
            min-width: 200px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 10px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            gap: 12px;
            background: #aeaeae;
            color: #fafafa;
            border-radius: 8px;
            border: none;
            height: 40px;
            background: #48566b;
            border: 2px solid #48566b;
            transition-duration: .1s;
            transition-timing-function: ease;

            &:hover {
              color: #48566b;
              background: #fafafa;
              border: 2px solid #48566b;
              cursor: pointer;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              svg {
                color: #48566b;
              }
            }
            svg {
              display: flex;
              margin: 0;
              font-size: 26px;
              color: #fafafa;
            }
            &:disabled {
              background: #aeaeae;
              border: #aeaeae;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

.titleInCargaNomina{
  display: block;
  font-family:Inter;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  align-items: center;
  color: #262626;
  padding: 14px;
}

.button-cargarN{
  background: #06A502 !important;
    border-radius: none;
    font-family: Inter;
    width: 180px;
    height: 33px;
    display: flex;
    font-size: 18px;
    padding: 17px;
    gap:5px;

    &.deshabilitado {
      background: #aeaeae !important;
      cursor: not-allowed;
      border-radius: none;
      width: 180px !important;
      height: 33px !important;
      display: flex !important;
      font-size: 16px !important;
      padding: 17px !important;
      gap:5px;
    }
}

.button-descargaN{
  background: #0065DD !important;
    border-radius: none;
    font-family: Inter;
    width: 240px;
    height: 40px;
    display: flex;
    font-size: 18px;
    padding: 17px;
    gap:5px;
    justify-content: center !important;
   
    &.deshabilitado {
      background: #aeaeae !important;
      cursor: not-allowed;
      font-family: Inter !important;
      border-radius: none;
      width: 240px !important;
      height: 40px !important;
      display: flex !important;
      font-size: 18px !important;
      padding: 17px !important;
      gap:5px;
    }
}
.pCargaNomina{
  font-family:Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-style: italic;
  padding-top: 20px;
}

.aqui{
  color: #005BC7;
  text-decoration: underline;
  cursor: pointer;
}

.message-danger-in-resumen-procesado {
  color: #D32F2F !important;
}

.message-duplicate-in-resumen-procesado {
  color: #005BC7 !important;
}

.message-nuevo-in-resumen-procesado {
  color: #06A502 !important;
}
.container-icon-loading {
  display: flex;
  margin: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  animation: spin 2s linear infinite;
  background: transparent;

  .icon-loading {
    display: flex;
    margin: 0;
    font-size: 22px;
  }
}