@import "../ui/_config";

/**
* TODO: NUEVOS DISEÑOS EN REDISEÑO TEAMWORK
*/

.container-operacion-bajas-confirmadas {
  display: flex;
  background-color: #fafafa;
  width: 100%;
  justify-content: center;

  .content-operacion-bajas-confirmadas {
    display: flex; 
    flex-direction: column;
    width: 85%;
    margin: 20px 0px;
    gap: 16px 0px;

    &.page-old {
      width: 100%;
      margin: 0px;
      gap: 0px;
    }

    .section-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;
      background: #005bc7;
      margin: 12px 0px;
      cursor: pointer;

      .section-option {
        display: flex;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #fafafa;
      }

      svg {
        font-size: 30px;
        color: #fafafa;
      }
    }

    .content-header-cliente {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 30px;
      background: #caefc9;
      margin: 0px 0px -15px 0px;
      @media (max-width: 480px) {
        padding: 16px 16px;
      }

      .title-header-table {
        display: flex;
        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 500;
        font-size: $fontSizeMid;
        line-height: 20px;
        color: $negroTw;

        .text-cliente {
          color: #005bc7;
          font-family: $fontFamilyInter;
          font-size: $fontSizeMid;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          &::before {
            content: "CLIENTE: ";
            color: $negroTw;
          }
        }
      }
    }

    .content-header-table {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 30px;
      background: #003d85;
      color: $blancoBasico;
      @media (max-width: 480px) {
        padding: 16px 16px;
      }

      .title-header-table {
        display: flex;
        flex-direction: row;
        justify-content: center;
        vertical-align: center;
        align-items: center;
        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 500;
        font-size: $fontSizeMid;
        line-height: 20px;
        color: $blancoBasico;
        gap: 5px;

        .text-grupo {
          color: #ffca4d;
          font-family: $fontFamilyInter;
          font-size: $fontSizeMid;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          &::before {
            content: "GRUPO: ";
            color: $blancoBasico;
          }
        }

        .text-proceso {
          color: #fafafa;
          font-family: $fontFamilyInter;
          font-size: $fontSizeMid;
          font-style: normal;
          font-weight: 600;

          &.proceso-content {
            font-family: $fontFamilyInter;
            font-weight: 400;
          }
        }
      }

      .button-hidden-table {
        display: flex;
        background: transparent;
        border: none;
        margin: 0;
        cursor: pointer;

        .svg-table-hidden {
          display: flex;
          font-size: 22px;
          margin: 0;
          color: $whiteTw;
          transition: transform 0.3s ease;
          &.icon-up {
            transform: rotate(0deg);
          }
          &.icon-down {
            transform: rotate(180deg);
          }
        }
      }
    }

    .container-table-list {
      width: 100%;
      overflow-x: auto;
      max-height: 2000px;
      margin: -15px 0px 0px 0px;
      transition: max-height 0.5s ease;

      .hidden {
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.5s ease;
      }
    }

    /**
    * TODO: CONTENIDO DE TABLA
    */
    .content-table {
      text-align: start;
      border-collapse: collapse;
      width: 100%;
      overflow-x: auto;

      .thead-table-registros,
      .thead-table-procesos {
        align-items: center;
        background: #d9e8fa;

        .th-table-registros,
        .th-table-procesos {
          font-family: $fontFamilyInter;
          font-style: normal;
          font-weight: 700;
          font-size: $fontSizeMid;
          line-height: 20px;
          border-radius: 0px;
          padding: 4px 6px;
          color: $negroTabla;
          height: 56px;

          @media (min-width: 700px) and (max-width: 1100px) {
            padding: 2px;
            font-size: $fontSizeSmall;
            line-height: 19px;
            height: 48px;
          }
          @media (max-width: 700px) {
            padding: 4px;
            font-size: $fontSizeSmall;
            line-height: 19px;
            height: 48px;
          }
          .button-select {
            display: flex;
            justify-content: center;
            margin: 0;
            background: transparent;
            border: none;
            cursor: pointer;
            svg {
              display: flex;
              margin: 0;
            }
          }
        }
      }

      .tbody-table-registros,
      .tbody-table-procesos {
        justify-content: center;
        align-items: center;

        .tr-table-registros,
        .tr-table-procesos {
          justify-content: center;
          align-items: center;
          background: #fafafa;
          border-bottom: 1px dashed #ccc;

          &.checkeado {
            background: #e9ebf8;
          }

          .td-table-registros,
          .td-table-procesos {
            font-family: $fontFamilyInter;
            font-style: normal;
            font-weight: 400;
            font-size: $fontSizeTable;
            line-height: 20px;
            border-radius: 0px;
            padding: 4px;
            text-align: center;
            color: $negroTw;
            height: 46px;

            @media (min-width: 700px) and (max-width: 1100px) {
              padding: 4px;
              font-size: 14px;
              line-height: 19px;
              height: 46px;
            }
            @media (max-width: 700px) {
              padding: 1px;
              font-size: 14px;
              line-height: 19px;
              height: 46px;
            }
            &.inactiva {
              color: #aeaeae;
              fill: #aeaeae;
            }

            .button-select {
              display: flex;
              justify-content: center;
              margin: 0;
              background: transparent;
              border: none;
              cursor: pointer;
              svg {
                display: flex;
                margin: 0;
              }
            }

            .content-state {
              display: flex;
              justify-content: center;
              align-items: center;
              vertical-align: center;
              text-align: center;
              gap: 5px;

              .content-state-icon {
                display: flex;
                justify-content: center;
                width: 20%;

                .content-icon {
                  display: flex;
                  margin: 0;
                }
              }

              .button-select {
                display: flex;
                justify-content: center;
                margin: 0;
                background: transparent;
                border: none;
                svg {
                  display: flex;
                  margin: 0;
                }

                &:disabled {
                  cursor: not-allowed;
                }
              }

              .content-state-text {
                display: flex;
                flex-direction: column;
                justify-content: start;
                text-align: start;
                width: 80%;

                .title {
                  display: flex;
                  width: 100%;
                  font-family: $fontFamilyInter;
                  font-size: $fontSizeXSmall;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 15px;

                  &.conducta-inadecuada,
                  &.proceso-legal {
                    color: $colorRojo;
                  }
                  &.contrato-activo {
                    color: $colorVerde;
                  }
                  &.registro-completo {
                    color: $colorVerde;
                  }
                  &.registro-incompleto {
                    color: $colorAmarillo;
                  }
                  &.proceso-interno {
                    color: $colorAmarillo;
                  }
                  &.solicitud-recibida {
                    color: $colorNaranjo;
                  }
                  &.solicitud-enviada {
                    color: $colorAmarillo;
                  }
                  &.solicitud-rechazada {
                    color: $colorRojo;
                  }
                  &.registro-ocupado {
                    color: $colorRojo;
                  }
                }

                .message {
                  display: flex;
                  width: 100%;
                  color: #404040;
                  font-family: $fontFamilyInter;
                  font-size: $fontSizeXSmall;
                  font-weight: 400;
                  line-height: 15px;
                }
              }
            }

            .content-icon-table {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;

              @media (min-width: 700px) and (max-width: 1100px) {
                gap: 4px;
              }
              @media (max-width: 700px) {
                gap: 3px;
              }

              .button-content {
                display: flex;
                border: none;
                background: none;
                padding: 2px;
                margin: 0;
                cursor: pointer;

                &:disabled {
                  cursor: not-allowed;
                }
                svg {
                  display: flex;
                  margin: 0;
                  font-size: 24px;
                  &:disabled {
                    color: $colorDisabled;
                    fill: $colorDisabled;
                  }

                  &.disabled {
                    color: $botonDisabled;
                    fill: $botonDisabled;
                  }

                  &.edit {
                    background: none;
                    color: #0065dd;
                    &:active {
                      background-color: #0065dd;
                      color: #fafafa;
                      border-radius: 50%;
                      padding: 2px;
                    }
                  }

                  &.restaurar {
                    background: none;
                    color: #0065dd;
                    transform: rotateY(180deg);
                    &:active {
                      background-color: #0065dd;
                      color: #fafafa;
                      fill: #fafafa;
                      border-radius: 50%;
                      padding: 2px;
                    }
                  }

                  &.reasignar {
                    background: none;
                    color: #0065dd;
                    &:hover {
                      background-color: #0065dd;
                      width: 25px;
                      height: 25px;
                      fill: #fafafa;
                      border-radius: 50%;
                      padding: 2px;
                      border: 1px solid #0065dd;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/** 
* TODO: NUEVO MODAL PARA SELECCION 
*/

.modal-section-motivo-detencion {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .section-detener-solicitud{
    display: flex;
    width: 75%;
  }

  .section-checkall { 
    display: flex; 
    width: 25%;
    justify-content: center; 
    align-items: end; 
    vertical-align: middle;
    margin-bottom: 10px;
    gap: 10px;
    
    .svg-detener-solicitud {
      display: flex;
      margin: 0;
      font-size: 30px;
      cursor: pointer;
    }

    .label-detener-solicitud {
      font-family: $fontFamilyInter;
      font-size: $fontSizeMid;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 5px;
    }
  }
}

.content-modal-anular-documento {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-table-restaurar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.anular-documento {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .table-restaurar {
    text-align: start;
    border-collapse: collapse;
    overflow-x: auto;
    
    .thead-table-restaurar {
      align-items: center;
      .tr-table-restaurar {
        background: #D9E1EA;
        border-bottom: 1px dashed #ccc; 

        &.anular-documento {
          background: #E5E5E5;
        }

        .td-table-restaurar {
          padding: 0px;
          height: 0px;
          font-family: $fontFamilyInter;
          font-size: $fontSizeMid;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 125% */
          height: 46px;
        }
      }
    }

    .tbody-table-restaurar {
      align-items: center;

      .tr-table-restaurar {
        background: #fdfdfd;

        .td-table-restaurar {
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 8px;
          font-family: $fontFamilyInter;
          font-size: $fontSizeMid;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          &.anular-documento { 
            height: 46px;
            padding: 0px;

            input {
              border-radius: 0px;
            }
          }

          &.red {
            color: $colorNaranjo;
          }

          &.green {
            color: #06a502;
          }

          .content-icon-table {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;

            .button-solicitar-candidato {
              display: flex;
              justify-content: center;
              flex-direction: row;
              background: transparent;
              margin: 0;
              border: none;
              align-items: center;
              padding: 4px 8px;
              font-family: $fontFamilyInter;
              font-size: $fontSizeLarge;
              font-weight: 700;
              line-height: 25px;
              gap: 10px;
              cursor: pointer;

              &.solicitar {
                border-radius: 8px;
                color: #fafafa;
                border: 2px solid $botonAmarillo;
                background: $botonAmarillo;

                &:hover {
                  background: #fafafa;
                  border: 2px solid $botonAmarillo;
                  color: $botonAmarillo;
                }
              }

              &.confirm {
                border-radius: 8px;
                color: #fafafa;
                border: 2px solid $botonVerde;
                background: $botonVerde;

                &:hover {
                  background: #fafafa;
                  border: 2px solid $botonVerde;
                  color: $botonVerde;
                }
              }

              &.disabled {
                background: $botonDisabled;
                cursor: not-allowed;
                color: #fafafa;
              }

              svg {
                display: flex;
                margin: 0;
                font-size: 24px;
              }
            }

            .sol-enviada {
              color: #005bc7;
              text-align: center;
              font-family: $fontFamilyInter;
              font-size: $fontSizeMid;
              font-style: italic;
              font-weight: 400;
              line-height: 19px;
            }

            .button-delete-table-restaurar {
              display: flex;
              margin: 0;
              align-items: center;
              background: $botonRojo;
              border: 2px solid $botonRojo;
              padding: 0px 4px;
              border-radius: 8px;
              cursor: pointer;

              svg {
                display: flex;
                margin: 0;
                font-size: 33px;
                color: #fdfdfd;
              }

              &:hover {
                background: $botonRojoHover;
                svg {
                  color: $botonRojo;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-container-seleccion {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #1e1e1ece;
  z-index: 13;
  overflow-y: scroll;

  .modal-clear-box {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 13;
  }

  &.edit {
    overflow-y: scroll;
    padding: 30px 0px;
  }

  &.filtro {
    overflow-y: none;
    position: fixed;
    padding: 30px 0px;
    background-color: transparent;
  }

  .modal-content-seleccion {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: #fafafa;
    padding: 48px 71px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 24px;
    margin: auto;
    gap: 16px;
    z-index: 14;

    &.filtro {
      background: #f5f5f5;
      padding: 48px 90px;
      border-radius: 1px;
      top: 40%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -0%);
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    }

    &.w-90 {
      width: 90%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 95%;
        padding: 32px 18px;
        gap: 10px;
      }
    }

    &.w-80 {
      width: 80%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 96%;
        padding: 32px 14px;
        gap: 10px;
      }
    }

    &.w-70 {
      width: 70%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 14px;
        gap: 10px;
      }
    }

    &.w-60 {
      width: 60%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 80%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    &.w-45 {
      width: 45%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 60%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 18px;
      }
    }

    &.w-80-modal-asignar {
      width: 80%;
      @media (min-width: 700px) and (max-width: 1100px) {
        width: 90%;
        padding: 32px 18px;
      }
      @media (max-width: 700px) {
        width: 90%;
        padding: 32px 14px;
        gap: 10px;
      }
    }

    .modal-content-message {
      max-width: 100%;
      background-color: #fafafa;
      padding: 48px 46px;
      border: 1px solid #888;
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      border-radius: 24px;
      margin: auto;
    }

    .modal-close {
      display: flex;
      justify-content: end;
      color: #aaa;
      margin: -20px -20px -10px -10px;
      svg {
        display: flex;
        margin: 0;
        font-size: 30px;
        color: #48566b;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        margin: -10px 0px -5px -5px;
      }
    }

    .modal-close:hover,
    .modal-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    .modal-title {
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;
      padding: 1px;

      &.start {
        text-align: start;
      }

      &.filter {
        padding: 0px 0px 40px 0px;
      }

      @media (min-width: 280px) and (max-width: 1100px) {
        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .modal-subtitle {
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: $fontSizeMid;
      line-height: 20px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    .modal-message {
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: $fontSizeMid;
      line-height: 20px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    .modal-message-restaurar {
      display: flex;
      flex-direction: column;
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    .modal-message-bottom {
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #262626;
      &.start {
        text-align: start;
      }
    }

    /** 
    * TODO: Tabla ficha bien perron 
    */

    .separador-seccion {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      border-bottom: 1px solid #dedede;
    }

    .area-table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: -12px 0px;
      span {
        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 700;
        font-size: $fontSizeMid;
        line-height: 20px;
        color: #404040;
      }
      svg {
        display: flex;
        margin: 0;
        justify-content: center;
        align-items: center;
        font-size: 34px;
        color: #404040;
        transition: all 0.3s ease;
        &.disabled {
          transform: rotate(180deg);
          transition: all 0.3s ease;
        }
      }
    }

    .seccion-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .span-title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: #3b6597;
      }
      svg {
        display: flex;
        margin: 0;
        justify-content: center;
        align-items: center;
        font-size: 44px;
        color: #3b6597;
        transition: all 0.3s ease;
        &.disabled {
          transform: rotate(180deg);
          transition: all 0.3s ease;
        }
      }
    }

    .seccion-title-masiva {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .seccion-masiva {
        display: flex;
        width: 100%;
        align-items: center;

        .label-title {
          width: 25%;
          padding: 8px;
          background: #d9e1ea;
          border: 1px solid #b0c1d5;
          color: #404040;
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        .label-subtitle {
          width: 70%;
          padding: 8px;
          border: 1px solid #b0c1d5;
          color: #404040;
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        svg {
          display: flex;
          width: 5%;
          margin: 0;
          justify-content: center;
          align-items: center;
          font-size: 44px;
          color: #3b6597;
          transition: all 0.3s ease;
          &.disabled {
            transform: rotate(180deg);
            transition: all 0.3s ease;
          }
        }
      }
    }

    .project-table {
      display: flex;
      justify-content: center;
      gap: 15px;
      flex-direction: column;
      &.disabled {
        display: none;
      }

      .content-subtitle {
        display: flex;
        justify-content: flex-start;
        vertical-align: middle;
        flex-direction: column;
        gap: 10px 0px;
        div {
          display: flex;
          justify-content: flex-start;
          vertical-align: middle;
          flex-direction: column;
          flex-direction: row;
          gap: 10px;
          .subtitle {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            text-transform: uppercase;
            color: #002d63;
          }
          .sub-subtitle {
            font-family: "Inter";
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            color: #a3a3a3;
          }
        }
        .linea {
          border-bottom: 2px dashed #dedede;
        }
      }

      .container-input {
        display: flex;
        width: 100%;
        flex-direction: column;
        .content-input {
          display: flex;
          justify-content: start;
          align-items: end;
          gap: 40px 40px;
          margin-bottom: 30px;

          &.input-number {
            margin-bottom: 16px;
          }

          .content-select-modal-update,
          .content-text-modal-update {
            display: flex;
            flex-direction: row;
            width: 100;
            &.end {
              justify-content: end;
            }
          }

          .button-delete {
            display: flex;
            justify-content: center;
            margin: 0;
            background: $colorNaranjo;
            border-radius: 8px;
            padding: 4px 8px;
            align-items: center;
            border: 2px solid $colorNaranjo;
            width: 50px;
            height: 42px;
            cursor: pointer;

            svg {
              display: flex;
              background: transparent;
              margin: 0;
              color: $fondoTw;
              font-size: 32px;
              fill: 8px;
            }

            &:hover {
              background: $fondoTw;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              border: 2px solid $colorNaranjo;
              svg {
                color: $colorNaranjo;
              }
            }
          }
        }
      }

      .process-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        gap: 30px;
        margin-bottom: 10px;

        .section-asignar {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 18px;

          .button-add {
            display: flex;
            justify-content: end;
            align-items: end;
            margin: 0;
            background: transparent;
            border: none;

            .svg-add {
              display: flex;
              margin: 0;
              font-size: 40px;
              cursor: not-allowed;
              fill: $botonDisabled;

              &.disabled {
                cursor: not-allowed;
                fill: $botonDisabled;
              }

              &.active {
                cursor: pointer;
                fill: $botonAzul;
              }
            }
          }
        }

        .div-button {
          display: flex;
          justify-content: center;
          align-items: end;

          .button-accept {
            display: flex;
            min-width: 120px;
            justify-content: center;
            align-items: center;
            padding: 6px 24px;
            gap: 10px;
            border-radius: 8px;
            font-family: $fontFamilyInter;
            font-style: normal;
            font-weight: 700;
            font-size: $fontSizeLarge;
            line-height: 25px;
            cursor: pointer;

            &.cancel {
              color: $fondoTw;
              border: 2px solid #005bc7;
              background: #005bc7;

              &:hover {
                border: 2px solid #005bc7;
                background: $fondoTw;
                color: #005bc7;
              }

              svg {
                display: flex;
                margin: 0;
                font-size: 22px;
              }
            }

            &.disabled {
              background: $botonDisabled;
              border: 2px solid $botonDisabled;
              cursor: not-allowed;
              color: $colorDisabled;
            }
          }
        }
      }

      .container-grilla {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 50px;

        .grilla-content {
          display: flex;
          justify-content: center;
          vertical-align: middle;
          flex-wrap: wrap;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          min-height: 45px;

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            vertical-align: middle;
            flex-basis: calc(100% / 4);
            flex-direction: row;
            padding: 10px 12px;
            font-size: 16px;
            border: 1px solid #b0c1d5;
            background: #d9e1ea;
            &.xcuatro {
              flex-basis: calc(100% / 4);
            }
          }

          .message {
            display: flex;
            flex-basis: calc((100% / 4) * 3);
            flex-direction: row;
            align-items: center;
            font-size: 16px;
            padding: 10px 12px;
            border: 1px solid #b0c1d5;
            &.xcuatro {
              flex-basis: calc(100% / 4);
            }
          }
        }
      }
    }

    .seccion-table-asignar {
      display: flex;
      justify-content: center;

      .table-asignar {
        text-align: start;
        border-collapse: collapse;
        width: 80%;
        overflow-x: auto;

        .thead-table-asignar {
          align-items: center;
          .tr-table-asignar {
            background: #d9e1ea;
            .td-table-asignar {
              padding: 5px;
              height: 0px;
            }
          }
        }

        .tbody-table-asignar {
          align-items: center;
          .tr-table-asignar {
            background: #fdfdfd;
            .td-table-asignar {
              align-items: center;
              text-align: center;
              justify-content: center;
              height: 65px;

              .content-icon-table {
                display: flex;
                justify-content: center;
                align-items: center;

                .button-delete-table-asignar {
                  display: flex;
                  margin: 0;
                  align-items: center;
                  background: $botonRojo;
                  border: 2px solid $botonRojo;
                  padding: 0px 4px;
                  border-radius: 8px;
                  cursor: pointer;
                  height: 40px;

                  svg {
                    display: flex;
                    margin: 0;
                    font-size: 44px;
                    color: #fdfdfd;
                  }

                  &:hover {
                    background: $botonRojoHover;
                    svg {
                      color: $botonRojo;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.seccion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .span-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #3b6597;
  }
  svg {
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    font-size: 44px;
    color: #3b6597;
    transition: all 0.3s ease;
    &.disabled {
      transform: rotate(180deg);
      transition: all 0.3s ease;
    }
  }
}

.seccion-title-masiva {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .seccion-masiva {
    display: flex;
    width: 100%;
    align-items: center;

    .label-title {
      width: 25%;
      padding: 8px;
      background: #d9e1ea;
      border: 1px solid #b0c1d5;
      color: #404040;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .label-subtitle {
      width: 70%;
      padding: 8px;
      border: 1px solid #b0c1d5;
      color: #404040;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    svg {
      display: flex;
      width: 5%;
      margin: 0;
      justify-content: center;
      align-items: center;
      font-size: 44px;
      color: #3b6597;
      transition: all 0.3s ease;
      &.disabled {
        transform: rotate(180deg);
        transition: all 0.3s ease;
      }
    }
  }
}

.project-table {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-direction: column;
  &.disabled {
    display: none;
  }

  .content-subtitle {
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;
    flex-direction: column;
    gap: 10px 0px;
    div {
      display: flex;
      justify-content: flex-start;
      vertical-align: middle;
      flex-direction: column;
      flex-direction: row;
      gap: 10px;
      .subtitle {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        color: #002d63;
      }
      .sub-subtitle {
        font-family: "Inter";
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        color: #a3a3a3;
      }
    }
    .linea {
      border-bottom: 2px dashed #dedede;
    }
  }

  .container-input {
    display: flex;
    width: 100%;
    flex-direction: column;

    &.psicolaboral {
      flex-direction: row;
    }

    .content-input {
      display: flex;
      justify-content: start;
      align-items: end;
      gap: 40px 40px;
      margin-bottom: 30px;

      &.input-number {
        margin-bottom: 16px;
      }

      .content-select-modal-update,
      .content-text-modal-update {
        display: flex;
        flex-direction: row;
        width: 100;
        &.end {
          justify-content: end;
        }
      }

      .button-delete {
        display: flex;
        justify-content: center;
        margin: 0;
        background: $colorNaranjo;
        border-radius: 8px;
        padding: 4px 8px;
        align-items: center;
        border: 2px solid $colorNaranjo;
        width: 50px;
        height: 42px;
        cursor: pointer;

        svg {
          display: flex;
          background: transparent;
          margin: 0;
          color: $fondoTw;
          font-size: 32px;
          fill: 8px;
        }

        &:hover {
          background: $fondoTw;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
          border: 2px solid $colorNaranjo;
          svg {
            color: $colorNaranjo;
          }
        }
      }
    }
  }

  .process-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 10px;

    .section-asignar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 18px;

      .button-add {
        display: flex;
        justify-content: end;
        align-items: end;
        margin: 0;
        background: transparent;
        border: none;

        .svg-add {
          display: flex;
          margin: 0;
          font-size: 40px;
          cursor: not-allowed;
          fill: $botonDisabled;

          &.disabled {
            cursor: not-allowed;
            fill: $botonDisabled;
          }

          &.active {
            cursor: pointer;
            fill: $botonAzul;
          }
        }
      }
    }

    .div-button {
      display: flex;
      justify-content: center;
      align-items: end;

      .button-accept {
        display: flex;
        min-width: 120px;
        justify-content: center;
        align-items: center;
        padding: 6px 24px;
        gap: 10px;
        border-radius: 8px;
        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 700;
        font-size: $fontSizeLarge;
        line-height: 25px;
        cursor: pointer;

        &.cancel {
          color: $fondoTw;
          border: 2px solid #005bc7;
          background: #005bc7;

          &:hover {
            border: 2px solid #005bc7;
            background: $fondoTw;
            color: #005bc7;
          }

          svg {
            display: flex;
            margin: 0;
            font-size: 22px;
          }
        }

        &.disabled {
          background: $botonDisabled;
          border: 2px solid $botonDisabled;
          cursor: not-allowed;
          color: $colorDisabled;
        }
      }
    }
  }

  .container-grilla {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 50px;

    .grilla-content {
      display: flex;
      justify-content: center;
      vertical-align: middle;
      flex-wrap: wrap;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      min-height: 45px;

      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        vertical-align: middle;
        flex-basis: calc(100% / 4);
        flex-direction: row;
        padding: 10px 12px;
        font-size: 16px;
        border: 1px solid #b0c1d5;
        background: #d9e1ea;
        &.xcuatro {
          flex-basis: calc(100% / 4);
        }
      }

      .message {
        display: flex;
        flex-basis: calc((100% / 4) * 3);
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        padding: 10px 12px;
        border: 1px solid #b0c1d5;
        &.xcuatro {
          flex-basis: calc(100% / 4);
        }
      }
    }
  }
}
