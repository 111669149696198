.modal-container {
  display: inline-flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #1e1e1ece;
  justify-content: center;
  vertical-align: middle;
  align-items: center;

  .modal-content {
    background-color: #fafafa;
    margin: 5%;
    padding: 34px;
    border: 1px solid #888;
    width: 40%;
    position: relative;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 24px;
  }

  .modal-close {
    color: #48566B;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    font-weight: bold;
    margin: 2rem;

    svg {
      color:#48566B;
      font-size: 30px;
      font-weight: 700;
      &:hover {
        color: #242424;
      }
    }
  }

  .modal-close:hover,
  .modal-close:focus {
    color: #242424;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #262626;
    padding: 20px 40px;
  }

  .modal-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #262626;
    margin-bottom: 30px;
  }

  .modal-message {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #262626;
    margin-bottom: 30px;
  }

  .button-content-modal {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin: 20px 0px 0px 0px;

    .button-modal-back {
      display: flex;
      width: 40%;
      height: 41px;
      justify-content: center;
      align-items: center;
      padding: 4px;
      gap: 4px;
      background: #fafafa;
      border: 2px solid #005bc7;
      border-radius: 8px;
      color: #005bc7;
      font-weight: 700;

      &:hover {
        color: #005bc7;
        background: #fafafa;
        border: 2px solid #005bc7;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        cursor: pointer;
      }
    }

    .button-modal-go {
      display: flex;
      width: 40%;
      height: 41px;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      font-weight: 700;
      background: #06a502;
      border: 2px solid #06a502;
      border-radius: 8px;
      color: #fafafa;
      &:hover {
        background: #fafafa;
        color: #06a502;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        cursor: pointer;
        border-radius: 8px;
      }
    }

    .button-modal-danger {
      display: flex;
      width: 40%;
      height: 41px;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      font-weight: 700;
      background: #D32F2F;
      border: 2px solid #D32F2F;
      border-radius: 8px;
      color: #fafafa;
      &:hover {
        background: #fafafa;
        color: #D32F2F;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        border-radius: 8px;
      }
    }
  }
}

// ----------------------------------------------------- //
// ---------------------- 960px ------------------------ //
// ----------------------------------------------------- //
@media (max-width: 960px) {
  .modal-container {
    width: 100%;
    height: 100vh;

    .modal-content {
      padding: 36px;
      width: 90%;
    }

    .modal-title {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;
      margin-bottom: 30px;
    }

    .modal-subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      margin-bottom: 30px;
    }

    .modal-message {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      margin-bottom: 30px;
    }
  }
}

// ----------------------------------------------------- //
// ---------------------- 480px ------------------------ //
// ----------------------------------------------------- //
@media (max-width: 480px) {
  .modal-container {
    width: 100%;
    height: 100vh;

    .modal-content {
      margin: 15% auto;
      padding: 30px;
      width: 90%;
    }

    .modal-title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 20px;
    }

    .modal-subtitle {
      font-style: normal;
      margin-bottom: 20px;
    }

    .modal-message {
      font-style: normal;
      margin-bottom: 20px;
    }
  }
}
