::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #D4D4D4;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #FAFAFA;
}

.container-admin-assist {
  background-color: #fafafa;
  width: 100%;
  display: flex;
  justify-content: center;

  .content-admin-assist {
    width: 90%;
    padding: 10px 5px;
    margin: 20px 10px;

    .nav-tag {
      display: flex;
      justify-content: start;
      align-items: start;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #61738e;
    }

    .title {
      display: flex;
      justify-content: start;
      align-items: start;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      color: #262626;
      margin: 10px 0px;
    }

    .sub-title {
      display: flex;
      justify-content: start;
      align-items: start;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #404040;
      margin: 10px 0px 5px 0px;
    }

    .line-separate {
      width: 100%;
      margin: 0px;
      border-bottom: 4px dotted #d9e1ea;
    }

    .content-new-service {
      display: flex;
      width: 100%;
      height: 50px;
      margin: 20px 0px;
      justify-content: space-between;
      align-items: center;
      background: #d9e1ea;
      border-radius: 8px 8px 0px 0px;
      cursor: pointer;
      span {
        display: flex;
        justify-content: start;
        margin-left: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #48566b;
      }
    }

    .content-items {
      display: flex;
      align-items: center;
      text-align: center;
      box-sizing: border-box;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 100%;
      gap: 20px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: #262626;
      }

      .required::after {
        margin-left: 5px;
        content: "(*):";
        color: #262626;
      }

      .div-inputs {
        display: flex;
        justify-content: space-between;
        flex: auto;
      }

      .table-process {
        width: 100%;
        text-align: center;

        thead {
          th {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            padding: 25px;
            color: #404040;
            background: #f5f5f5;
            border: 0.5px solid #d4d4d4;
            border-radius: 0px;
          }
        }

        tbody {
          td {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            padding: 25px;
            color: #404040;
            background: #FAFAFA;
            border: 0.5px solid #d4d4d4;
            border-radius: 0px;

            .button-icon {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              vertical-align: middle;
              background: transparent;
              border: none;
              cursor: pointer;

              .svg-red {
                display: inline-block;
                margin: auto;
                background-color: transparent;
                color: #fe0000;
              }

              .svg-edit {
                display: inline-block;
                margin: auto;
                background-color: transparent;
                color: #404040;
              }
            }
          }
        }
      }
    }

    .title-table {
      display: flex;
      justify-content: start;
      align-items: start;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #404040;
      margin: 10px 0px 0px 30px;
    }

    .content-admin-filter {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      flex-flow: row wrap;
      color: #262626;
      margin: 10px 0px;

      .div-content-filter {
        width: 18%;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #262626;
        flex-flow: row wrap;
        span {
          margin-right: 3px;
          color: #262626;
        }
      }
    }

    .content-admin-action-massive {
      display: flex;
      justify-content: end;
      width: 100%;
      align-items: center;
      flex-flow: row wrap;

      .div-content-action-massive {
        width: 18%;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // REMPLAZO DE TABLA PARA COLAPSAR BIEN PERRON
    .project-table {
      display: flex;
      width: 100%;
      justify-content: center;
      box-sizing: border-box;
      flex-wrap: wrap;
      flex: 100%;
      border: 1px solid #d4d4d4;

      .content {
        display: flex;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        border: 1px solid #d4d4d4;
        flex-wrap: wrap;
        flex-direction: column;
        flex: auto;

        .project-table-title {
          display: flex;
          height: 45px;
          justify-content: center;
          align-items: center;
          padding: 10px;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #404040;
          background: #F5F5F5;
          border-bottom: 1px solid #D4D4D4;
        }

        .project-table-subtitle {
          display: flex;
          min-height: 40px;
          vertical-align: middle;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #404040;
          background: #FAFAFA;
        }
      }
      
    }

    ///////////////////// TABLESERVICE /////////////////////
    ///////////////////// TABLESERVICE /////////////////////

    .table-process-service {
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      text-align: center;
      thead {
        tr {
          background: #f5f5f5;
          .td-process-service-title {
            height: 60px;
            font-style: normal;
            font-weight: 600;
            background: #f5f5f5;
            font-size: 14px;
            line-height: 20px;
            color: #404040;
            padding: 8px;
            border-radius: 0px;
          }
        }
      }

      tbody {
        .list-tr-table {
          td {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #aeaeae;
            background: #fafafa;
            padding: 6px;
            border-radius: 0px;
          }

          .active {
            color: #404040;
          }
        }

        .selected {
          td {
            background: #e2ebf9;
          }
        }
      }
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 52px;
      height: 25px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #61738e;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 4px;
      background-color: #fafafa;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #48566b;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }

    ///////////////////// BUTTON NEW SERVICE /////////////////////
    ///////////////////// BUTTON NEW SERVICE /////////////////////

    .content-button-confirm {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;

      .button-confirm {
        width: 30%;
        color: #fafafa;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        background: #ff4900;
        border: 2px solid #ff4900;
        border-radius: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        cursor: pointer;

        &:hover {
          color: #ff4900;
          background: #fafafa;
          border: 2px solid #ff4900;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        }
      }

      .button-disable {
        width: 30%;
        color: #fafafa;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        background: #aeaeae;
        border-radius: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        cursor: pointer;
      }
    }

    .container-filter {
      display: flex;
      justify-content: start;
      flex-direction: row;
      align-items: start;
      padding-top: 0px;
      padding-left: 0px;
      margin-bottom: 20px;

      .content-filter {
        display: flex;
        width: 100%;
        justify-content: start;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #262626;
        align-items: center;
        text-transform: capitalize;
        gap: 10px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          border: 0;
          background: none;
          padding: 0px;

          svg {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0px;
            font-size: 26px;
            color: #a3a3a3;
          }
        }
      }
    }

    .footer-table {
      padding: 13px 10px;
      width: 100%;
      background: #f5f5f5;
      display: flex;
      vertical-align: middle;
      align-items: center;
      gap: 10px;
      position: relative;
      margin-top: 5px;
      @media (max-width: 770px) {
        display: flex;
        padding: 4px;
        gap: 5px;
        position: static;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
      }

      .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;

        input {
          padding: 8px 6px;
          font-size: 14px;
          button {
            svg {
              width: 10px;
            }
          }
        }

        .pagination-text {
          display: flex;
          justify-content: center;
          flex-direction: row;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          @media (max-width: 770px) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .pagination-number {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        position: absolute;
        @media (max-width: 770px) {
          position: static;
        }

        .number-pagination {
          display: flex;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          justify-content: center;
          text-align: center;
          align-items: center;
          vertical-align: middle;
          color: #fafafa;
          text-transform: capitalize;
          background: #61738e;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          @media (max-width: 770px) {
            font-size: 12px;
            width: 22px;
            height: 22px;
          }
        }

        button {
          display: flex;
          background: none;
          margin: 0;
          border: none;
          padding: 0px;
          cursor: pointer;

          svg {
            display: flex;
            margin: 0;
            color: #61738e;
            font-size: 32px;
            padding: 0px;
            @media (max-width: 770px) {
              font-size: 22px;
            }

            &.doble {
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              @media (max-width: 770px) {
                font-size: 24px;
              }
            }
            &.disabled {
              color: #aeaeae;
            }
          }
          &.disabled {
            cursor: not-allowed;
            svg {
              color: #aeaeae;
            }
          }
        }
      }
    }
  }
}

// ----------------------------------------------------- //
// ---------------------- 960px ------------------------ //
// ----------------------------------------------------- //
@media (max-width: 960px) {
  .container-admin-assist {
    background-color: #fafafa;
    width: 100%;
    display: flex;
    justify-content: center;

    .content-admin-assist {
      width: 97%;
      padding: 5px;
      margin: 10px 5px;

      .nav-tag {
        display: flex;
        justify-content: start;
        align-items: start;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #61738e;
      }

      .title {
        display: flex;
        justify-content: start;
        align-items: start;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: #262626;
        margin: 10px 0px;
      }

      .sub-title {
        display: flex;
        justify-content: start;
        align-items: start;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #404040;
        margin: 10px 0px 5px 0px;
      }

      .line-separate {
        width: 100%;
        border-bottom: 4px dotted #d9e1ea;
      }

      .content-new-service {
        display: flex;
        width: 100%;
        height: 50px;
        margin: 20px 0px;
        justify-content: space-between;
        align-items: center;
        background: #d9e1ea;
        border-radius: 8px 8px 0px 0px;
        cursor: pointer;
        span {
          display: flex;
          justify-content: start;
          margin-left: 10px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #48566b;
        }
      }

      .content-items {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          color: #262626;
        }

        .required::after {
          margin-left: 5px;
          content: "(*):";
          color: #262626;
        }

        .table-process {
          width: 100%;
          text-align: center;
    
          thead {
            th {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              padding: 10px;
              border-radius: 0px;
            }
          }
    
          tbody {
            td {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              padding: 5px;
              border-radius: 0px;
              border: none;
    
              .button-icon {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                background: transparent;
                border: none;
                cursor: pointer;
    
                .svg-red {
                  display: inline-block;
                  margin: auto;
                  background-color: transparent;
                  color: #fe0000;
                }
    
                .svg-edit {
                  display: inline-block;
                  margin: auto;
                  background-color: transparent;
                  color: #404040;
                }
              }
            }
          }
        }
      }

      .title-table {
        display: flex;
        justify-content: start;
        align-items: start;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #404040;
        margin: 10px 0px 10px 30px;
      }

      .content-admin-filter {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        flex-flow: row wrap;

        .div-content-filter {
          width: 19%;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            margin-right: 3px;
          }
        }
      }

      .content-admin-action-massive {
        display: flex;
        justify-content: end;
        width: 100%;
        align-items: center;
        flex-flow: row wrap;
        margin: 10px 0px;

        .div-content-action-massive {
          width: 18%;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      ///////////////////// TABLESERVICE /////////////////////
      ///////////////////// TABLESERVICE /////////////////////

      .table-process-service {
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        text-align: center;

        thead {
          tr {
            background: #f5f5f5;
            .td-process-service-title {
              font-style: normal;
              font-weight: 600;
              background: #f5f5f5;
              font-size: 14px;
              line-height: 20px;
              color: #404040;
              padding: 4px;
              border-radius: 0px;
            }
          }
        }

        tbody {
          .list-tr-table {
            td {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #aeaeae;
              background: #fafafa;
              padding: 4px;
              border-radius: 0px;
            }

            .active {
              color: #404040;
            }
          }

          .selected {
            td {
              background: #e2ebf9;
            }
          }
        }
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 52px;
        height: 25px;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #61738e;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        background-color: #fafafa;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: #48566b;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }

      ///////////////////// BUTTON NEW SERVICE /////////////////////
      ///////////////////// BUTTON NEW SERVICE /////////////////////

      .content-button-confirm {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;

        .button-confirm {
          width: 30%;
          color: #fafafa;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          background: #ff4900;
          border: 2px solid #ff4900;
          border-radius: 8px;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: #ff4900;
            background: #fafafa;
            border: 2px solid #ff4900;
            box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
          }
        }

        .button-disable {
          width: 30%;
          color: #fafafa;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          background: #aeaeae;
          border-radius: 8px;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          cursor: pointer;
        }
      }
    }
  }
}

// ----------------------------------------------------- //
// ---------------------- 480px ------------------------ //
// ----------------------------------------------------- //
@media (max-width: 480px) {
  .container-admin-assist {
    background-color: #fafafa;
    width: 100%;
    display: flex;
    justify-content: center;
  
    .content-admin-assist {
      width: 100%;
      padding: 10px 5px;
      margin: 20px 10px;
  
      .footer-table {
        padding: 13px 10px;
        width: 100%;
        background: #f5f5f5;
        display: flex;
        vertical-align: middle;
        align-items: center;
        flex-direction: column-reverse;

        .pagination-number {
          display: flex;
          width: 50%;

          justify-content: center;
          align-items: center;
          vertical-align: middle;
          position: relative;

          .number-pagination {
            display: flex;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            justify-content: center;
            text-align: center;
            align-items: center;
            vertical-align: middle;
            color: #FAFAFA;
            text-transform: capitalize;
            background: #61738E;
            border-radius: 50%;
            width: 25px;
            height: 25px;
          }

          button {
            margin: -10px;
          }

          svg{
            color: #61738E;
            font-size: 34px;
          }
        }
      }
    }
  }
}
