.containter-groups {
  width: 92%;
  margin: auto;

  .title-group {
    h1 {
      color: #003d85;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      padding: 48px 0px 24px;
    }
  }

  .process-group,
  .stages-group,
  .allow-persons-groups {
    padding: 16px 0px;

    h2 {
      color: #005bc7;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .process-group {
    .item-process-collapse-hide {
      height: 61px;
      transition: height 0.2s ease-in-out;

      .button-collapse {
        svg {
          transform: rotate(0deg);
          transition: transform 0.2s ease-in-out;
        }
      }
    }

    .item-process-collapse-show {
      height: 109px;
      transition: height 0.2s ease-in-out;

      .button-collapse {
        svg {
          transform: rotate(90deg);
          transition: transform 0.2s ease-in-out;
        }
      }
    }

    .item-process {
      padding: 13px 20px;
      background: #efefef;
      box-shadow: 0px 1px 8px rgba(0, 101, 221, 0.08);
      border-radius: 8px;
      position: relative;
      overflow: hidden;

      .item-collapse-box {
        min-height: 51px;
        max-height: 109px;
        display: flex;
        vertical-align: middle;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;

        .item-process-top {
          display: flex;
          vertical-align: middle;
          align-items: center;
          padding-top: 10px;

          .priority_2 {
            background-color: #be2a2a;
          }

          .priority_1 {
            background-color: #ffb400;
          }

          .priority_0 {
            background-color: #53cc50;
          }

          .priority-process {
            border-radius: 50%;
            height: 14px;
            width: 14px;
            margin-right: 10px;
          }

          .title-process {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #333333;
          }
        }

        .item-process-bottom {
          display: flex;
          vertical-align: middle;
          align-items: center;
          gap: 26px;
          width: 95%;
          margin: auto;
          padding-top: 20px;

          label {
            color: #6f6f6f;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      .button-collapse {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        width: 109px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        cursor: pointer;
        border: transparent;
        background-color: transparent;

        svg {
          display: block;
          margin: auto;
          width: 25px;
          height: 25px;
          fill: #404040;
        }
      }

      &:not(:last-child) {
        margin-bottom: 11px;
      }
    }
  }

  .stages-group {
    .items-stages {
      display: flex;
      vertical-align: middle;
      align-items: center;
      gap: 64px;
      margin-top: 30px;

      .stage-selected {
        background: #003d85;
        color: #fff;
        font-weight: 700;
      }

      .stage-linked {
        background: #f5f5f5;
        color: #a3a3a3;

        &:hover {
          background-color: rgba(#003d85, 0.4);
          color: #fff;
          transition: all 0.2s ease-in-out;
        }
      }

      a {
        width: calc(100% / 3);
        display: flex;
        vertical-align: middle;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        border-radius: 8px;
        padding: 15px 0px;
        font-size: 16px;
        line-height: 20px;
        justify-content: center;
      }
    }
  }

  .explain-fastincome {
    padding: 20px 10px;
    margin-top: 30px;
    margin-bottom: 30px;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      text-align: center;
    }
  }

  .allow-persons-groups,
  .blocked-persons-groups {
    padding-top: 100px;
    padding-bottom: 100px;

    .header-table {
      padding: 19px 35px;
      background: #003d85;
      display: flex;
      vertical-align: middle;
      align-items: center;
      border-top-right-radius: 9px;
      border-top-left-radius: 9px;

      .title-table {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #fafafa;
      }
    }

    .footer-table {
      padding: 13px 10px;
      background: #e5f7e5;
      margin-top: 4px;
      display: flex;
      vertical-align: middle;
      align-items: center;

      .pagination-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .button-action-alerts {
      background-color: #fff4d9;
    }

    .filters-table {
      display: flex;
      align-items: flex-start;
      vertical-align: middle;
      padding-top: 40px;
      padding-bottom: 40px;

      .cols-filter {
        width: calc(100% / 6);
      }

      .cols-filter-icon {
        width: 40px;
        height: 45px;
        display: flex;
        vertical-align: middle;
        align-items: center;

        img {
          display: block;
          margin: auto;
        }
      }
    }

    .actions-table {
      display: flex;
      vertical-align: middle;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 50px;
      padding-top: 10px;

      .q-regs {
        display: flex;
        vertical-align: middle;
        align-items: center;
        gap: 20px;
        padding: 0 20px;
        color: #6f6f6f;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }

      .menu-action-principal {
        button {
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
          border-radius: 8px;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          padding: 13px 60px;
          border: none;

          &:not(:disabled) {
            cursor: pointer;
            color: #fafafa;
            background: #ffb400;
          }

          &:disabled {
            color: #a3a3a3;
            background: #d4d4d4;
          }
        }
      }
    }

    .button-actions {
      background-color: #e5f7e5;
    }

    .button-action-blocked {
      background-color: rgba(#be2a2a, 0.3);
    }

    .button-actions,
    .button-action-alerts,
    .button-action-blocked {
      width: 37px;
      height: 37px;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      margin-right: 10px;
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
      position: relative;

      &:hover > .tooltip {
        display: flex;
      }

      &:not(:hover) > .tooltip {
        display: none;
      }

      .tooltip {
        position: absolute;
        top: 45px;
        align-items: center;
        text-align: center;
        background: #404040;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        z-index: 99;
        width: max-content;
        padding: 5px 10px;
        border-radius: 5px;
        color: #fff;
        opacity: 1;
        animation: fade-in 0.2s;
        left: -2px;

        i {
          position: absolute;
          width: 0;
          top: -17px;
          height: 0;
          left: calc(10px);
          border-right: 10px solid transparent;
          border-top: 10px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid #404040;
        }
      }

      img,
      svg {
        display: block;
        margin: auto;
      }
    }

    .table-allow-persons {
      width: 100%;

      thead {
        .rows_choise_multi {
          td {
            &:first-child {
              svg {
                fill: #0065dd;
              }
            }
          }
        }

        tr {
          td {
            border-bottom: 1px dashed #404040;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            padding-top: 23px;
            padding-bottom: 23px;

            &:first-child {
              text-align: center;
            }
          }
        }
      }

      tbody,
      thead {
        .checkbox-button {
          border: none;
          width: 35px;
          height: 35px;
          display: flex;
          vertical-align: middle;
          align-items: center;
          background-color: transparent;
          cursor: pointer;

          img {
            display: block;
            margin: auto;
            fill: #404040;
          }
        }
      }

      tbody {
        .rows_choise_multi {
          td {
            background-color: rgba(#0065dd, 0.1);

            &:first-child {
              svg {
                fill: #0065dd;
              }
            }
          }
        }

        tr {
          td {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px dashed #d4d4d4;

            .item-priority {
              width: 14px;
              height: 14px;
              border-radius: 50%;
              display: inline-flex;
              margin-right: 15px;
            }

            .priority_red {
              background-color: #be2a2a;
            }

            .priority_yellow {
              background-color: #ffb400;
            }

            .priority_green {
              background-color: #53cc50;
            }

            &:last-child {
              width: calc(37px * 6 + 10px * 6 + 50px + 10px);
            }

            &:first-child {
              text-align: center;
            }

            .button-especial {
              width: 50px;
              height: 33px;
              background: #0065dd;
              box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
              border-radius: 8px;
              border: none;
              cursor: pointer;
              display: inline-flex;
              vertical-align: middle;
              align-items: center;
              position: relative;
              margin-right: 10px;

              &:hover > .tooltip {
                display: flex;
              }

              &:not(:hover) > .tooltip {
                display: none;
              }

              .tooltip {
                position: absolute;
                top: 45px;
                align-items: center;
                text-align: center;
                background: #404040;
                font-weight: 500;
                font-size: 14px;
                line-height: 23px;
                z-index: 99;
                width: max-content;
                padding: 5px 10px;
                border-radius: 5px;
                color: #fff;
                opacity: 1;
                animation: fade-in 0.2s;
                right: -2px;

                i {
                  position: absolute;
                  width: 0;
                  top: -17px;
                  height: 0;
                  right: 15px;
                  border-right: 10px solid transparent;
                  border-top: 10px solid transparent;
                  border-left: 10px solid transparent;
                  border-bottom: 10px solid #404040;
                }
              }

              img {
                display: block;
                margin: auto;
              }
            }

            @keyframes fade-in {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          }

          .cols-skeleton {
            padding-right: 20px;
          }

          .col-actions {
            text-align: right;
          }

          &:hover {
            td {
              background-color: rgba(#e5f7e5, 0.7);
            }
          }
        }
      }
    }
  }
}
