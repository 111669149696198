@import "../_config";

.card-container {
  display: flex;
  justify-content: space-between;
  font-family: $fontFamilyInter;
  width: 100%;
  gap: 24px;

  .cards {
    display: flex;
    font-family: $fontFamilyInter;
    flex-direction: column;
    width: 100%;
    min-height: 185px;
    justify-content: space-between;
    position: relative;
    padding: 17px 8px 8px 13px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px #1d1d1d33;
  
    .content-etiqueta-title {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      align-content: center;
      gap: 16px;
  
      .numberCard {
        display: flex;
        color: #fafafa;
        font-family: $fontFamilyInter;
        font-weight: 700;
        font-size: 40px;
      }
  
      .textCard {
        display: flex;
        color: #fafafa;
        font-family: $fontFamilyInter;
        font-weight: 500;
        font-size: 14px;
        text-wrap: balance;
      }
    }
    .verTodo {
      color: #fafafa;
      font-family: $fontFamilyInter;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      align-items: end;
      text-align: right;
      text-decoration: underline;
      bottom: 0;
      right: 0;
      padding: 8px 10px;
      cursor: pointer;
    }
  
    &.card1 {
      background-color: #ffb400;
    }
    &.card2 {
      background-color: #0065dd;
    }
    &.card3 {
      background-color: #d32f2f;
    }
    &.card4 {
      background-color: #ff0081;
    }
    &.card5 {
      background-color: #ff4900;
    }
  }
}

@media (max-width: 767px) {
  .card-container {
    flex-direction: column;
  }
}
