.content-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px;

  .icon-loading {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.svg-loading {
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.content-loadingSmall {
  display: flex;
  max-width: 14px;
  position: absolute;
  justify-content: center;
  align-items: center;
  margin: 0px;

  .icon-loading {
    display: flex;
    max-width: 14px;
    position: absolute;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      display: flex;
      max-width: 14px;
      position: absolute;
      transform: rotate(360deg);
    }
  }
}

.container-is-error-table {
  display: flex;
  margin: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.container-icon-loading {
  display: flex;
  margin: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  animation: spin 2s linear infinite;
  background: transparent;

  .icon-loading {
    display: flex;
    margin: 0;
    font-size: 22px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
