.modal-container {
  display: inline-flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: #1e1e1ece;
  z-index: 9999;

  .modal-content{
    width: 40%;
    max-width: 100%;
    background-color: #fafafa;
    padding: 48px 46px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 24px;
    margin: auto;

    .modal-content-message {
      width: 40%;
      max-width: 100%;
      background-color: #fafafa;
      padding: 48px 46px;
      border: 1px solid #888;
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      border-radius: 28px;
      margin: auto;
    }

    .modal-close {
      color: #aaa;
      top: 0;
      right: 0;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      float: right;
    }

    .modal-close:hover,
    .modal-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    .modal-title {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;
      margin-bottom: 10px;
    }

    .modal-subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      margin-bottom: 10px;
    }

    .inputs-min {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px;

      input {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        width: 35px;
        height: 48px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        border: 1px solid #D4D4D4;
        border-radius: 8px;
        background: #FAFAFA;
        margin: 2px;
      }

      .disabled {
        color: #A3A3A3;
      }

      .label-point {
        font-size: 18px;
        font-weight: 700;
        margin: 0px 5px;
      }
    }

    .inputs-min-text {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px;

      .div-text {
        margin: 0px 18px;
      }
    }

    .modal-message {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #262626;
      margin-bottom: 0px;
    }

    .button-content {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      align-items: center;
  
      .button-back {
        display: flex;
        width: 40%;
        height: 41px;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        background: #005BC7;
        border: 1px solid #005bc7;
        border-radius: 8px;
        color: #FAFAFA;
        font-weight: 700;
  
        &:hover {
          background: #FAFAFA;
          border: 1px solid #005BC7;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
          border-radius: 8px;
          color: #005BC7;
        }
      }

      .button-out {
        display: flex;
        width: 40%;
        height: 41px;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        background: transparent;
        border-radius: 8px;
        border: transparent;
        color: #D32F2F;
        text-decoration: underline;
        font-weight: 700;
      }
  
      .button-go-confirm {
        display: flex;
        width: 40%;
        height: 41px;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        background: #06A502;
        border-radius: 8px;
        font-weight: 700;
        border-color: #028400;
  
        &:hover {
          color: #06A502;
          background: #FAFAFA;
          border: 2px solid #06A502;
          box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
          border-radius: 8px;
        }
      }
    }
  }
}

@media(max-width: 480px) {
  .modal-container {
  
    .modal-content{
      width: 90%;
    }

  }
}
