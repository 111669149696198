@import "../_config";

/**
* TODO: FOOTER TABLE BIEN PERRON
*/

.footer-table {
  padding: 12px 10px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  vertical-align: middle;
  align-items: center;
  gap: 10px;
  position: relative;
  margin-top: -10px;
  @media (max-width: 770px) {
    display: flex;
    padding: 4px;
    gap: 5px;
    position: static;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    input {
      padding: 8px 6px;
      font-size: 14px;
      button {
        svg {
          width: 10px;
        }
      }
    }

    .pagination-text {
      display: flex;
      justify-content: center;
      flex-direction: row;
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 600;
      font-size: $fontSizeMid;
      line-height: 20px;
      @media (max-width: 770px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .pagination-number {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: absolute;
    @media (max-width: 770px) {
      position: static;
    }

    .number-pagination {
      display: flex;
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      justify-content: center;
      text-align: center;
      align-items: center;
      vertical-align: middle;
      color: #fafafa;
      text-transform: capitalize;
      background: #61738e;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      @media (max-width: 770px) {
        font-size: 12px;
        width: 22px;
        height: 22px;
      }
    }

    button {
      display: flex;
      background: none;
      margin: 0;
      border: none;
      padding: 0px;
      cursor: pointer;

      svg {
        display: flex;
        margin: 0;
        color: #61738e;
        font-size: 32px;
        padding: 0px;
        @media (max-width: 770px) {
          font-size: 22px;
        }

        &.doble {
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          @media (max-width: 770px) {
            font-size: 24px;
          }
        }
        &.disabled {
          color: #aeaeae;
        }
      }
      &.disabled {
        cursor: not-allowed;
        svg {
          color: #aeaeae;
        }
      }
    }
  }
}
