/* General */
$fontSize:10px;
$borderRadius:12px;
$transitionDuration:.2s;

:root {
  --font-family: 'Roboto', sans-serif;
  --text-color:#333333;
  --text-color-secondary:#6c757d;
  --primary-color:#333333;
}
