//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
.p-disabled {
  background: #d5d5d5;
  opacity: 1;
}

.p-radiobutton-disabled {
  background: #d5d5d5;
}

.p-inputtext:disabled {
  background: #d5d5d5;
  opacity: 1;
}

.p-component {
  border-radius: 8px;
  font-size: 16px;
}

.p-button:disabled {
  border-radius: 8px;
}

.p-button:disabled {
  background-color: #d5d5d5;
  border: none;
  opacity: 1;
  border-radius: 8px;
}

.p-dialog .p-dialog-header {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 28px;
}

.p-dialog .p-dialog-footer {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

//
.p-button {
  background: #0065dd;
  border-radius: none;
}

.p-button:enabled:hover {
  background: #004494;
}

//.p-button.p-button-success{   background: #388a36;}

.p-button.p-button-success:enabled:hover {
  background: #388a36;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 30px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #0065dd;
  color: #ffffff;
  border-radius: 30px;
}

.filter_table_text {
  padding-top: 15px;
}

.filter_table_text_validar {
  padding-top: 15px;
  font-style: italic;
}

.toolbar_text {
  padding-top: 15px;
  margin-right: 200px;
}

.toolbar_text_right {
  padding-top: 15px;
  margin-right: 70px;
}

.p-togglebutton.p-button {
  font-size: 10px;
  box-shadow: none;
}

.p-togglebutton.p-button:focus {
  box-shadow: none;
}

.p-togglebutton.p-button.p-highlight {
  background: #ffb400;
  border: none;
  opacity: 1;
  font-size: 10px;
}

.p-togglebutton.p-button.p-highlight:hover {
  background: #e5a200;
  border: none;
  opacity: 1;
  font-size: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.p-inputgroup .p-inputtext,
.p-fluid .p-inputgroup .p-inputtext,
.p-inputgroup .p-inputwrapper,
.p-fluid .p-inputgroup .p-input {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.p-inputgroup-addon:first-child {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #0065dd;
  }
  40% {
    stroke: #ff7d49;
  }
  66% {
    stroke: #0065dd;
  }
  80%,
  90% {
    stroke: #ff7d49;
  }
}

::placeholder {
  color: #b3afab;
}

/* Table */
.p-datatable-table {
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.p-datatable .p-datatable-thead > tr > th {
  //border-radius: 10px;

  background-color: #f0f6ff;
}

// th:first-child {
//   border-top-left-radius: 10px;
// }

// th:last-child {
//   border-top-right-radius: 10px;
// }

// tr:last-child td {
//   //border-radius: 10px;
//   border-bottom-left-radius: 10px;
//   border-bottom-right-radius: 10px;
// }

.p-tooltip .p-tooltip-text {
  background-color: #333333;
}

.galleria-demo .custom-indicator-galleria .indicator-text {
  color: #e9ecef;
  cursor: pointer;
}
.galleria-demo .custom-indicator-galleria .p-highlight .indicator-text {
  color: var(--primary-color);
}

.p-galleria-item-next {
  display: none !important;
}

.p-galleria-item-prev {
  display: none !important;
}

.p-toolbar2 {
  border: none;
  padding: 0rem;
  background-color: #f8fcff;
}

.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 1.4rem;
}

.p-button-link:visited {
  color: red;
  background-color: rgb(194, 194, 39);
}

.p-button .p-button-link:visited {
  color: red;
  background-color: yellow;
}

.padding7y14 {
  padding: 7px 14px;
}

.margin0 {
  margin: 0;
}

.circleRed {
  background-color: #ff4900;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.circleYellow {
  background-color: #ffb400;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.circleGreen {
  background-color: #53cc50;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.inputTelefonoVacioNecesario {
  border-width: "1px";
  border-style: "solid";
  border-color: #d32f2f;
  border-left: white;
}

.inputVacioNecesario {
  border-width: "1px";
  border-style: "solid";
  border-color: #d32f2f;
}

.inputTelefonoVacioNoNecesario {
  border-width: "1px";
  border-style: "solid";
  border-color: #ffb400;
  border-left: white;
}

.inputVacioNoNecesario {
  border-width: "1px";
  border-style: "solid";
  border-color: #ffb400;
}

.inputTelefonoRelleno {
  border-width: "1px";
  border-style: "solid";
  border-color: #53cc50;
  border-left: white;
}

.inputRelleno {
  border-width: "1px";
  border-style: "solid";
  border-color: #53cc50;
}

.botonEnviarAValidarDisabled {
  background-color: #d5d5d5;
  border: #d5d5d5;
}

.botonEnviarAValidar {
  background-color: #ffb400;
  border: #ffb400;
}

.stickyFunction {
  padding: 5px;
  position: sticky;
  top: 70px;
  z-index: 2;
  background-color: #f8fcff;
}

.stickyFunctionSub {
  padding: 5px;
  position: sticky;
  top: 95px;
  z-index: 13;
  background-color: #f8fcff;
}

.inputD32F2FBorder {
  border: #d32f2f 2px solid;
  padding-right: 7px;
}

.input06A502Border {
  border: #06a502 2px solid;
}

.inputced4daBorder {
  border: 1px solid #ced4da;
}

.borderGreen {
  border-left: 18px solid #06a502;
}

.borderRed {
  border-left: 18px solid #d32f2f;
}

.borderOrange {
  border-left: 18px solid #ff4900;
}

//LeftSidebar
.sidebar {
  position: absolute;
  left: 0%;
  right: 95.17%;
  top: 0%;
  bottom: 0%;
  width: 3% !important;
}

//card
.p-card-title {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  margin-bottom: 0.5rem !important;
  color: #aeaeae !important;
}

//nombre de cargo
.p-card-subtitle {
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  margin-bottom: 0.5rem !important;
  color: #333333 !important;
  height: 35px;
  line-height: 1.3;
}

.p-card-body {
  height: 100%;
  width: 100%;
}

.circleUrgentPriority {
  background-color: #d32f2f;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.circleMediumPriority {
  background-color: #ffb400;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.circleFreePriority {
  background-color: #53cc50;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

//modal home restaurar

.titleHomeRestaurar {
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Roboto";
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
}

.bodyHomeModal {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  font-size: 15px;
  line-height: 17.58px;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

//modal error api
.errorModalBox {
  position: absolute;
  width: 454px;
  height: 260px;
  left: calc(50% - 454px / 2 - 8px);
  top: calc(50% - 260px / 2 - 38px);
  background: #ffffff;
  border-radius: 17px;
}

.errorModalTittle {
  font-family: "Roboto";
  font-weight: 600px;
  font-size: 24px;
  color: #333333;
  line-height: 28.13px;
}

.errorModalButton {
  font-family: "Roboto";
  font-weight: 500px;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  width: 190px;
  height: 38px;
  color: #ffffff;
  background-color: #2a63d5;
  border-radius: 8px;
}

//mensaje inhabilitado modal

.inhabilitadoBoxTittle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 23px;
  margin: "0";
  margin-left: 13px;
  margin-top: 5px;
}

.inhabilitadoBox {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 17, 58px;
  margin-left: 13px;
}

// quantity validar

.quantityStyle {
  font-family: "Roboto";
  font-weight: 600;
  font-family: "Roboto";
  font-style: italic;
  font-size: 13px;
  line-height: 34px;
}

.chkRed .p-checkbox-box {
  border-color: #ff4900 !important;
  background: #ff4900 !important;
}

.chkRed .p-checkbox-icon {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #eef2ff !important;
  color: #495057 !important;
}

//colores para boton subir archivos

.btn > .p-button {
  background: #398149 !important;
  border: none;
}

//material-icon
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
.material-symbols-outlined.md-40 {
  font-size: 40px;
  color: #53cc50;
}
.material-symbols-outlined.md-40.error {
  font-size: 40px;
  color: #d32f2f;
}

//material-icon
.material-symbols-outlined.md-18 {
  font-size: 18px;
}
.material-symbols-outlined.md-24 {
  font-size: 24px;
}
.material-symbols-outlined.md-36 {
  font-size: 36px;
}
.material-symbols-outlined.md-48 {
  font-size: 48px;
}
.material-symbols-outlined.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-symbols-outlined.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}
.material-symbols-outlined.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-symbols-outlined.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

.alignIcon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

//acordeon principal

.accordionMain
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #d8e6fb;
  border-color: #dee2e6;
  color: #333333;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordionMain .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  color: #333333;
  background: #d8e6fb;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}
.accordionMain .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  color: #333333;
  background: #d8e6fb;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}

.accordionMain
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: #d8e6fb;
  border-color: #d8e6fb;
  color: #333333;
}

//acordeon candidatos

.accordionCandidates
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #013776;
  border-color: #013776;
  color: #ffffff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordionCandidates .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #013776;
  color: #ffffff;
  background: #013776;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}
.accordionCandidates .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #013776;
  color: #ffffff;
  background: #013776;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}

.accordionCandidates
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: #013776;
  border-color: #013776;
  color: #ffffff;
}

.acciones-reclutarporgrupos {
  input[type=text] {
    padding: 5px 4px;
  }
}

.p-button.p-upload {
  background-color: transparent;
  color: #398149;
  border-color: #398149;
}

// subtitulos barra accordeon
.postulantesInfo {
  color: rgb(255, 255, 255);
  font-family: "Roboto";
  font-size: 15px;
}

.postulantesCantidad {
  color: rgb(255, 255, 255);
  font-family: "Roboto", "sans-serif";
  font-size: 13px;
  font-style: italic;
}
