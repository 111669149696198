.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 12.4px 2rem 2rem 4rem;
    transition: margin-left $transitionDuration;
}

.layout-home-container {
    display: flex;
    background: #0065dd;
    height: 100vh;
    overflow:unset;
}

.layout-main {
    flex: 1 1 auto;
}

.cardHome{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.flex{
    display: flex;
}

.divTexto{
    width: 100%;
    text-align: center;
    height: 16px;
}

.textHeader{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 51px;
    color: #FFFFFF;
}



.divImagen{
    height: '344px';
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 24px 0;
}

.imagen{
    height: '344px';
}

.textFooter{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 51px;
    color: #FFFFFF;
}

.displayComplete{
    display: flex;
    justify-content: center;
    align-items: center;
}

.displayFlexRowReverse{
    display: flex;
    flex-direction: row-reverse;
}

.marginPaddingBorder0{
    margin:0;
    padding:0;
    border: 0;
}

.marginTop34px{
    margin-top: 34px;
    
}

.backgroundColorBlanco{
    background-color: #FFFFFF;
}

.color333333{
    color: #333333;
}

.descartarCandidatoPor{
    width: 156px;
    height: 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: right;

    /* Primarios/Borrar */

    color: #D32F2F;
}

.teamworkSubrayado{
    width: 76px;
    height: 19px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    align-items: center;
    text-align: center;
    text-decoration-line: underline;

    /* Primarios/Textos */

    color: #333333;
}

.teamworkNoSubrayado{
    width: 76px;
    height: 19px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    align-items: center;
    text-align: center;
    text-decoration-line: underline;

    /* Primarios/Textos */

    color: #333333;
}

.indicacionDelCandidato{
    width: 152px;
    height: 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;

    color: #333333;
}

.paddingLadosA{
    padding: 0 20px;
}

.marginBottom32px{
    margin-bottom: 32px;
}

.marginright{
    margin-right:390px;
}