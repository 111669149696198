@import "../_config";

.modal-option {
  display: flex;
  background: #fafafa;
  box-shadow: 0px 4px 8px 0px rgba(29, 29, 29, 0.2);
  position: absolute;
  flex-direction: column;
  min-width: 200px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 2px;
  left: 0;
  top: 70px;
  z-index: 9999;
  
  &:hover {
    display: block;
  }
  
  .option {
    color: var(--texto-submenu-desactivo, #a3a3a3);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background: transparent;
    border: none;
    text-wrap: balance;
    cursor: pointer;

    &.active {
      color: var(--hover-70061738-e-hover, #61738e);
      text-decoration-line: underline;
    }
  }
}

.with-menu {
  position: relative;
  height: 80px;
  display: flex;
  vertical-align: middle;
  align-items: center;

  &:hover > .modal-option {
    display: flex;
  }

  &:not(:hover) > .modal-option {
    display: none;
  }
}