@import "../_config";

/** 
* TODO: TABLA FICHA POSTULACIONES CODIGO QR 
*/

.modal-ficha-postulacion {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .content-table-row {
    display: flex;
    vertical-align: center;
    align-items: center;
    width: calc(100% / 1);
    &.dos {
      @media (min-width: 280px) and (max-width: 1100px) {
        width: calc(100% / 1);
        flex-direction: row;
      }
    }
    &.tres {
      width: 31%;
      gap: 45px;
      @media (min-width: 280px) and (max-width: 1100px) {
        width: calc(100% / 1);
        flex-direction: row;
      }
    }
    &.cuatro {
      width: 48%;
      gap: 45px;
      @media (min-width: 280px) and (max-width: 1100px) {
        width: calc(100% / 1);
        flex-direction: row;
      }
    }

    &.documento {
      width: calc(100% / 2);
      @media (min-width: 280px) and (max-width: 1100px) {
        width: calc(100% / 1);
        flex-direction: row;
      }
    }

    .title-table-row {
      display: flex;
      justify-content: start;
      align-items: center;
      min-height: 45px;
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 16px;
      background: $colorFichaPostulacion;
      border: 1px solid $colorFichaPostulacion;
      width: 50%;

      @media (max-width: 700px) {
        width: 100%;
        font-size: 14px;
        min-height: 40px;
        line-height: 20px;
        padding: 4px;
      }

      &.dos {
        width: 70%;
        @media (max-width: 700px) {
          width: 100%;
        }
      }
      &.tres {
        width: 56%;
        @media (min-width: 500px) {
          width: 100%;
        }
      }
    }

    .subtitle-table-row {
      display: flex;
      justify-content: start;
      align-items: center;
      min-height: 45px;
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      padding: 10px 20px;
      border: 1px solid #b0c1d5;
      width: 50%;
      @media (max-width: 700px) {
        width: 100%;
        font-size: 14px;
        min-height: 40px;
        line-height: 20px;
        padding: 4px;
      }
      &.dos {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 30%;
        @media (min-width: 280px) and (max-width: 1100px) {
          gap: 10px;
        }
        .button-plus {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          background: none;
          border: none;
          cursor: pointer;
          svg {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
          }
        }
      }
    }
  }

  .separador-seccion {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    border-bottom: 1px solid #dedede;
  }

  .area-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: -12px 0px;
    cursor: pointer;
    padding-left: 24px;

    span {
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #404040;
    }
    svg {
      display: flex;
      margin: 0;
      justify-content: center;
      align-items: center;
      font-size: 34px;
      color: #404040;
      transition: all 0.3s ease;
      &.disabled {
        transform: rotate(180deg);
        transition: all 0.3s ease;
      }
    }
  }

  .project-table {
    display: flex;
    width: 100%;

    &.disabled {
      display: none; // desactivar
      animation: fade-out 0.2s forwards;
    }

    @keyframes fade-out {
      to {
        display: none;
        opacity: 0;
      }
    }

    .container-table {
      width: 100%;
      display: flex;
      vertical-align: middle;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px 0px;

      &.documentacion {
        gap: 4px 0px;
      }

      @media (max-width: 700px) {
        gap: 6px 0px;
      }

      &.container-table2 {
        width: 100%;
        display: flex;
        vertical-align: middle;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px 30px;

        @media (max-width: 700px) {
          gap: 6px 0px;
        }
      }

      &.contratos {
        display: flex;
        width: 100%;
        justify-content: space-between;
        vertical-align: middle;
        align-items: center;
        gap: 8px 0px;

        @media (max-width: 700px) {
          gap: 6px 0px;
        }
      }

      .title {
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-family: $fontFamilyInter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        padding: 4px;

        &.documento {
          margin-top: 10px;
        }

        @media (max-width: 700px) {
          font-size: 15px;
        }
      }

      .content-table {
        display: flex;
        flex-direction: column;
        border: 1px solid #b0c1d5;
        @media (min-width: 1100px) {
          width: calc(100% / 4);
        }
        @media (min-width: 700px) and (max-width: 1100px) {
          width: calc(100% / 2);
        }
        @media (max-width: 700px) {
          width: calc(100% / 1);
        }

        &.uno {
          @media (min-width: 1100px) {
            width: calc(100% / 1);
          }
          @media (min-width: 700px) and (max-width: 1100px) {
            width: calc(100% / 1);
          }
          @media (max-width: 700px) {
            width: calc(100% / 1);
          }
        }

        &.dos {
          @media (min-width: 1100px) {
            width: calc(100% / 2);
          }
          @media (min-width: 700px) and (max-width: 1100px) {
            width: calc(100% / 2);
          }
          @media (max-width: 700px) {
            width: calc(100% / 1);
          }
        }

        &.tres {
          @media (min-width: 1100px) {
            width: calc(100% / 3);
          }
          @media (min-width: 700px) and (max-width: 1100px) {
            width: calc(100% / 2);
          }
          @media (max-width: 700px) {
            width: calc(100% / 1);
          }
        }

        &.cuatro {
          @media (min-width: 1100px) {
            width: 22%;
            &.ending {
              width: 34%;
            }
          }
          @media (min-width: 700px) and (max-width: 1100px) {
            width: calc(100% / 2);
          }
          @media (max-width: 700px) {
            width: calc(100% / 1);
          }
        }

        &.cinco {
          @media (min-width: 1100px) {
            width: calc(100% / 5);
          }
          @media (min-width: 700px) and (max-width: 1100px) {
            width: calc(100% / 2);
          }
          @media (max-width: 700px) {
            width: calc(100% / 1);
          }
        }

        .subtitle-content-age {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
        }

        .title-table {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 45px;
          font-family: $fontFamilyInter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          padding: 10px 0px;
          background: $colorFichaPostulacion;
          border-bottom: 1px solid $colorFichaPostulacion;
          @media (max-width: 700px) {
            font-size: 14px;
            min-height: 40px;
            line-height: 20px;
            padding: 4px;
          }
        }

        .subtitle-table {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 45px;
          font-family: $fontFamilyInter;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          border: none;
          text-align: center;
          @media (max-width: 700px) {
            font-size: 14px;
            min-height: 40px;
            line-height: 20px;
            padding: 4px;
          }
          &.cinco {
            background: #fdfdfd;
            border-left: 1px solid $colorFichaPostulacion;
            width: 25%;
            gap: 1px;
            &:first-child {
              border-left: none;
            }
            &:nth-child(2) {
              background: $colorFichaPostulacion;
            }
            &:nth-child(4) {
              background: $colorFichaPostulacion;
            }
          }
        }
      }

      .content-table-row-data {
        display: flex;
        width: 100%;
        flex-direction: row;
        @media (min-width: 280px) and (max-width: 1100px) {
          flex-direction: column;
        }
      }

      .iconSquare {
        padding-right: 30px;
        align-content: center;
        align-items: center;
        @media (min-width: 280px) and (max-width: 1100px) {
          width: calc(100%);
          justify-content: center;
          display: flex;
          padding-top: 10px;
        }
      }

      .content-table-row {
        display: flex;
        vertical-align: center;
        align-items: center;
        width: calc(100% / 1);

        &.dos {
          @media (min-width: 280px) and (max-width: 1100px) {
            width: calc(100% / 1);
            flex-direction: row;
          }
        }
        &.tres {
          width: 31%;
          gap: 45px;
          @media (min-width: 280px) and (max-width: 1100px) {
            width: calc(100% / 1);
            flex-direction: row;
          }
        }
        &.cuatro {
          width: 48%;
          gap: 45px;
          @media (min-width: 280px) and (max-width: 1100px) {
            width: calc(100% / 1);
            flex-direction: row;
          }
        }

        &.documento {
          width: calc(100% / 2);
          @media (min-width: 280px) and (max-width: 1100px) {
            width: calc(100% / 1);
            flex-direction: row;
          }
        }

        &.documento-modal {
          display: flex;
          flex-wrap: wrap;
          gap: 4px 0px;
          @media (min-width: 280px) and (max-width: 1100px) {
            width: calc(100% / 1);
            flex-direction: row;
          }
        }

        .content-document-colaborator {
          display: flex;
          width: 50%;
          flex-direction: row;
          height: 50px;
        }

        .title-table-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          align-items: center;
          min-height: 45px;
          font-family: $fontFamilyInter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          padding: 10px 16px;
          background: $colorFichaPostulacion;
          border: 1px solid $colorFichaPostulacion;
          width: 50%;
          border: 1px solid #b0c1d5;

          @media (max-width: 700px) {
            width: 100%;
            font-size: 14px;
            min-height: 40px;
            line-height: 20px;
            padding: 4px;
          }

          &.dos {
            width: 70%;
            @media (max-width: 700px) {
              width: 100%;
            }
          }

          &.documento {
            width: 70%;
            padding: 8px;
            @media (max-width: 700px) {
              width: 100%;
            }
          }
          &.tres {
            width: 56%;
            @media (min-width: 500px) {
              width: 100%;
            }
          }
        }

        .subtitle-table-row {
          display: flex;
          justify-content: start;
          align-items: center;
          min-height: 45px;
          font-family: $fontFamilyInter;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          padding: 10px 20px;
          border: 1px solid #b0c1d5;
          width: 50%;
          @media (max-width: 700px) {
            width: 100%;
            font-size: 14px;
            min-height: 40px;
            line-height: 20px;
            padding: 4px;
          }
          &.dos {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 30%;
            @media (min-width: 280px) and (max-width: 1100px) {
              gap: 10px;
            }
            .button-plus {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0;
              background: none;
              border: none;
              cursor: pointer;
              svg {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
              }
            }
          }
        }
      }

      .content-table-children {
        display: flex;
        flex-direction: row;

        @media (min-width: 1100px) {
          width: calc(100% / 6);
        }
        @media (min-width: 700px) and (max-width: 1100px) {
          width: calc(100% / 3);
        }
        @media (max-width: 700px) {
          width: calc(100% / 2);
        }

        &.cargas {
          @media (min-width: 1100px) {
            width: calc(100% / 5);
          }
          @media (min-width: 700px) and (max-width: 1100px) {
            width: calc(100% / 3);
          }
          @media (max-width: 700px) {
            width: calc(100% / 2);
          }
        }

        &.columna {
          @media (min-width: 1100px) {
            width: calc(100% / 1);
          }
          @media (min-width: 700px) and (max-width: 1100px) {
            width: calc(100% / 1);
          }
          @media (max-width: 700px) {
            width: calc(100% / 1);
          }
        }

        .title-table-children {
          display: flex;
          justify-content: center;
          vertical-align: middle;
          align-items: center;
          flex-basis: 40%;
          font-family: $fontFamilyInter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          background: #fafafa;
          border: 1px solid #b0c1d5;
          @media (max-width: 700px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .subtitle-table-children {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;

          div {
            flex-basis: 50%;
            display: flex;
            justify-content: center;
            vertical-align: middle;
            align-items: center;
            font-family: $fontFamilyInter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            padding: 10px 20px;
            background: #fafafa;
            border: 1px solid #b0c1d5;
            @media (max-width: 700px) {
              font-size: 14px;
              line-height: 20px;
              padding: 4px;
            }
            &:first-child {
              background: #dadada;
              border: 1px solid #b0c1d5;
            }
          }
        }
      }

      .content-button-plus {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .button-plus {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          background: none;
          border: none;

          svg {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            font-size: 34px;
          }
        }
      }

      .container-area-contratos {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        gap: 0px 30px;

        .content-area-contratos {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          gap: 0px 30px;

          .section-title {
            display: flex;
            width: 200px;
            padding: 8px 4px 8px 16px;
            justify-content: start;
            align-items: center;
            flex-shrink: 0;
            border: 1px solid #b0c1d5;
            background: #d9e1ea;

            label {
              color: #404040;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
