@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/**
* TODO: COLORES TW PLATAFORMA REDISEÑO SELECCION
*/
$fondoTw: #FFFFFF;
$whiteTw: #FFFFFF;
$blancoBasico: #FAFAFA;
$negroTabla: #404040;
$negroTitle: #262626;
$negroTw: #262626;
$azulFlick: #0065DD; 
$azulOscuro: #002D63; 
$colorFilter: #B0CFF4; 
$colorAmarillo: #E3A000;
$colorDisabled: #FDFDFD;
$colorVerde: #06A502;
$colorRosado: #FF0081;
$colorGris: #48566B; 
$colorVerdeOscuro: #327A30;
$colorNaranjo: #FF4900;
$colorRojo: #D32F2F;
$colorSubtitleDashboard: #005BC7;
$colorFichaPostulacion: #D9E1EA; 
$colorTitleModalUpdate: #3b6597; 

/**
* TODO: COLORES TOAST
*/
$botonVerdeToast: #06A502;
$botonRojoToast: #BE2A2A;
$botonNaranjoToast: #FF4900;
$botonRosadoToast: #ff0081;

/**
* TODO: COLORES BOTONES
*/
$botonAzul: #0065DD;
$botonAzulOscuro: #374151;
$botonAzulRut: #005BC7;
$botonAzulRutHover: #003d85;
$botonVerde: #06A502;
$botonRojo: #BE2A2A;
$botonNaranja: #FF4900;
$botonAyuda: #E30075;
$botonGris: #48566B;
$botonCancelar: #FDFDFD;
$botonAmarillo: #E3A000;
$botonDisabled: #D4D4D4; 

$botonAzulHover: #002D63;
$botonVerdeHover: #FDFDFD;
$botonRojoHover: #FDFDFD;
$botonNaranjaHover: #FDFDFD;
$botonAyudaHover: #FDFDFD;
$botonGrisHover: #FDFDFD;
$botonCancelarHover: #FDFDFD;
$botonAmarilloHover: #FDFDFD;

/**
* fuentes a ocupar
*/
$fontSizeTitle: 25px;
$fontSizeLarge: 18px;
$fontSizeMid: 16px;
$fontSizeTable: 15px;
$fontSizeSmall: 14px;
$fontSizeXSmall: 11.57px;
$fontFamilyInter: "Inter";
$borderRadius: 12px;
$borderRadiusButton: 8px;
$transitionDuration: .2s;

/**
* mediasqueries 
*/
$media-query-sm: 576px;
$media-query-md: 768px;
$media-query-lg: 992px;
$media-query-xl: 1200px;