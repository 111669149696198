/////////////////// BUTTONADD ///////////////////
/////////////////// BUTTONADD ///////////////////
.button-add-assist {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background: transparent;
  border: none;
  cursor: pointer;
  svg {
    display: inline-block;
    margin: auto;
    background-color: transparent;
    color: #404040;
    font-size: 25px;
    &:hover {
      color: #bdbdbd;
    }
  }
}

/////////////////// DROPDOWN ///////////////////
/////////////////// DROPDOWN ///////////////////
.dropdown-assist {
  display: flex;
  background-color: #fafafa;
  padding: 10px;
  width: 100%;
  border-radius: 8px;
}

.input-select {
  width: 80%;
  padding: 0.5rem 0.5rem;
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 8px;
}

.input-date {
  border-radius: 8px;
  width: 90%;
  padding: 0.5rem 0.5rem;
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  font-size: 16px;
}

.text-input {
  color: #495057;
  background: #ffffff;
  max-height: 45px;
  border-radius: 10px;
  border: 1px solid #a2a2a2;
  font-size: 18px;

  &:focus { 
    outline: none;
  }
}

/////////////////// BUTTONICON ///////////////////
/////////////////// BUTTONICON ///////////////////
.button-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background: transparent;
  border: none;
  cursor: pointer;
  gap: 10px;

  &:focus {
    outline: none;
  }

  svg {
    display: inline-block;
    margin: auto;
    background-color: transparent;
  }
}

.button-filter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background: transparent;
  border: none;
  cursor: pointer;
  gap: 10px;

  &:disabled {
    cursor:auto; 
    &:hover {
      border-radius: 50%;
    }
  }

  &:focus {
    outline: none;
  }

  svg {
    display: inline-block;
    margin: auto;
    background-color: transparent;

    &:disabled {
      cursor: none;
    }

    &:hover {
      background-color: #E5E5E5;
      border-radius: 50%;
    }

    &:active {
      transform: scale(0.98);
      border-radius: 50%;
      background-color: #FAFAFA;
      border: 2px solid #242424;
    }
  }
}
