@import "../ui/_config";

/** 
* TODO: Modal botones varios; disabled, cancel, confirm, delete, etc...
*/

.modal-button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  gap: 30px;

  &.document-modalupdate {
    margin-top: 0px;
    gap: 10px;
  }

  &.center {
    justify-content: space-around;
  }

  &.center-seleccion {
    gap: 30px;
    justify-content: center;
  }

  &.registro-individual {
    gap: 57px;
    justify-content: center;
  }

  &.separados {
    justify-content: space-between;
  }

  &.contratacion {
    gap: 30px;
    justify-content: center;
  }

  &.end {
    justify-content: end;
  }

  &.start {
    justify-content: start;
  }

  &.filter {
    padding-top: 50px;
    justify-content: end;
    @media (max-width: 700px) {
      padding-top: 1px;
      justify-content: center;
    }
  }

  &.modal-button-content-in-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    gap: 32px;
    &.center {
      justify-content: center;
    }

    &.end {
      justify-content: end;
    }

    &.filter {
      padding-top: 50px;
      justify-content: end;
      @media (max-width: 700px) {
        padding-top: 1px;
        justify-content: center;
      }
    }
  }

  .modal-buttonModal {
    display: flex;
    min-width: 160px;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    gap: 10px;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    cursor: pointer;

    @media (min-width: 350px) and (max-width: 1150px) {
      min-width: 96px;
    }
    @media (max-width: 344px) {
      min-width: 96px;
    }

    &.cancel {
      color: #005bc7;
      border: 2px solid #005bc7;
      background: $blancoBasico;

      &:hover {
        border: 2px solid #005bc7;
        background: $blancoBasico;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
    }

    &.confirm {
      color: $blancoBasico;
      border: 2px solid #06a502;
      background: #06a502;

      &:hover {
        background: $blancoBasico;
        color: #06a502;
        border: 2px solid #06a502;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
    }
  }

  .tooltip-custom {
    background-color: $blancoBasico;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: $blancoBasico;
  }

  .modal-button {
    display: flex;
    min-width: 240px;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    gap: 10px;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin: 16px 0px;
    cursor: pointer;
    transition-duration: 0.2s;
    transition-timing-function: ease;

    &.registro-individual {
      border-radius: 100%;
      min-width: 45px;
      min-height: 45px;
    }

    @media (min-width: 700px) and (max-width: 1100px) {
      min-width: 190px;
    }
    @media (max-width: 700px) {
      min-width: 140px;
    }

    &.cancel {
      color: #005bc7;
      border: 2px solid #005bc7;
      background: $blancoBasico;

      &.contratacion {
        min-width: 250px;
      }

      &:hover {
        border: 2px solid #005bc7;
        background: $blancoBasico;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: $blancoBasico;
        cursor: not-allowed;
      }
    }

    &.document {
      color: $blancoBasico;
      border: 2px solid $botonAzulOscuro;
      background: $botonAzulOscuro;
      svg {
        margin: 0;
      }

      &:hover {
        border: 2px solid $botonAzulOscuro;
        background: $botonAzulOscuro;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: $botonAzulOscuro;
        cursor: not-allowed;
      }
    }

    &.confirm {
      color: $blancoBasico;
      border: 2px solid $colorVerde;
      background: $colorVerde;

      &.contratacion {
        min-width: 250px;
      }

      &:hover {
        background: $blancoBasico;
        color: $colorVerde;
        border: 2px solid $colorVerde;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: $blancoBasico;
        cursor: not-allowed;
      }
    }

    &.option {
      color: $blancoBasico;
      border: 2px solid $azulFlick;
      background: $azulFlick;

      &.contratacion {
        min-width: 250px;
      }

      &:hover {
        background: $azulOscuro;
        color: $blancoBasico;
        border: 2px solid$azulOscuro;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: $blancoBasico;
        cursor: not-allowed;
      }
    }

    &.naranja {
      color: $blancoBasico;
      border: 2px solid $colorNaranjo;
      background: $colorNaranjo;

      &.contratacion {
        min-width: 250px;
      }

      &:hover {
        background: $blancoBasico;
        color: $colorNaranjo;
        border: 2px solid $colorNaranjo;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }

      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: $blancoBasico;
        cursor: not-allowed;
      }
    }

    &.delete {
      color: $blancoBasico;
      border: 2px solid $colorRojo;
      background: $colorRojo;

      &.contratacion {
        min-width: 250px;
      }

      &:hover {
        background: $blancoBasico;
        color: $colorRojo;
        border: 2px solid $colorRojo;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: $blancoBasico;
        cursor: not-allowed;
      }
    }

    &.delete-white {
      color: $colorRojo;
      border: 1px solid $colorRojo;
      background: $blancoBasico;

      &.contratacion {
        min-width: 250px;
      }

      &:hover {
        border: 1px solid $colorRojo;
        background: $blancoBasico;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: $blancoBasico;
        cursor: not-allowed;
      }
    }

    &.delete-registro-individual {
      color: $blancoBasico;
      border: 2px solid $colorRojo;
      position: relative;
      background: $colorRojo;
      svg {
        display: flex;
        position: absolute;
        width: 17px;
      }

      &:hover {
        background: $blancoBasico;
        color: $colorRojo;
        border: 2px solid $colorRojo;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        svg {
          fill: $colorRojo;
          color: $colorRojo;
        }
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: $blancoBasico;
        cursor: not-allowed;
      }
    }

    &.confirm-registro-individual {
      color: $blancoBasico;
      border: 2px solid $colorVerde;
      position: relative;
      background: $colorVerde;
      svg {
        display: flex;
        position: absolute;
        width: 17px;
      }
      &:hover {
        background: $blancoBasico;
        color: $colorVerde;
        border: 2px solid $colorVerde;
        box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
        svg {
          fill: $colorVerde;
          color: $colorVerde;
        }
      }
      &:disabled {
        background: #aeaeae;
        border: 2px solid #aeaeae;
        color: $blancoBasico;
        cursor: not-allowed;
      }
    }

    &.disabled {
      color: $blancoBasico;
      border: 2px solid #aeaeae;
      background: #aeaeae;
      cursor: not-allowed;

      &:hover {
        background: #aeaeae;
        color: $blancoBasico;
      }
    }
  }
}

.content-button-registro-individual {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  label {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 11.57px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }
}
