@import "../_config";

/** 
* TODO: NUEVAS FUNCIONALIDADES PARA LOS FILTROS
*/

.container-filter-group {
  display: flex;
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  z-index: 12;

  .modal-back-box {
    display: flex;
    position: fixed;
    background: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 13;
  }

  .content-filter-newdesign {
    display: flex;
    flex-direction: column;
    position: absolute;
    max-width: 100%;
    width: 100%;
    background-color: #fafafa;
    padding: 48px 46px;
    margin-bottom: 40px;
    border: 1px solid #888;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    border-radius: 1px;
    z-index: 15;

    @media (max-width: 700px) {
      padding: 48px 24px;
    }
    
    .container-title-filter {
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #262626;
      padding: 0px 0px 40px 0px;

      @media (max-width: 700px) {
        padding: 0px 0px 10px 0px;
      }
    }

    .section-filter-aplicate {
      display: flex;
      justify-content: space-between;
      align-items: end;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 40px;
      gap: 20px;

      @media (max-width: 700px) {
        margin-bottom: 0px;
      }

      .content-filter-aplicate {
        display: flex;
        justify-content: start;
        flex-flow: column wrap;
        gap: 25px;

        @media (max-width: 700px) {
          gap: 10px;
        }

        .title-filter {
          font-family: $fontFamilyInter;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #262626;
        }

        .content-button-filter-aplicate {
          display: flex;
          justify-content: start;
          gap: 10px;
          flex-wrap: wrap;

          .button-filter-aplicate {
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 4px 8px 4px 16px;
            height: 28px;
            background: #002d63;
            border: 1px solid #fafafa;
            gap: 10px;
            color: #fafafa;
            cursor: pointer;
            svg {
              display: flex;
              margin: 0;
              color: #fafafa;
              font-size: $fontSizeMid;
            }
          }
        }
      }

      .content-button-filter {
        display: flex;
        justify-content: end;
        align-items: end;

        .button-clear-left {
          display: flex;
          align-items: center;
          font-family: $fontFamilyInter;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          padding: 4px 8px 4px 16px;
          height: 28px;
          background: #fafafa;
          border: 2px solid #005bc7;
          gap: 10px;
          color: #005bc7;
          border-radius: 8px;
          cursor: pointer;
          svg {
            display: flex;
            margin: 0;
            color: #fafafa;
            font-size: $fontSizeMid;
          }

          &:hover {
            background: #005bc7;
            color: #fafafa;
            border: 2px solid #005bc7;
          }
        }
      }
    }

    .content-filter {
      display: flex;
      justify-content: start;
      align-items: center;
      vertical-align: middle;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;

      .section-filter {
        display: flex;
        width:calc(100% / 4 - 20px);
        min-height: 180px;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        flex-direction: column;
        border-left: 2px solid #dedede;
        border-right: 2px solid #dedede;
        padding: 0px 10px;
        gap: 10px;
        

        @media (max-width: 700px) {
          width: 100%;
          border-left: none;
          border-right: none;
          border-bottom: 2px solid #dedede;
          min-height: 125px;
          &:last-child {
            border-bottom: none;
          }
        }
       
        .title {
          display: flex;
          font-family: $fontFamilyInter;
          font-style: normal;
          font-weight: 500;
          font-size: $fontSizeMid;
        }

        .content-description-filter {
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 10px;

          @media (max-width: 700px) {
            flex-direction: row;
            gap: 10px;
          }
          section {
            display: flex;
            justify-content: center;
            vertical-align: middle;
            align-items: center;
            flex-direction: row;
            gap: 10px;
            @media (max-width: 700px) {
              width: 100%;
              flex-direction: row;
              gap: 8px;
            }
            .orden {
              display: flex;
              align-items: center;
              font-family: $fontFamilyInter;
              font-style: normal;
              font-weight: 400;
              font-size: 11.57px;
              gap: 10px;
            }
            svg {
              display: flex;
              margin: 0;
            }
          }
        }

        .content-input-filter {
          display: block;
          position: relative;
          width: 100%;
          margin-bottom: 20px;

          .content-filter-aplicate {
            display: flex;
            position: absolute;
            justify-content: start;
            flex-flow: column wrap;
            margin-top: 5px;
            gap: 25px;

            @media (max-width: 700px) {
              gap: 10px;
            }

            .title-filter {
              font-family: $fontFamilyInter;
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              color: #262626;
            }

            .content-button-filter-aplicate {
              display: flex;
              justify-content: start;
              gap: 10px;
              flex-wrap: wrap;

              .button-filter-aplicate {
                display: flex;
                justify-content: start;
                align-items: center;
                padding: 4px 8px 4px 16px;
                height: 28px;
                background: #002d63;
                border: 1px solid #fafafa;
                gap: 10px;
                color: #fafafa;
                cursor: pointer;
                svg {
                  display: flex;
                  margin: 0;
                  color: #fafafa;
                  font-size: $fontSizeMid;
                }
              }
            }
          }
        }

        .section-button {
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 5px;
          flex-direction: row;
          flex-wrap: wrap;

          .button-filter {
            display: flex;
            font-family: $fontFamilyInter;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            align-items: center;
            border: 1px solid #01326a;
            text-transform: capitalize;
            background: #f5f5f5;
            border-radius: 0px;
            color: #002d63;
            padding: 4px 25px 4px 16px;

            @media (max-width: 700px) {
              font-size: 11.57px;
              line-height: 15px;
            }
            &:hover {
              background: #002d63;
              color: #f5f5f5;
            }

            &.active {
              background: #002d63;
              color: #f5f5f5;
            }
          }
        }
      }
    }
  }
}


@media (min-width: 700px) {
  .content-filter-newdesign.filter-40{
    width: calc(40% - 0px) !important;
    right: 105px;
    top: 160px;
  }
  
  .content-filter.filter-40 .section-filter{
    width: 100% !important;
  }
}

@media (min-width: 1100px) {
  .content-filter.filter-40 .section-filter{
    width: 47% !important;
  }
}