/*LeftSidebar*/

.sidebar {
  position: absolute;
  left: 0%;
  right: 95.17%;
  top: 0%;
  bottom: 0%;
  width: 3% !important;
}

/*card*/

.p-card-title {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  margin-bottom: 0.5rem !important;
  color: #aeaeae !important;
}

/*nombre de cargo*/
.p-card-subtitle {
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  margin-bottom: 0.5rem !important;
  color: #333333 !important;
  height: 35px;
  line-height: 1.3;
}

.p-card-body {
  height: 100%;
  width: 100%;
}

.circleUrgentPriority {
  background-color: #d32f2f;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.circleMediumPriority {
  background-color: #ffb400;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.circleFreePriority {
  background-color: #53cc50;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

/*modal home restaurar*/

.titleHomeRestaurar {
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Roboto";
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
}

.bodyHomeModal {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  font-size: 15px;
  line-height: 17.58px;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

/*modal error api*/
.errorModalBox {
  position: absolute;
  width: 454px;
  height: 260px;
  left: calc(50% - 454px / 2 - 8px);
  top: calc(50% - 260px / 2 - 38px);
  background: #ffffff;
  border-radius: 17px;
}

.errorModalTittle {
  font-family: "Roboto";
  font-weight: 600px;
  font-size: 24px;
  color: #333333;
  line-height: 28.13px;
}

.errorModalButton {
  font-family: "Roboto";
  font-weight: 500px;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  width: 190px;
  height: 38px;
  color: #ffffff;
  background-color: #2a63d5;
  border-radius: 8px;
}

/*mensaje inhabilitado modal*/

.inhabilitadoBoxTittle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 23px;
  margin: "0";
  margin-left: 13px;
  margin-top: 5px;
}

.inhabilitadoBox {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 17, 58px;
  margin-left: 13px;
}

/*quantity validar*/

.quantityStyle {
  font-family: "Roboto";
  font-weight: 600;
  font-family: "Roboto";
  font-style: italic;
  font-size: 13px;
  line-height: 34px;
}

.chkRed .p-checkbox-box {
  border-color: #ff4900 !important;
  background: #ff4900 !important;
}

.chkRed .p-checkbox-icon {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #eef2ff !important;
  color: #495057 !important;
}

/*colores para boton subir archivos*/

.btn > .p-button {
  background: #398149 !important;
  border: none;
}

/*material-icon*/

.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
.material-symbols-outlined.md-40 {
  font-size: 40px;
  color: #53cc50;
}
.material-symbols-outlined.md-40.error {
  font-size: 40px;
  color: #d32f2f;
}
.material-symbols-outlined.md-40.icon {
  font-size: 40px;
  color: #06a502;
  border-radius: 50px;
}
.material-symbols-outlined.md-24.icon {
  font-size: 24px;
  color: #ffffff;
  background-color: #06a502;
  border-radius: 50px;
  padding: 5px;
}
.material-symbols-outlined.md-24.iconRed {
  font-size: 24px;
  color: #ffffff;
  background-color: #d32f2f;
  border-radius: 50px;
  padding: 5px;
}
.material-symbols-outlined.md-24.iconYellow {
  font-size: 24px;
  color: #ffffff;
  background-color: #ffb400;
  border-radius: 50px;
  padding: 5px;
}

.material-symbols-outlined.md-24.iconBlue {
  font-size: 24px;
  color: #2a63d5;
}

.material-symbols-outlined.md-24.iconCRed {
  font-size: 24px;
  color: #d32f2f;
}

.material-symbols-outlined.md-24.iconCYellow {
  font-size: 24px;
  color: #ffb400;
}

.material-symbols-outlined.md-36.iconCYellow {
  font-size: 36px;
  color: #ffb400;
}

.material-symbols-outlined.md-18.iconCCYellow {
  font-size: 18px;
  color: #ffb400;
}

.material-symbols-outlined.md-24.iconGray {
  font-size: 24px;
  color: #d5d5d5;
}

.material-symbols-outlined.md-24.iconCGreen {
  font-size: 24px;
  color: #06a502;
}

/*material-icon */

.material-symbols-outlined.md-18 {
  font-size: 18px;
}
.material-symbols-outlined.md-24 {
  font-size: 24px;
}
.material-symbols-outlined.md-36 {
  font-size: 36px;
}
.material-symbols-outlined.md-48 {
  font-size: 48px;
}
.material-symbols-outlined.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-symbols-outlined.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}
.material-symbols-outlined.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-symbols-outlined.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

.alignIcon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/*acordeon principal*/

.accordionMain
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #d8e6fb;
  border-color: #dee2e6;
  color: #333333;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordionMain .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  color: #333333;
  background: #d8e6fb;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}
.accordionMain .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  color: #333333;
  background: #d8e6fb;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}

.accordionMain
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: #d8e6fb;
  border-color: #d8e6fb;
  color: #333333;
}

/*acordeon candidatos*/

.accordionCandidates
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #013776;
  border-color: #013776;
  color: #ffffff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordionCandidates .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #013776;
  color: #ffffff;
  background: #013776;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}
.accordionCandidates .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #013776;
  color: #ffffff;
  background: #013776;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}

.accordionCandidates
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: #013776;
  border-color: #013776;
  color: #ffffff;
}

.p-button.p-upload {
  background-color: transparent;
  color: #398149;
  border-color: #398149;
}

/*subtitulos barra accordeon*/
.postulantesInfo {
  color: rgb(255, 255, 255);
  font-family: "Roboto";
  font-size: 15px;
}

/*acordeon rut prevencion*/

.accordionRutCandidates
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #013776;
  border-color: #013776;
  color: #ffffff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordionRutCandidates
  .p-accordion
  .p-accordion-header
  .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #013776;
  color: #ffffff;
  background: #013776;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}
.accordionRutCandidates
  .p-accordion
  .p-accordion-header
  .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #013776;
  color: #ffffff;
  background: #013776;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}

.accordionRutCandidates
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: #013776;
  border-color: #013776;
  color: #ffffff;
}
.accordionRutCandidates .p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #d8e6fb;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

/* prevetopbar*/

.prevetopbar .layout-topbar {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  background-color: #ffffff;
  transition: left 0.2s;
  display: flex;
  align-items: center;
}

.menu-stickyFunction2 {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.stickyFunction2 {
  position: sticky;
  top: -1.5px;
  z-index: 12;
  background-color: #ffffff;
}

.prevetopbar .p-toolbar {
  background: #ffffff;
  border: 0px solid;
  /* padding: 1rem; */
}

.prevetopbar .p-divider-horizontal {
  margin: 0px !important;
  padding: 0px !important;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #d8e6fb;
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.messageRut {
  font-size: 16px;
}

.loading .p-button:disabled {
  background-color: #2a63d5;
  border: none;
  opacity: 1;
  border-radius: 50px;
}

/* New container */

.layout-main-container-new {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.p-toolbar {
  background: #ffffff;
  padding: 1rem;
  border: none !important;
}

/* Accordeon Procesos Activos */

.accordionActiveProcess
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #013776;
  border-color: #013776;
  color: #ffffff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordionActiveProcess
  .p-accordion
  .p-accordion-header
  .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #013776;
  color: #ffffff;
  background: #013776;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}
.accordionActiveProcess
  .p-accordion
  .p-accordion-header
  .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #013776;
  color: #ffffff;
  background: #013776;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}

.accordionActiveProcess
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: #013776;
  border-color: #013776;
  color: #ffffff;
}

.circuloRed {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #d32f2f;
  border-color: black;
  border: 1px solid rgb(0, 0, 0);
}

.circuloRedNB {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #d32f2f;
}

.circuloGreen {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #53cc50;
  border-color: black;
  border: 1px solid rgb(0, 0, 0);
}

.circuloGreenNB {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #53cc50;
}

.circuloYellow {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffb400;
  border-color: black;
  border: 1px solid rgb(0, 0, 0);
}

.circuloYellow {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffb400;
}

.circuloGrey {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #aeaeae;
  border-color: black;
  border: 1px solid rgb(0, 0, 0);
}

.containerJobPosition {
  background-color: #efefef;
  border-radius: 4px;
}

.vacantantesJobMsg {
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  font-weight: 400;
}

.vacantantesMsg {
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

.colorTextVacantes {
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  color: white;
}

.colorTextAsigAct {
  color: white;
}

.colorTextVacantesName {
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  color: rgb(212, 202, 23);
}

.accordionActiveProcess .p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #d8e6fb;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.accordionActiveProcess .p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

/*Reclutamiento por grupo*/

.cardMenu .card {
  background-color: #eff6ff;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%),
    0px 1px 4px rgb(0 0 0 / 8%) !important;
}

.accordionCandidates .p-accordion .p-accordion-content {
  padding: 0rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.cardTest {
  background-color: #eff6ff;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%),
    0px 1px 4px rgb(0 0 0 / 8%) !important;
}

.container {
  background-color: #eff6ff;
  border-radius: 12px;
  padding: 10px;
}

.cardmodalInfoContact {
  color: #ffffff;
  background: #607d8b;
  border: 1px solid #607d8b;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

/* Accordeon Procesos Activos */

.tablasTitle {
  background-color: #eef5ff;
  border: 1px solid #d5d5d5;
}

.tablasInfo {
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
}

.tablasTitle2 {
  background-color: #d0ffcf;
  border: 1px solid #d5d5d5;
}

.tablaInfo {
  width: 100%;
}

.tablaInfo td {
  padding: 10px;
  border: 1px solid #d5d5d5;
}

.tablaInfo .tablasTitle {
  background-color: #eef5ff;
}

.tablaInfo .tablasInfo {
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
}

.tablaInfo tr:last-child td {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
/*Tabla  pasar Lista */

.p-radiobutton-box.p-highlight {
  border-color: #0065dd !important;
  background: #0065dd !important;
}
.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  transform: translateZ(0) scale(1, 1);
  visibility: hidden;
}

.pLista {
  height: 44px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #2a63d5;
}

/***************/
.p-button-icon-right {
  font-size: 1.4rem;
}

.css-i4bv87-MuiSvgIcon-root {
  margin-right: 10px;
}
.p-picklist .p-picklist-header {
  background-color: #d5d5d5;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
  font-weight: 600;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  font-size: 15px;
  line-height: 18px;
  align-items: center;
  text-align: center;
}

.p-picklist-transfer-buttons .p-button:disabled.p-component.p-button-icon-only {
  background: #d5d5d5;
}

.p-picklist-transfer-buttons
  .p-button.p-component.p-button-icon-only:nth-child(2) {
  background-color: #53cc50;
}

.p-picklist-transfer-buttons
  .p-button:not(.p-disabled).p-component.p-button-icon-only:first-child {
  background: #53cc50;
}

.p-picklist-source-controls {
  display: none !important;
}

.p-picklist-target-controls {
  display: none !important;
}

.p-radiobutton-box.p-highlight {
  border-color: #0065dd !important;
  background: #0065dd !important;
}
.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  transform: translateZ(0) scale(1, 1);
  visibility: hidden;
}

/* card nómina */

.containerField {
  display: flex;
  flex-direction: column;
  background-color: #eaeae5;
  padding: 1%;
  border: 1px solid #d5d5d5;
  border-radius: 12px;
  width: 33.3333%;
}

/* menu*/

#menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* items del menu */

/* enlaces del menu */

#menu ul a {
  display: block;
  color: #333333;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  font-family: "Roboto";
  background-color: white;
}

/* items del menu */

#menu ul li {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
}

/* efecto al pasar el ratón por los items del menu */

#menu ul ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
}

/* items del menu desplegable */

#menu ul ul li {
  float: none;
  width: 150px;
  z-index: 3;
}

/* enlaces de los items del menu desplegable */

#menu ul ul a {
  line-height: 120%;
  padding: 10px 15px;
}

/* items del menu desplegable al pasar el ratón */

#menu ul li:hover > ul {
  display: block;
}

/*border radios warning*/

.warningModal .p-dialog .p-dialog-header {
  border-top-right-radius: 15px;
  border-top-left-radius: none !important;
}

#panel1a-header .MuiAccordionSummary-contentGutters {
  display: block;
}

.tableAsistencia > tr:last-child > td {
  border-radius: 0px;
}
