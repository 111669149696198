@import "./_config";

.cols-required {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  .layer {
    &::after {
      content: "(*):";
      padding: 0px 5px;
    }
  }
}

.cols-not-required {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  .layer {
    &::after {
      padding: 0px 1px;
      content: ":";
    }
  }
}

.layer {
  display: flex;
  min-height: 36px;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: #262626;
  padding: 0px 5px 0px 0px;
  user-select: none;

  i {
    font-size: 14px;
    padding-left: 4px;
    text-transform: none;
    color: #a3a3a3;
    font-weight: normal;
  }
}

.layer-document {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: #262626;
  user-select: none;
  margin-bottom: 20px;
  // line-height: 10px;

  &.document {
    margin-bottom: auto;
    min-height: 36px;
  }

  i {
    font-size: 14px;
    padding-left: 4px;
    text-transform: none;
    color: #a3a3a3;
    font-weight: normal;
  }
}

.layer-error,
.layer-caution,
.layer-success {
  display: flex;
  vertical-align: middle;
  align-items: center;
  padding: 5px 0px;
  &::placeholder {
    color: #d32f2f; /* Cambia el color a rojo */
  }
  &.down {
    display: flex;
    position: absolute;
    gap: 4px;
    &::placeholder {
      color: #d32f2f; /* Cambia el color a rojo */
    }
  }

  &::disabled {
    background: #e9ebf8;
    color: #61738e;
    cursor: not-allowed;
  }
}

.layer-error {
  color: #d32f2f;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  position: absolute;
  &::placeholder {
    color: #d32f2f; /* Cambia el color a rojo */
  }
}

.layer-caution {
  color: #8f6500;
  svg {
    display: flex;
    margin: 0;
    color: #e3a000;
  }
}

.layer-success {
  color: #255c24;
  svg {
    display: flex;
    margin: 0;
    color: #06a502;
  }
}

.input-initial {
  border: 1px solid #a3a3a3;
  background-color: #fff;

  svg {
    fill: #262626;
  }

  &::placeholder {
    color: #a3a3a3;
  }

  &:hover {
    border: 1px solid #0065dd;
  }

  &:focus {
    border: 1px solid #0065dd;
    box-shadow: 0px 0px 0px 0.2rem rgba(4, 54, 154, 0.21);
    transition: all 0.2s ease-in-out;
  }

  &.disabled {
    background: #e5e5e5;
    color: #61738e;
    cursor: not-allowed;
    border: 1px solid #a3a3a3;
    &:hover {
      border: 1px solid #a3a3a3;
    }
  }

  &:disabled {
    background: #e5e5e5;
    color: #61738e;
    cursor: not-allowed;
    border: 1px solid #a3a3a3;
    &:hover {
      border: 1px solid #a3a3a3;
    }
  }
}

.input-success {
  border: 1px solid green;
  background-color: #fff;

  svg {
    fill: #262626;
  }

  &:focus {
    border: 1px solid green;
    box-shadow: 0px 0px 0px 0.2rem rgba(green, 0.21);
    transition: all 0.2s ease-in-out;
  }

  &.disabled {
    background: #e5e5e5;
    cursor: not-allowed;
    color: #61738e;
    border: 1px solid #a3a3a3;
    &:hover {
      border: 1px solid #a3a3a3;
    }
  }
}

.input-error {
  border: 1px solid #8d2020;
  color: #8d2020 !important;
  background-color: #ffb5a8;
  background: #ffb5a8;
  transition: all 0.2s ease-in-out;

  svg {
    fill: #d32f2f;
  }

  &::placeholder {
    color: #d32f2f;
    background: #feece5;
  }

  &.disabled {
    background: #e5e5e5;
    color: #61738e;
    cursor: not-allowed;
    border: 1px solid #a3a3a3;
    &:hover {
      border: 1px solid #a3a3a3;
    }
  }
}

.input-caution {
  border: 1px solid #8f6500;
  background: #fff2d6;
  transition: all 0.2s ease-in-out;
  &::placeholder {
    color: #8f6500; /* Cambia el color a rojo */
  }
}

input[type="text"] {
  padding: 10px 12px 10px 14px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #262626;
  width: 100%;
  display: flex;
  user-select: none;
  outline: none;

  &:disabled {
    background: #e9ebf8;
    &::placeholder {
      color: #61738e;
    }
    color: #61738e;
    border: 1px solid #61738e;
    cursor: not-allowed;
    &:svg {
      display: flex;
      justify-content: center;
      margin: 0;
    }
  }
}

input[type="number"] {
  padding: 10px 12px 10px 14px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #262626;
  width: 100%;
  display: flex;
  user-select: none;
  outline: none;

  &:disabled {
    background: #e9ebf8;
    &::placeholder {
      color: #61738e;
    }
    color: #61738e;
    border: 1px solid #61738e;
    cursor: not-allowed;
    &:svg {
      display: flex;
      justify-content: center;
      margin: 0;
    }
  }
}

.textarea-modal-candidate {
  padding: 10px 12px 10px 14px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #262626;
  width: 100%;
  display: block;
  user-select: none;
  outline: none;
  max-width: 100%;
  min-width: 100%;
  font-family: "Inter";
  resize: none;

  &:disabled {
    background: #e9ebf8;
    color: #61738e;
    cursor: not-allowed;
    &:svg {
      display: flex;
      justify-content: center;
      margin: 0;
    }
  }
}

.custom-file-input {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  position: relative;

  input[type="file"] {
    display: none;
  }

  .input-img {
    //padding: 10px; /* Ajusta según sea necesario */
    background-image: url("../../components/ui/icons-seleccion/Adjuntar.png"); //icons-seleccion/Adjuntar.png src\components\ui\icons-seleccion\Adjuntar.png
    //background-size: contain;
    background-repeat: no-repeat;
    background-position: right 10px center; //left center;
  }

  .label-file {
    display: flex;
    width: 100%;
    max-width: 100%;
    border-radius: 1px;
    font-weight: 400;
    font-size: 16px;
    user-select: none;
    outline: none;
    padding: 10px 25px 10px 14px;
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &:disabled {
      background: #e9ebf8;
      color: #61738e;
      cursor: not-allowed;
    }

    &.disabled {
      background: #e9ebf8;
      color: #61738e;
      border: 1px solid var(--borde-field-disable, #61738e);
      cursor: not-allowed;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    // &.error {

    // }
  }

  .layer-down {
    display: flex;
    position: absolute;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    color: #a3a3a3;
  }

  .button-file {
    display: flex;
    justify-content: center;
    margin: 0;
    align-items: center;
    padding: 5px 8px;
    background: #005bc7;
    border-radius: 12px;
    border: 1px solid #fafafa;
    cursor: pointer;

    svg {
      display: flex;
      font-size: 32px;
      margin: 0;
      color: #fafafa;
      background: transparent;
      transform: rotate(310deg);
    }

    &:hover {
      background: #003d85;
    }

    &.disabled {
      color: #61738e;
      background: #aeaeae;
      cursor: not-allowed;
    }
  }

  .button-download-file {
    display: flex;
    justify-content: center;
    margin: 0;
    align-items: center;
    padding: 5px 8px;
    background: #fafafa;
    border-radius: 12px;
    border: 2px solid #005bc7;
    cursor: pointer;
    svg {
      display: flex;
      font-size: 32px;
      margin: 0;
      color: #fafafa;
      background: transparent;
    }

    &:disabled {
      border: 2px solid #aeaeae;
      color: #61738e;
      cursor: not-allowed;
    }
  }
}

.leyenda-documento {
  display: flex;
  position: absolute;
  font-size: 13.17px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #a3a3a3;
  margin-top: 20px;
}

.box-number {
  display: flex;
  vertical-align: middle;
  align-items: center;
  width: 50px;
  gap: 16px;

  input[type="number"] {
    padding: 8px 2px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #262626;
    width: 30px;
    margin: auto;
    display: flex;
    user-select: none;
    outline: none;
    text-align: center;
    &::placeholder {
      color: #a3a3a3;
    }
  }

  .button-action-incre-decre {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    border: none;

    .svg-add {
      fill: #fff;
      display: block;
      margin: auto;
    }

    &:disabled {
      color: #61738e;
      background: #d5d5d5;
      .svg-remove {
        display: flex;
        justify-content: center;
        margin: 0;
        background: #d5d5d5;
        fill: #fafafa;
      }
    }

    &:not(:disabled) {
      background: #0065dd;
      cursor: pointer;
      &.button-remove {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        background: #fafafa;
        border: 3px solid #0065dd;
        .svg-remove {
          background: #fafafa;
          fill: #0065dd;
          display: block;
          margin: auto;
        }
      }
    }
  }
}

.ddl-customized-box-show {
  .arrow-list {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
    fill: #0065dd;
  }
}

.ddl-customized-box-hidden {
  .arrow-list {
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
  }
}

.dropdownlist-customized-box {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;

  .ddl-icons-options-list {
    position: absolute;
    z-index: 9;
    top: 0;
    right: 1px;
    height: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;

    .clear-box {
      width: 1.5px;
      height: 50%;
      background-color: rgb(170, 170, 170);
      border-radius: 2px;
    }

    .button-showhidden-list-option {
      border-radius: 8px;
    }

    .button-showhidden-list-option,
    .button-delete-list-options {
      width: 24px;
      display: flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      user-select: none;
      outline: none;
      background: transparent;

      .icon-delete-list-options,
      .arrow-list {
        display: block;
        margin: auto;
      }
      &.disabled {
        color: #61738e;
        background: transparent;
      }

      .input-error {
        background-color: #ffb5a8;
      }
    }

    .button-delete-list-options {
      .icon-delete-list-options {
        fill: #262626;
        width: 15px;
        background: transparent;
      }
    }

    .button-showhidden-list-option {
      .arrow-list {
        width: 25px;

        background: transparent;
      }
    }
  }

  .input-dropdownlist-customized {
    padding: 10px 35px 10px 10px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #262626;
    width: 100%;
    margin: auto;
    display: flex;
    outline: none;
    cursor: pointer;
    caret-color: transparent;
    user-select: none;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.disabled {
      background: #e9ebf8;
      color: #61738e;
      cursor: not-allowed;
    }
  }

  .apply-collapse-top {
    bottom: 44px;
  }

  .dropdownlist-customized-list {
    position: absolute;
    width: 100%;
    height: 200px;
    justify-content: center;
    align-items: center;
    z-index: 10;
    top: 42px;
    box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
    background-color: #fff;
    border-radius: 6px;
    animation: dropdownlist-fadein 0.2s;
    opacity: 1;
    transition-duration: 0.2s;
    transition-timing-function: ease;

    .ddl-customized-search {
      background-color: rgb(240, 240, 240);
      padding: 6px;
      position: relative;
      display: flex;
      align-items: center;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      .button-delete-search {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 12px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border: none;

        .icon-delete-search {
          display: block;
          margin: auto;
          width: 15px;
          cursor: pointer;
        }
      }

      .input-initial {
        background-color: #fff;
        transition-duration: 0.2s;
        transition-timing-function: ease;

        &:not(:hover) {
          border: 1px solid #a3a3a3;
        }

        svg {
          fill: #eee;
        }

        &:hover {
          border: 1px solid #0065dd;
          transition: border 0.2s ease-in-out;
        }

        &:focus {
          border: 1px solid #0065dd;
          box-shadow: 0px 0px 0px 0.2rem rgba(4, 54, 154, 0.21);
          transition: all 0.2s ease-in-out;
        }
      }

      .input-success {
        border: 1px solid green;

        button {
          background-color: #fff;
          svg {
            fill: green;
          }
        }

        &:focus {
          border: 1px solid green;
          box-shadow: 0px 0px 0px 0.2rem rgba(green, 0.21);
          transition: all 0.2s ease-in-out;
        }
      }

      .input-error {
        border: 1px solid #8d2020;
        color: #8d2020;
        background-color: #ffb5a8;
        transition: all 0.2s ease-in-out;

        button {
          background-color: #feece5;
          svg {
            fill: #d32f2f;
          }
        }

        &::placeholder {
          color: #d32f2f;
          background-color: #feece5;
        }
      }

      input {
        padding: 9px 9px 9px 13px;
        background: linear-gradient(0deg, #ffffff, #ffffff),
          linear-gradient(0deg, #a3a3a3, #a3a3a3);
        border-radius: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #262626;
        width: 100%;
        margin: auto;
        display: flex;
        outline: none;
        cursor: pointer;
        transition-duration: 0.2s;
        transition-timing-function: ease;

        &:disabled {
          background: #e5e5e5;
          color: #61738e;
          cursor: not-allowed;
          border: 1px solid #a3a3a3;
          &:hover {
            border: 1px solid #a3a3a3;
          }
        }
      }
    }

    .ddl-customized-overflow {
      height: calc(100% - 57px);
      overflow-y: scroll;
      overflow-x: hidden;

      .ddl-customized-list-option {
        padding: 10px;
        cursor: pointer;
        display: flex;
        vertical-align: middle;
        align-items: center;

        svg {
          fill: rgb(150, 150, 150);
        }

        &:last-child {
          border-bottom-left-radius: 6px;
        }

        &:hover {
          background-color: rgba(4, 54, 154, 0.21);
        }
      }

      .ddl-customized-list-option-selected {
        background-color: rgba(4, 54, 154, 0.21);

        svg {
          fill: #0065dd;
        }
      }

      .ddl-customized-option-not-data {
        padding: 20px;
        display: flex;
        vertical-align: middle;
        align-items: center;
      }

      &::-webkit-scrollbar {
        width: 8px;
        left: -20px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(175, 175, 175, 1);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgba(100, 100, 100, 1);
      }

      &::-webkit-scrollbar-thumb:active {
        background: rgba(100, 100, 100, 1);
      }

      .container::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-track:hover,
      &::-webkit-scrollbar-track:active {
        background: transparent;
      }
    }
  }
}

.box-datepicker {
  position: relative;
  display: flex;
  vertical-align: middle;
  align-items: center;

  svg {
    position: absolute;
    right: 3%;
    fill: rgb(100, 100, 100);
    width: 20px;
  }
}

// ----------------------------------------------------- //
// ---------------------- 480px ------------------------ //
// ----------------------------------------------------- //
@media (max-width: 480px) {
  .cols-required {
    .layer {
      &::after {
        content: "(*):";
        padding: 0px 5px;
      }
    }
  }

  .layer {
    display: flex;
    padding: 10px 5px 10px 0px;

    i {
      font-size: 14px;
      padding-left: 4px;
      text-transform: none;
      color: #a3a3a3;
      font-weight: normal;
    }
  }

  .layer-error,
  .layer-caution,
  .layer-success {
    display: flex;
    vertical-align: middle;
    align-items: center;
    padding: 10px;
  }

  input[type="text"] {
    padding: 10px 12px 10px 20px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #262626;
    width: 98%;
    display: flex;
    user-select: none;
    outline: none;

    &::placeholder {
      color: #a3a3a3;
    }
  }

  .box-number {
    display: flex;
    vertical-align: middle;
    align-items: center;
    width: 50%;
    gap: 0px;
    padding-left: 5px;
    padding-right: 5px;

    input[type="number"] {
      padding: 12px;
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      color: #262626;
      width: 40%;
      margin: auto;
      display: flex;
      user-select: none;
      outline: none;
      text-align: center;

      &::placeholder {
        color: #a3a3a3;
      }
    }
  }

  .dropdownlist-customized-box {
    width: 98%;
    position: relative;

    .ddl-icons-options-list {
      position: absolute;
      z-index: 9;
      top: 0;
      right: 1px;
      height: 100%;
      display: flex;
      vertical-align: middle;
      align-items: center;

      .clear-box {
        width: 1.5px;
        height: 50%;
        background-color: rgb(170, 170, 170);
        border-radius: 2px;
      }

      .button-showhidden-list-option {
        border-radius: 8px;
      }

      .button-showhidden-list-option,
      .button-delete-list-options {
        width: 30px;
        height: calc(100% - 2px);
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border: none;
        cursor: pointer;
        user-select: none;
        outline: none;

        .icon-delete-list-options,
        .arrow-list {
          display: block;
          margin: auto;
        }
      }

      .button-delete-list-options {
        .icon-delete-list-options {
          fill: #262626;
          width: 15px;
        }
      }

      .button-showhidden-list-option {
        .arrow-list {
          width: 25px;
        }
      }
    }

    .input-dropdownlist-customized {
      padding: 10px 12px 10px 20px;
      background: linear-gradient(0deg, #ffffff, #ffffff),
        linear-gradient(0deg, #a3a3a3, #a3a3a3);
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      color: #262626;
      width: 100%;
      margin: auto;
      display: flex;
      outline: none;
      cursor: pointer;
      caret-color: transparent;
      user-select: none;
    }

    .apply-collapse-top {
      bottom: 44px;
    }

    .dropdownlist-customized-list {
      position: absolute;
      width: 100%;
      height: 200px;
      z-index: 10;
      box-shadow: 0px 4px 4px rgba(4, 54, 154, 0.21);
      background-color: #fff;
      border-radius: 6px;
      animation: dropdownlist-fadein 0.2s;
      opacity: 1;

      .ddl-customized-search {
        background-color: rgb(240, 240, 240);
        padding: 10px;
        position: relative;
        display: flex;
        align-items: center;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .button-delete-search {
          position: absolute;
          width: 30px;
          height: 30px;
          right: 12px;
          display: flex;
          vertical-align: middle;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          border: none;

          .icon-delete-search {
            display: block;
            margin: auto;
            width: 15px;
            cursor: pointer;
          }
        }

        input {
          padding: 9px 9px 9px 13px;
          background: linear-gradient(0deg, #ffffff, #ffffff),
            linear-gradient(0deg, #a3a3a3, #a3a3a3);
          border-radius: 8px;
          font-weight: 400;
          font-size: 14px;
          color: #262626;
          width: 100%;
          margin: auto;
          display: flex;
          outline: none;
          cursor: pointer;
        }
      }

      .ddl-customized-overflow {
        height: calc(100% - 57px);
        overflow-y: scroll;
        overflow-x: hidden;

        .ddl-customized-list-option {
          padding: 10px;
          cursor: pointer;
          display: flex;
          vertical-align: middle;
          align-items: center;

          svg {
            fill: rgb(150, 150, 150);
          }

          &:last-child {
            border-bottom-left-radius: 6px;
          }

          &:hover {
            background-color: rgba(4, 54, 154, 0.21);
          }
        }

        .ddl-customized-list-option-selected {
          background-color: rgba(4, 54, 154, 0.21);

          svg {
            fill: #0065dd;
          }
        }

        .ddl-customized-option-not-data {
          padding: 20px;
          display: flex;
          vertical-align: middle;
          align-items: center;
        }

        &::-webkit-scrollbar {
          width: 8px;
          left: -20px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(175, 175, 175, 1);
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: rgba(100, 100, 100, 1);
        }

        &::-webkit-scrollbar-thumb:active {
          background: rgba(100, 100, 100, 1);
        }

        .container::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-track:hover,
        &::-webkit-scrollbar-track:active {
          background: transparent;
        }
      }
    }
  }

  .box-datepicker {
    position: relative;
    display: flex;
    vertical-align: middle;
    align-items: center;

    svg {
      position: absolute;
      right: 4%;
      fill: rgb(100, 100, 100);
      width: 20px;
    }
  }
}

.button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  margin: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  gap: 10px;

  &:focus {
    outline: none;
  }

  svg {
    display: flex;
    margin: auto;
    background-color: transparent;
  }
}

.button-filter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background: transparent;
  border: none;
  cursor: pointer;
  gap: 10px;

  &:disabled {
    cursor: auto;
    &:hover {
      border-radius: 50%;
    }
  }

  &:focus {
    outline: none;
  }

  svg {
    display: inline-block;
    margin: auto;
    background-color: transparent;

    &:disabled {
      cursor: none;
    }

    &:hover {
      background-color: #e5e5e5;
      border-radius: 50%;
    }

    &:active {
      transform: scale(0.98);
      border-radius: 50%;
      background-color: #fafafa;
      border: 2px solid #242424;
    }
  }
}

/**
  TODO: TOAST DESIGN
*/
.container-toast {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  right: 0;
  position: fixed;
  gap: 10px;
  z-index: 20;

  .content-toast {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8px 16px;
    border-radius: 12px 0px 0px 12px;
    width: 536px;
    height: 92px;

    .content-title-toast {
      display: flex;
      justify-content: space-between;

      .title {
        font-family: $fontFamilyInter;
        font-style: italic;
        font-weight: 600;
        font-size: 16px;
        align-items: center;
        text-transform: capitalize;
      }

      .button-close-toast {
        display: flex;
        margin: 0;
        border: none;
        background: transparent;
        svg {
          display: flex;
          margin: 0;
          cursor: pointer;
        }
      }
    }

    .message {
      font-family: $fontFamilyInter;
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    &.success {
      background: #e2ebf9;
      border-left: 16px solid $botonVerdeToast;
      .title,
      .message {
        color: $botonVerde;
      }
    }
    &.danger {
      background: #f8e0e0;
      border-left: 16px solid $botonRojoToast;
      .title,
      .message {
        color: $botonRojoToast;
      }
    }
    &.warning {
      background: #fff4d9;
      border-left: 16px solid $botonNaranjoToast;
      .title,
      .message {
        color: #404040;
      }
    }
    &.help {
      background: #f8e0e0;
      border-left: 16px solid $botonRosadoToast;
      .title,
      .message {
        color: $botonRosadoToast;
      }
    }
  }
}
