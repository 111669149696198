.modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  vertical-align: middle;
  align-items: center;
  overflow-y: scroll;
  padding-top: 30px;
  padding-bottom: 30px;

  .modal-body {
    width: 80%;
    min-height: 90vh;
    background-color: #fff;
    margin: auto;
    border-radius: 21px;
  }
}

.modal-ficha-candidato {
  padding-top: 32px;
  width: 90%;
  margin: auto;

  h2 {
    padding: 20px 10px 20px 10px;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 10px 0px 40px 10px;
    width: 80%;
    margin: auto;
  }

  .row-forms {
    .title {
      color: #002d63;
      text-transform: uppercase;
      padding-bottom: 8px;
      padding-top: 8px;
      border-bottom: 1px dashed #cecece;
      font-weight: bold;
      font-size: 14px;
    }

    .content-row {
      padding: 24px 0px;
      display: flex;
      vertical-align: middle;
      align-items: flex-start;

      .content-cols-left {
        width: 30%;
      }

      .content-cols-middle {
        width: 20%;
      }

      .content-cols-right {
        width: 50%;
      }

      .content-cols {
        width: calc(100% / 4);
      }

      .content-cols-large {
        width: calc(100% / 2);
      }
    }
  }

  // ;
}
