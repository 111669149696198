@import "./_config";

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-black {
  position: absolute;
  bottom: calc(100% + 6px); 
  left: 50%;
  transform: translateX(-85%);
  background-color: #404040;
  color: #fff;
  padding: 8px 16px;
  white-space: nowrap;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
  font-family: $fontFamilyInter;
  font-size: $fontSizeMid;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-transform: capitalize;
  z-index: 999999;
}

.arrow-black {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0;
  border-color: #404040 transparent transparent transparent;
  bottom: -6px;
  left: 85%;
  transform: translateX(-50%);
  z-index: 999999;
}

.tooltip-white {
  position: absolute;
  top: calc(100% + 6px); 
  left: 50%;
  transform: translateX(-30%);
  background-color: #FAFAFA;
  color: #404040;
  padding: 8px 16px;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
  border: 1px solid #404040;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; 
  text-transform: capitalize;
  z-index: 11;

  .content-tooltip {
    display: flex;
    justify-content: start;
    align-items: center;
    vertical-align: center;
    flex-direction: row;
    font-family: "Inter";
    font-size: 13.17px;
    font-style: normal;
    font-weight: 500;
    margin-top: 5px;

    .button-content {
      display: flex;
      background: transparent;
      border: none;
      margin: 0;
    }
  }
}

.arrow-white {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px;
  border-color: transparent transparent #FAFAFA transparent;
  top: -6px;
  left: 30%;
  transform: translateX(-50%);
}
