.box {
    width: 100%;
    height: auto;
    background-color: #003D85;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 100; 

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        vertical-align: middle;
    }

    .menu-icon {
        font-size: 2rem;
        color: #ffffff;
        margin-right: 3rem;
        margin-left: 3rem;
    }

    label {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #FAFAFA; 
    }
}