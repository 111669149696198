@import "../_config";

/**
* TODO: CHANGES IN MODAL UPDATE CANDIDATE  
*/

.container-grafico-pdf {
  display: flex;
  width: 500px;
  height: 477px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .content-title {
    display: flex;
    justify-content: center;
    align-items: center;

    .title-pdf {
      color: #262626;
      font-size: 20px;
      font-weight: 700;
    }
  }
}
.containe-pdf-psicolaboral {
  overflow: hidden !important;

  .center-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .loading-container {
    display: flex;
    align-items: end;
    justify-content: center;
    text-align: center;
    gap: 2px;
  }
  
  .loading-dots {
    display: flex;
  }
  
  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #333;
    border-radius: 50%;
    margin-right: 4px;
    opacity: 0;
  }
  
  .dot1 {
    animation: dotAnimation 2s infinite linear 0.5s;
  }
  
  .dot2 {
    animation: dotAnimation 2s infinite linear 1s;
  }
  
  .dot3 {
    animation: dotAnimation 2s infinite linear 1.5s;
  }
  
  @keyframes dotAnimation {
    20%,
    70%,
    100% {
      opacity: 0;
    }
    50%,
    70% {
      opacity: 1;
    }
  }
  
  .loading-text {
    font-family: Arial, sans-serif;
    font-size: 34px;
    font-weight: 700;
    line-height: 28px;
  }
}

