@import "../_config";

/**
* TODO: HEADERS DE LA TABLA
*/
.content-seleccion-multiple {
  display: flex;
  justify-content: start;
  width: 100%;

  &.action-in-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &.margin-top-20 {
    margin-top: 20px;
  }

  &.j-end {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: end;
  }

  .content-multiple {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .button-multiple {
      display: flex;
      margin: 0;
      background: transparent;
      border: none;
      cursor: pointer;
    }

    .title-multiple {
      color: #404040;
      text-align: center;
      font-family: $fontFamilyInter;
      font-size: $fontSizeMid;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      .selects-table-registro {
        color: $azulFlick;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 19px; /* 118.75% */
        margin-left: 20px;
      }

      .selects {
        color: $azulFlick;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 19px; /* 118.75% */

        &::before {
          content: " / ";
          font-weight: 400;
        }
      }
    }
  }
}

/** 
* TODO: CSS DE ACCIONES MASIVAS EN TABLAS
*/

.container-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;

  .content-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    position: relative;
  }

  .content-button-masive {
    display: flex;
    gap: 25px;

    .button-navegation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 8px;
      margin: 0;
      text-align: center;
      font-family: $fontFamilyInter;
      font-size: $fontSizeLarge;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      background: transparent;
      border: none;
      border-radius: $borderRadiusButton;
      gap: 30px;
      transition-duration: 0.2s;

      &.naranjo {
        background: $botonNaranja;
        border: 2px solid $botonNaranja;
        cursor: pointer;
        color: $blancoBasico;
        &:hover {
          border: 2px solid $botonNaranja;
          background: $blancoBasico;
          color: $botonNaranja;
        }
      }

      &.verde {
        background: $botonVerde;
        border: 2px solid $botonVerde;
        cursor: pointer;
        color: $blancoBasico;
        &:hover {
          border: 2px solid $botonVerde;
          background: $blancoBasico;
          color: $botonVerde;
        }
      }

      &.disabled {
        background: $botonDisabled;
        border: 2px solid $botonDisabled;
        color: $colorDisabled;
        cursor: not-allowed;
      }
    }
  }

  .content-actions-masive {
    display: flex;
    gap: 25px;

    .button-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      background: none;
      border-radius: 100%;
      border: none;
      margin: 0;
      width: 40px;
      height: 40px;
      cursor: pointer;
      transition: all 0.2s linear;

      svg {
        display: flex;
        margin: 0;
        font-size: 24px;
        transition: all 0.2s linear;
      }

      &:disabled {
        border: 2px solid #aeaeae !important;
        cursor: no-drop;
        svg {
          color: #aeaeae;
          fill: #aeaeae;
        }
        &.file {
          padding: 2px 10px;
        }
      }

      &.disabled {
        border: 2px solid #aeaeae;
        cursor: no-drop;
        svg {
          color: #aeaeae;
          fill: #aeaeae;
        }
        &.file {
          padding: 2px 10px;
        }
      }

      &.active-check {
        border: 2px solid #ff4900;
        svg {
          color: #ff4900;
        }
        &:active {
          background-color: #ff4900;
          svg {
            color: #fafafa;
          }
        }
        &:hover {
          background-color: #ff4900;
          svg {
            color: #fafafa;
          }
        }
      }

      &.active-send {
        border: 2px solid #ff4900;
        svg {
          color: #ff4900;
        }
        &:active {
          background-color: #ff4900;
          svg {
            color: #fafafa;
          }
        }
        &:hover {
          background-color: #ff4900;
          svg {
            color: #fafafa;
          }
        }
      }

      &.active-cancel {
        border: 2px solid $colorNaranjo;
        svg {
          color: $colorNaranjo;
        }
        &:active {
          background-color: $colorNaranjo;
          svg {
            color: $blancoBasico;
          }
        }
        &:hover {
          background-color: $colorNaranjo;
          svg {
            color: $blancoBasico;
          }
        }
      }

      &.active-azul {
        border: 2px solid $azulFlick;
        svg {
          color: $azulFlick;
        }
        &:active {
          background-color: $azulFlick;
          svg {
            color: $blancoBasico;
            fill: $blancoBasico;
          }
        }
        &:hover {
          background-color: $azulFlick;
          svg {
            color: $blancoBasico;
            fill: $blancoBasico;
          }
        }
      }

      &.active-verde {
        border: 2px solid $colorVerde;
        transition-duration: 0.2s;
        // transition-timing-function: ease-in;
        svg {
          color: $colorVerde;
        }
        &:active {
          background-color: $colorVerde;
          svg {
            color: $blancoBasico;
            fill: $blancoBasico;
          }
        }
        &:hover {
          background-color: $colorVerde;
          svg {
            color: $blancoBasico;
            fill: $blancoBasico;
          }
        }
      }

      &.active-naranjo {
        border: 2px solid $colorNaranjo;
        transition-duration: 0.2s;
        svg {
          color: $colorNaranjo;
        }
        &:active {
          background-color: $colorNaranjo;
          svg {
            color: $blancoBasico;
            fill: $blancoBasico;
          }
        }
        &:hover {
          background-color: $colorNaranjo;
          svg {
            color: $blancoBasico;
            fill: $blancoBasico;
          }
        }
      }

      &.active-rosado {
        border: 2px solid $colorRosado;
        transition-duration: 0.2s;
        svg {
          color: $colorRosado;
        }
        &:active {
          background-color: $colorRosado;
          svg {
            color: $blancoBasico;
            fill: $blancoBasico;
          }
        }
        &:hover {
          background-color: $colorRosado;
          svg {
            color: $blancoBasico;
            fill: $blancoBasico;
          }
        }
      }

      &.active-reasignar {
        border: 2px solid $azulFlick;
        transition-duration: 0.2s;
        svg {
          color: $azulFlick;
        }
        &:active,
        &:hover {
          background-color: $azulFlick;
          svg {
            color: $blancoBasico;
            fill: $blancoBasico;
            &:disabled {
              fill: $botonDisabled;
            }
          }
        }
      }

      &.active-solicitar-candidato {
        border: 2px solid $colorAmarillo;
        transition-duration: 0.2s;
        svg {
          color: $colorAmarillo;
        }
        &:active,
        &:hover {
          background-color: $colorAmarillo;
          svg {
            color: $blancoBasico;
            fill: $blancoBasico;
            &:disabled {
              fill: $botonDisabled;
            }
          }
        }
      }

      &.active-block {
        border: 2px solid $colorRojo;
        transition-duration: 0.2s;
        svg {
          color: $colorRojo;
          fill: $colorRojo;
        }
        &:active,
        &:hover {
          background-color: $colorRojo;
          svg {
            fill: #fafafa;
            color: #fafafa;
          }
        }
      }

      &.active-file {
        border: 2px solid $colorVerdeOscuro;
        padding: 2px 10px;
        transition-duration: 0.2s;

        svg {
          display: flex;
          margin: 0;
          font-size: 25px;
          color: $colorVerdeOscuro;
        }
        &:active {
          background-color: $colorVerdeOscuro;
          svg {
            fill: #fafafa;
          }
        }
        &:hover {
          background-color: $colorVerdeOscuro;
          svg {
            fill: #fafafa;
          }
        }
      }

      &.active-traspasos-check {
        border: 2px solid $colorVerde;
        padding: 2px 10px;
        transition-duration: 0.2s;
        svg {
          display: flex;
          margin: 0;
          font-size: 25px;
          color: $colorVerde;
        }
        &:active {
          background-color: $colorVerde;
          svg {
            fill: #fafafa;
          }
        }
        &:hover {
          background-color: $colorVerde;
          svg {
            fill: #fafafa;
          }
        }
      }

      &.active-restore {
        border: 2px solid #0065dd;
        transition-duration: 0.2s;
        svg {
          color: #0065dd;
        }
        &:active {
          background-color: #0065dd;
          svg {
            color: #fafafa;
          }
        }
        &:hover {
          background-color: #0065dd;
          svg {
            color: #fafafa;
          }
        }
      }
    }
  }
}
