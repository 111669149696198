@import "./_config";

.content-submenu {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  .content-page,
  .content-nav {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 0px 16px;
    margin: 10px 0px;

    .page-tag {
      display: flex;
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: $fontSizeMid;
      line-height: 22px;
      color: #61738e;
      cursor: pointer;
      &:active {
        color: #748cb1;
      }
    }

    .nav-tag {
      display: flex;
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 400;
      font-size: $fontSizeMid;
      line-height: 22px;
      color: #a3a3a3;
      cursor: pointer;

      &.select {
        font-weight: 600;
        line-height: 24px;
        text-decoration-line: underline;
        color: #48566b;
      }
      &:active {
        color: #748cb1;
      }
    }

    .page-tag-nav {
      display: flex;
      font-family: $fontFamilyInter;
      font-style: normal;
      font-weight: 500;
      font-size: $fontSizeMid;
      line-height: 22px;
      color: #005bc7;
      cursor: pointer;
      &:active {
        color: #748cb1;
      }
    }
  }
}

.section-title-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px 0px;

  &.dashboard {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .content-title-dashboard {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    vertical-align: middle;
    gap: 5px;

    .title-page-dashboard {
      color: $colorSubtitleDashboard;
      font-family: $fontFamilyInter;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px;
    }

    .label-ciclo {
      color: var(--input-texto-titulo, #404040);
      font-family: $fontFamilyInter;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px; 
    }
    .w-50 {
      width: 50%;
    }
    
    /* 40% de ancho */
    .w-40 {
      width: 40%;
    }
    
    /* 30% de ancho */
    .w-30 {
      width: 30%;
    }
    
    /* 25% de ancho */
    .w-25 {
      width: 25%;
    }
    
    /* 20% de ancho */
    .w-20 {
      width: 20%;
    }
    
    /* 10% de ancho */
    .w-15 {
      width: 15%;
    }
  }

  .title-page {
    color: #262626;
    text-align: center;
    font-family: $fontFamilyInter;
    font-size: $fontSizeTitle;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }

  .subtitle-page {
    color: #404040;
    text-align: center;
    font-family: $fontFamilyInter;
    font-size: $fontSizeMid;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }

  .note-page {
    color: #48566b;
    text-align: center;
    font-family: $fontFamilyInter;
    font-size: $fontSizeMid;
    font-style: italic;
    font-weight: 400;
    margin: 0;
  }
}
@media (max-width: 1100px) {
  .content-submenu {
    flex-direction: column; 
  }
}