* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: $fontSize;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    // background-color: var(--surface-ground);
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    width: 100vw;
    overflow-x: hidden;
    height: 100%;
}

:root {
    --surface-ground: #f8fcff;
    --text-color: #333333;;
    --text-color-secondary:#6c757d;
    --primary-color:#333333;
}

a, button {
    text-decoration: none;
    color: var(--primary-color);
}



.layout-theme-light {
    background-color: #edf1f5;
}
